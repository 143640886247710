import { IonContent, IonPage, IonLabel, IonButton } from '@ionic/react';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Step, { StepProps } from './Step';
import Banner from '../../components/Banner';
import { observer } from 'mobx-react';
import Utils from '../../utils/Utils';
import DesignHelper from './DesignHelper';
import '../../theme/pages/packageSignature.css';

class PackageSignature extends Step<StepProps> {
  goToNextStep = (isDeliverySignatureRequired: boolean) => {
    const { workflowStore } = this.props.appStore;
    workflowStore.setIsDeliverySignatureRequired(isDeliverySignatureRequired);
    workflowStore.goToNextStep();
  };

  onClickYes = () => {
    this.goToNextStep(true);
  };

  onClickNo = () => {
    this.goToNextStep(false);
  };

  render() {
    const { locationStore, workflowStore } = this.props.appStore;

    const themeMain = locationStore.themeMain;
    const themeButton = locationStore.themeButton;

    return (
      <IonPage className="package-signature">
        <IonContent>
          <Banner {...this.props} />
          {DesignHelper.renderThemeDesignTop(themeMain, locationStore.location?.logo || null)}
          {Utils.isNative() && DesignHelper.renderHomeButton(themeButton, () => workflowStore.reset(true))}
          {DesignHelper.renderThemeLabelTitle(themeMain, locationStore.tabletTerminologies.signatureRequired)}
          <div className="buttons-container">
            <IonButton className="yesno-button" onClick={this.onClickYes}>
              <FontAwesomeIcon className="yesno-icon" icon={['far', 'check-circle']} />
              <IonLabel className="yesno-text">{locationStore.tabletTerminologies.yes}</IonLabel>
            </IonButton>
            <IonButton className="yesno-button" onClick={this.onClickNo}>
              <FontAwesomeIcon className="yesno-icon no" icon={['far', 'times-circle']} />
              <IonLabel className="yesno-text">{locationStore.tabletTerminologies.no}</IonLabel>
            </IonButton>
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

export default observer(PackageSignature);
