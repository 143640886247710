import OutCheckInRecordAPIModel from './OutCheckInRecordAPIModel';
import PostCheckInRecordBodyModel from '../PostCheckInRecordBody';

export default class PostCheckInRecordBodyAPIModel {
  check_in_record: OutCheckInRecordAPIModel;
  surrogate_id?: string;

  constructor(postCheckInRecordBody: PostCheckInRecordBodyModel) {
    this.check_in_record = new OutCheckInRecordAPIModel(postCheckInRecordBody.checkInRecord);
    this.surrogate_id = postCheckInRecordBody.surrogateId;
  }
}
