import AccountAPIModel from './api/AccountAPIModel';

export default class AccountModel {
  apiKey: string;
  locationId: number;

  constructor(accountAPI: AccountAPIModel) {
    this.apiKey = accountAPI.api_key;
    this.locationId = accountAPI.location_id;
  }
}
