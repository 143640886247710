import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import Step, { StepProps } from './Step';
import '../../theme/pages/stubLoading.css';
import Banner from '../../components/Banner';
import Loading from '../../components/Loading';
import DesignHelper from './DesignHelper';

export interface StubLoadingProps extends StepProps {
  message?: string | null;
}

class StubLoading extends Step<StubLoadingProps> {
  render() {
    const { appStore } = this.props;
    const { locationStore } = appStore;

    return (
      <IonPage className="stub-loading">
        <IonContent>
          <Banner {...this.props} />
          {DesignHelper.renderThemeDesignTop(locationStore.themeMain, locationStore.location?.logo || null)}
        </IonContent>
        <Loading message={this.props.message ?? ''} />
      </IonPage>
    );
  }
}

export default StubLoading;
