import AppStore from './AppStore';
import Axios, { AxiosResponse } from 'axios';
import ENV from '../env';
import { Etc } from '../commons/Constants';
import { computed, makeObservable, observable } from 'mobx';
import { getBaseUrl } from '../utils/BaseUrls';

export class WebStoreInitErrorCode {
  static get UNAUTHORIZED() {
    return 'web_store_init_unauthorized';
  }
  static get UNKNOWN() {
    return 'web_store_init_unknown';
  }
}

export default class WebStore extends AppStore {
  private _sessionId!: string;
  private _locationId!: number;
  private _previewMode!: boolean;
  private _deviceId!: string;

  constructor() {
    super();
    makeObservable<WebStore, '_sessionId' | '_locationId' | '_previewMode' | '_deviceId'>(this, {
      _sessionId: observable,
      _locationId: observable,
      _previewMode: observable,
      _deviceId: observable,
      sessionId: computed,
      locationId: computed,
      previewMode: computed,
      deviceId: computed,
    });
  }

  get authenticationHeader() {
    return {
      'session-id': this._sessionId,
    };
  }

  get arePermissionsGranted(): boolean {
    return true;
  }

  get locationId(): number {
    return this._locationId;
  }

  get sessionId() {
    return this._sessionId;
  }

  get previewMode() {
    return this._previewMode;
  }

  get deviceId() {
    return this._deviceId;
  }

  async fetchLocationId(sessionId: string) {
    this._sessionId = sessionId;
    const baseUrl = await getBaseUrl()

    return Axios.get(baseUrl + '/api/locationId/', {
      headers: {
        ...this.authenticationHeader,
      },
    })
      .then((res: AxiosResponse<any>) => {
        this._locationId = res.data.location_id;
        this._previewMode = res.data.preview_mode;
        this._deviceId = res?.data?.device_id || null;
      })
      .catch((err) => {
        console.error(Etc.LOG_PREFIX + JSON.stringify(err));
        if (err.response !== undefined) {
          if (err.response.status === 401) {
            throw WebStoreInitErrorCode.UNAUTHORIZED;
          } else {
            throw WebStoreInitErrorCode.UNKNOWN;
          }
        } else {
          throw WebStoreInitErrorCode.UNKNOWN;
        }
      });
  }
}
