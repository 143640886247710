export default class AnalyticsData {
  startTimeMillis!: number;
  totalTimeMillis = 0;
  browserName!: string;
  browserVersion!: string;
  osName!: string;
  osVersion!: string;
  deviceType!: string;
  mobileModel = '';
  mobileVendor = '';
  firstName?: string = undefined;
  lastName?: string = undefined;
  email?: string = undefined;
  company?: string = undefined;
  phoneNumber?: string = undefined;
  location?: string = undefined;
}
