import { action, computed, makeObservable, observable } from 'mobx';
import AccountModel from '../models/AccountModel';
import { Capacitor, Plugins } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { Network, ConnectionStatus } from '@capacitor/network';
import { Camera } from '@capacitor/camera';
import type { PermissionState } from '@capacitor/core';
import { Preferences as Storage } from '@capacitor/preferences';
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';

import AccountAPIModel from '../models/api/AccountAPIModel';
import AppStore from './AppStore';
import Axios, { AxiosResponse } from 'axios';
import { Etc, TauriApp, NodeId } from '../commons/Constants';
import Utils from '../utils/Utils';
import InCheckInRecordAPIModel from '../models/api/InCheckInRecordAPIModel';
import { isPlatform } from '@ionic/react';
import { rollbarHandler } from '../components/RollbarErrorHandler';
import { AppVersion } from '@ionic-native/app-version';
import BadgeUtils, { BadgePrintInterface } from '../utils/BadgeUtils';
import PrinterUtils from '../plugins/PrinterUtils';
import BadgeInfoAPIModel from '../models/api/BadgeInfoAPIModel';
import { getBaseUrl } from '../utils/BaseUrls';
const { LockModeManager, IntentManager } = Plugins;

const KEY_ACCOUNT = 'key_account';

export class NativeStoreTryToLoginError {
  static get NO_ACCOUNT() {
    return 'native_store_try_to_login_no_account';
  }
  static get INVALID_ACCOUNT() {
    return 'native_store_try_to_login_invalid_account';
  }
}

export interface PermissionStatus {
  // TODO: change 'location' to the actual name of your alias!
  location: PermissionState;
}

export default class NativeStore extends AppStore {
  private _account: AccountModel | null = null;
  private _arePermissionsGranted = false;
  private _isConnected = false;

  private _welcomePageTimeoutMillis = 180000;
  private _welcomePageTimeout: NodeJS.Timeout | null = null;
  private _reloadTimeoutMillis = 1200000;
  private _reloadLocationTimeout: NodeJS.Timeout | null = null;
  private _printBadgeTimeoutMillis = 2000;
  private _printBadgeTimeout: NodeJS.Timeout | null = null;
  private _isFirstThermal = false;
  private _thermalCheckInPollTimeoutMillis = 1000;
  private _thermalCheckInPollTimeout: NodeJS.Timeout | null = null;
  private _appVersion: string = '';
  private _deviceId: string | null = null;

  isFromSuccess = false;
  private rollbar = rollbarHandler();

  constructor() {
    super();

    makeObservable<NativeStore, '_account' | '_arePermissionsGranted' | '_isConnected'>(this, {
      _account: observable,
      _arePermissionsGranted: observable,
      _isConnected: observable,
      arePermissionsGranted: computed,
      account: computed,
      isConnected: computed,
      requestPermissions: action,
      tryToLogin: action,
      login: action,
      logout: action,
      onNetworkStatusChange: action,
    });

    Network.getStatus().then((status: ConnectionStatus) => {
      this.onNetworkStatusChange(status);
    });
    this._renderAppVersion();
  }

  get authenticationHeader() {
    return {
      'api-key': this._account!.apiKey,
    };
  }

  get locationId(): number {
    return this._account!.locationId;
  }

  get appVersion(): string {
    return this._appVersion || '';
  }

  get arePermissionsGranted() {
    return this._arePermissionsGranted;
  }

  get account() {
    return this._account;
  }

  get isConnected() {
    return this._isConnected;
  }

  get isPlatformAndroid(): boolean {
    return isPlatform('android');
  }

  get appVersionPlatform(): string {
    const platform = isPlatform('android') ? 'Android' : isPlatform('ios') ? 'iOS' : 'Web';
    return 'Greetly/' + platform + '/' + this._appVersion;
  }

  get isBluetoothPrinter(): boolean | undefined {
    return (this._locationStore.location?.badgeSetting.ipAddress ?? '').length == 0;
  }

  set shouldShowWelcomePage(value: boolean) {
    super.setShowWelcomePageFunc(value);

    if (value) {
      this.clearWelcomePageTimeout();
      this._workflowStore.reset();
    } else {
      this._setWelcomePageTimeout();
    }
  }

  get shouldShowWelcomePage(): boolean {
    return this._shouldShowWelcomePage;
  }

  async goToSettings() {
    NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App,
    });
  }

  async requestPermissions() {
    if (Capacitor.isNativePlatform()) {
      if (isPlatform('ios')) {
        const permissions = await Camera.requestPermissions();
        if (permissions.camera !== 'denied') {
          return (this._arePermissionsGranted = true);
        }
        this._arePermissionsGranted = false;
      } else if (isPlatform('android')) {
        return Camera.requestPermissions().then((_permission: any) => {
          this._arePermissionsGranted = true;
        });
      }
    } else {
      this._arePermissionsGranted = true;
    }
  }

  async tryToLogin() {
    return await Storage.get({ key: KEY_ACCOUNT }).then((accountStr: any) => {
      if (accountStr.value !== null) {
        try {
          this._account = JSON.parse(accountStr.value) as AccountModel;
        } catch (e) {
          throw NativeStoreTryToLoginError.INVALID_ACCOUNT;
        }
      } else {
        throw NativeStoreTryToLoginError.NO_ACCOUNT;
      }
    });
  }

  async login(code: string) {
    const baseUrl = await getBaseUrl();
    // @TODO device info
    return Axios.post(baseUrl + '/api/device/bind', { code: code }).then(
      async (res: AxiosResponse<AccountAPIModel>) => {
        this._account = new AccountModel(res.data);

        return await Storage.set({
          key: KEY_ACCOUNT,
          value: JSON.stringify(this._account),
        });
      },
    );
  }

  async logout() {
    return await Storage.remove({
      key: KEY_ACCOUNT,
    }).finally(() => {
      this._workflowStore.reset();

      this._account = null;
    });
  }

  onNetworkStatusChange(status: ConnectionStatus) {
    this._isConnected = status.connected;
    if (!status.connected) {
      this._account = null;
    }
  }

  async fetchLocation(shouldClearLocation: boolean) {
    this._workflowStore.reset();

    this.clearPrintBadgeTimeout();
    this.clearReloadLocationTimeout();
    this.clearWelcomePageTimeout();
    return this._locationStore.fetch(shouldClearLocation, this.appVersionPlatform).then(() => {
      this.shouldShowWelcomePage = true;
      this._setReloadLocationTimeout();
      this._setPrintBadgeTimeout();
    });
  }

  resetWelcomePageTimeout() {
    if (this._welcomePageTimeout !== null) {
      clearTimeout(this._welcomePageTimeout);
      this._welcomePageTimeout = null;
      this._setWelcomePageTimeout();
    }
  }

  clearWelcomePageTimeout() {
    if (this._welcomePageTimeout !== null) {
      clearTimeout(this._welcomePageTimeout);
      this._welcomePageTimeout = null;
    }
  }

  private _setWelcomePageTimeout() {
    if (this._welcomePageTimeout === null) {
      this._welcomePageTimeout = setTimeout(() => {
        if (this._workflowStore.node?.type === NodeId.VIDEO_PLAYER) {
          this._welcomePageTimeout = null;
          this.shouldShowWelcomePage = false;
        } else {
          this.shouldShowWelcomePage = true;
        }
      }, this._welcomePageTimeoutMillis);
    }
  }

  clearReloadLocationTimeout() {
    if (this._reloadLocationTimeout !== null) {
      clearTimeout(this._reloadLocationTimeout);
      this._reloadLocationTimeout = null;
    }
  }

  resetReloadLocationTimeout() {
    if (this._reloadLocationTimeout !== null) {
      clearTimeout(this._reloadLocationTimeout);
      this._reloadLocationTimeout = null;
      this._setReloadLocationTimeout();
    }
  }

  private _setReloadLocationTimeout() {
    if (this._reloadLocationTimeout === null) {
      this._reloadLocationTimeout = setTimeout(() => {
        this._reloadLocationTimeout = null;
        this.fetchLocation(false)
          .catch((err) => {
            console.error(Etc.LOG_PREFIX + err);
          })
          .finally(() => {
            this.shouldShowWelcomePage = true;
            this._setReloadLocationTimeout();
          });
      }, this._reloadTimeoutMillis);
    }
  }

  clearPrintBadgeTimeout() {
    if (this._printBadgeTimeout !== null) {
      clearTimeout(this._printBadgeTimeout);
      this._printBadgeTimeout = null;
    }
  }

  pinApp() {
    LockModeManager.pinApp();
  }

  get deviceId(): string {
    return this._deviceId ?? '';
  }

  async fetchDeviceId(): Promise<string> {
    if (this._deviceId === null) {
      this._deviceId = (await Device.getId()).uuid;
    }
    return this._deviceId || '';
  }

  private async _setPrintBadgeTimeout() {
    if (
      (this._locationStore.location?.isSubscriptionValid ?? true) &&
      (this._locationStore.location?.isPrintBadgeEnabled ?? false) &&
      this._printBadgeTimeout === null
    ) {
      this._printBadgeTimeout = setTimeout(async () => {
        PrinterUtils.isPrinterConnected()
          .then((connected) => {
            if (connected) {
              this._printRequestBadges();
            } else {
              PrinterUtils.searchConnectedPrinters(this.isBluetoothPrinter ?? true);
            }
          })
          .finally(() => {
            this.clearPrintBadgeTimeout();
            this._setPrintBadgeTimeout();
          });
      }, this._printBadgeTimeoutMillis);
    }
  }

  private async _printRequestBadges() {
    this.fetchDeviceId().then(async (deviceId: any) => {
      let headers = { headers: { ...this.authenticationHeader } };
      const ipAddress = this._locationStore?.location?.badgeSetting?.ipAddress || '';
      const baseUrl = await getBaseUrl();

      Axios.get(baseUrl + `/api/locations/${this.locationId}/badge_printing_queues?device_id=${deviceId}`, headers)
        .then(async (res: AxiosResponse<Array<BadgeInfoAPIModel>>) => {
          if (typeof res.data !== 'string') {
            for (let i = 0; i < res.data.length; ++i) {
              const badgeInfo = res.data[i];

              if (badgeInfo.badge_image) {
                this.convertImageToBase64(badgeInfo.badge_image, (base64Img: string) => {
                  Utils.printImg(base64Img, ipAddress);
                });
              } else {
                Axios.get(
                  baseUrl + `/api/locations/${this.locationId}/check_in_records/${badgeInfo.check_in_record_id}`,
                  headers,
                )
                  .then(async (checkInRecordAPI: AxiosResponse<InCheckInRecordAPIModel>) => {
                    this.createAndPrintBadge(badgeInfo, checkInRecordAPI.data);
                  })
                  .catch((err) => {
                    console.error(Etc.LOG_PREFIX + JSON.stringify(err));
                  });
              }
            }
          }
        })
        .catch((err) => {
          console.error(Etc.LOG_PREFIX + JSON.stringify(err));
        });
    });
  }

  private convertImageToBase64(imgUrl: string, callback: Function) {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.height = image.naturalHeight;
      canvas.width = image.naturalWidth;
      ctx!.drawImage(image, 0, 0);
      const dataUrl = canvas.toDataURL();
      callback && callback(dataUrl.replace('data:image/png;base64,', ''));
    };
    image.src = imgUrl;
  }

  private async createAndPrintBadge(badgeInfo: BadgeInfoAPIModel, checkInRecordAPI: InCheckInRecordAPIModel) {
    //find workflow
    let workflow = this._locationStore.location?.workflows.find(
      (workflow) => workflow.id == checkInRecordAPI.check_in_option_id,
    );

    //convert to BadgePrintInterface
    const badgePrint: BadgePrintInterface = {
      visitorInfoFieldValues: checkInRecordAPI.visitor_info_fields,
      hostName: checkInRecordAPI.host_name ? checkInRecordAPI.host_name : null,
      visitorImgUrl: checkInRecordAPI.visitor_photo
        ? await BadgeUtils.addProxyToURL(encodeURIComponent(checkInRecordAPI.visitor_photo))
        : null,
      companyImgUrl: this._locationStore?.location?.logo
        ? await BadgeUtils.addProxyToURL(encodeURIComponent(this._locationStore.location?.logo))
        : null,
      badgeText: null,
      customBadgeHTML: badgeInfo.body_html,
      workflowName: workflow?.name,
      visitorId: checkInRecordAPI.id,
    };

    // print badge
    await Utils.printBadge(badgePrint, this._locationStore.location!.badgeSetting).catch((err) => {
      console.error(Etc.LOG_PREFIX + JSON.stringify(err));
      this.rollbar
        .configure({
          payload: {
            context: { location_id: this._locationStore.location?.id },
          },
        })
        .error(err, 'Print Failure');
    });
  }

  /**
   *
   * @returns
   */

  openThermal() {
    if (!this._isFirstThermal) {
      this._isFirstThermal = true;
      return IntentManager.startActivity({ pkg: TauriApp.PACKAGE, cls: TauriApp.SPLASH_ACTIVITY });
    } else {
      return IntentManager.startActivity({
        action: TauriApp.ACTION_CHECK_TEMPERATURE,
        pkg: TauriApp.PACKAGE,
        cls: TauriApp.MAIN_ACTIVITY,
      });
    }
  }

  clearThermalCheckInPollTimeout() {
    if (this._thermalCheckInPollTimeout !== null) {
      clearTimeout(this._thermalCheckInPollTimeout);
      this._thermalCheckInPollTimeout = null;
    }
  }

  // @TODO change to onResume
  setThermalCheckInPollTimeout() {
    if (this._thermalCheckInPollTimeout === null) {
      this._thermalCheckInPollTimeout = setTimeout(async () => {
        const baseUrl = await getBaseUrl();
        this._thermalCheckInPollTimeout = null;
        if (this._workflowStore.workflow === null) {
          Axios.get(baseUrl + `/api/locations/${this.locationId}/check_in_records?thermal_check_in=true`, {
            headers: {
              ...this.authenticationHeader,
            },
          })
            .then(async (res: AxiosResponse<Array<any>>) => {
              if (res.data.length !== 0) {
                if (this._workflowStore.workflow === null) {
                  this._workflowStore.setThermalCheckInRecord(res.data[0]);
                }
              } else {
                this.setThermalCheckInPollTimeout();
              }
            })
            .catch((err) => {
              console.error(Etc.LOG_PREFIX + JSON.stringify(err));

              this.setThermalCheckInPollTimeout();
            });
        } else {
          this.setThermalCheckInPollTimeout();
        }
      }, this._thermalCheckInPollTimeoutMillis);
    }
  }

  private _renderAppVersion() {
    AppVersion.getVersionNumber().then(
      (res) => {
        this._appVersion = res;
      },
      (err) => {
        console.info('app version error', err);
        this._appVersion = 'undefined';
      },
    );
  }
}
