import { IonLabel } from '@ionic/react';

import React from 'react';

import '../theme/components/inputs.css';
import GreetlyIcon from './GreetlyIcon';
import Utils from '../utils/Utils';

type InputType =
  | 'number'
  | 'time'
  | 'text'
  | 'date'
  | 'email'
  | 'password'
  | 'search'
  | 'tel'
  | 'url'
  | 'decimal'
  | undefined;

interface Props {
  name?: string;
  type?: InputType;
  inputMode?: 'text' | 'email' | 'search' | 'tel' | 'url' | 'none' | 'numeric' | 'decimal' | undefined;
  forPlaceholder: string;
  forIcon: string;
  isRequired?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: any) => void;
  value?: string;
  readonly?: boolean;
  disabled?: boolean;
  minlength?: number;
  maxlength?: number;
  autofocus?: boolean;
  defaultValue?: string | undefined;
  keyValue?: string;
}

type State = {
  isFocused: boolean;
};

class Inputs extends React.Component<Props, State> {
  placeholderText = React.createRef<HTMLIonLabelElement>();
  greetlyIcon = React.createRef<HTMLSpanElement>();
  actualInput = React.createRef<HTMLInputElement>();
  container = React.createRef<HTMLDivElement>();
  state: State = {
    isFocused: false,
  };
  setPlaceholderPosition = (isSet: boolean, ev: any) => {
    ev.preventDefault();
    ev.stopPropagation();
    const elem = this.placeholderText.current;
    const elemIcon = this.greetlyIcon.current;
    const actualInput = this.actualInput.current;

    if (elem && elemIcon && actualInput) {
      if (ev.currentTarget.value === '') {
        elem.classList.remove('filled');
        if (isSet) {
          actualInput.classList.add('focused');
          elem.classList.add('focused');
          elemIcon.classList.add('iconfocus');
        } else {
          actualInput.classList.remove('focused');
          elem.classList.remove('focused');
          elemIcon.classList.remove('iconfocus');
        }
      } else {
        if (isSet) {
          actualInput.classList.add('focused');
          elem.classList.remove('filled');
          elem.classList.add('focused');
          elemIcon.classList.add('iconfocus');
        } else {
          actualInput.classList.remove('focused');
          elem.classList.remove('focused');
          elemIcon.classList.remove('iconfocus');
          elem.classList.add('filled');
        }
      }
    }
  };

  _fillPlaceHolder() {
    if (
      (this.props.value != null && this.props.value.length > 0) ||
      (this.props.defaultValue != null && this.props.defaultValue.length > 0)
    ) {
      this.placeholderText.current?.classList.add('filled');
    }
  }

  componentDidUpdate() {
    this._fillPlaceHolder();
  }

  componentDidMount() {
    this._fillPlaceHolder();
    if (this.props.autofocus) {
      setTimeout(() => {
        if (this.actualInput.current) {
          this.actualInput.current.focus();
        }
      }, 300);
    }
  }

  nextInput = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    //might break with other elements, test later
    ev.preventDefault();
    ev.stopPropagation();
    if (ev.key === 'Enter') {
      let inputs: any = document.getElementsByClassName('native-input');
      for (let i = 0; i < inputs.length; i++) {
        const input = inputs[i];

        if (this.actualInput.current === input) {
          if (i !== inputs.length - 1) {
            // not last one
            inputs[i + 1].focus();
          } else {
            //last one
            inputs[i].blur();
          }
        }
      }
    }
  };

  validate = (validateClosure?: () => boolean) => {
    if (!(validateClosure != null && validateClosure!())) {
      this.container.current!.classList.add('warning');
      this.placeholderText.current!.classList.add('warning');
      return false;
    } else {
      return (this.props.value ?? '').trim().length > 0;
    }
  };

  clearErrors = () => {
    this.container.current!.classList.remove('warning');
    this.placeholderText.current!.classList.remove('warning');
  };

  getInputType = (type: InputType): InputType => {
    if (Utils.isMobileApp()) {
      return type;
    } else {
      return type === 'date' ? 'text' : type;
    }
  };

  render() {
    return (
      <div className="inputs">
        <div className={'input' + (this.props.inputMode === 'tel' ? '-margin' : '')} ref={this.container}>
          <input
            className="native-input"
            onFocus={(e) => {
              this.setPlaceholderPosition(true, e);
              if (!Utils.isMobileApp())
                if (this.props.type === 'date') {
                  e.target.type = 'date';
                }
            }}
            ref={this.actualInput}
            onBlur={(e) => {
              if (this.actualInput.current && (this.actualInput.current?.value ?? '').trim().length == 0) {
                this.actualInput.current.value = '';
                this.props.onChange!(e);
              }
              this.setPlaceholderPosition(false, e);
              if (!Utils.isMobileApp()) {
                if (this.props.type === 'date') {
                  e.target.type = e.target.value ? 'date' : 'text';
                }
              }
            }}
            defaultValue={this.props.defaultValue}
            onChange={(event) => {
              this.clearErrors();
              this.props.onChange!(event);
            }}
            onKeyPress={this.props.onKeyPress}
            type={this.getInputType(this.props.type)}
            name={this.props.name}
            minLength={this.props.minlength}
            maxLength={this.props.maxlength}
            inputMode={this.props.inputMode}
            required={this.props.isRequired}
            value={this.props.value}
            readOnly={this.props.readonly}
            disabled={this.props.disabled}
            key={this.props.keyValue}
            // enterkeyhint="enter"
            onKeyUp={(event) => {
              this.nextInput(event);
            }}
            // onTouchEnd={undefined}
            // onTouchEndCapture={undefined}
            // onTouchStart={(event)=>{this.focusInput(event);}}
            // mode="md"
            autoCapitalize="true"
            autoFocus={this.props.autofocus}
          />
          <GreetlyIcon forRef={this.greetlyIcon} iconName={this.props.forIcon} />
        </div>
        <IonLabel
          ref={this.placeholderText}
          className={'placeholder' + (this.props.inputMode === 'tel' ? '-margin' : '')}
          position="floating"
        >
          {this.props.forPlaceholder}
          {this.props.isRequired ? '*' : ''}
        </IonLabel>
      </div>
    );
  }
}

export default Inputs;
