import StepModel from './StepModel';
import WorkflowAPIModel from './api/WorkflowAPIModel';
import { StepId, WorkflowType, Paths } from '../commons/Constants';
import VisitorInfoFieldModel from './VisitorInfoFieldModel';
import WorkflowModel_v2 from './v2/WorkflowModel_v2';

export default class WorkflowModel {
  id: number;
  type: string;
  name: string;
  namePolyglot: { [index: string]: any } = {};
  steps: Array<StepModel>;
  order: number;
  iconName: string;
  faIconName: string;
  customIconName: string;
  shouldPhotoCapture: boolean;
  shouldPrintBadge: boolean;
  visitorInfoFields: Array<VisitorInfoFieldModel> = [];
  hasNDASignature: boolean;
  ndaBody: string | null;
  customSuccessMsg: string | null;
  customSuccessMsgPolyglot: { [index: string]: any } = {};
  customSignatureMsg: string | null;
  customSignatureMsgPolyglot: { [index: string]: any } = {};
  successImgSrc: string;
  shouldVerifyGuest: boolean;
  shouldSearchEmployee: boolean;
  useAlternativeWorkflow: boolean;
  alternativeWorkflow?: WorkflowModel_v2 | null; // new workflow system (nodes & edges)

  constructor(workflowAPI: WorkflowAPIModel) {
    this.id = workflowAPI.id;
    this.name = workflowAPI.name;
    this.namePolyglot = workflowAPI.name_polyglot;
    this.type = workflowAPI.option_type;
    this.steps = workflowAPI.steps;
    this.order = workflowAPI.order;
    this.iconName = workflowAPI.icon_name;
    this.faIconName = workflowAPI.fa_icon_name;
    this.customIconName = workflowAPI.custom_icon_name;
    this.shouldPhotoCapture = workflowAPI.photo_capture;

    this.useAlternativeWorkflow = workflowAPI.use_workflow && workflowAPI.workflow != null;
    if (this.useAlternativeWorkflow) {
      this.alternativeWorkflow = new WorkflowModel_v2(workflowAPI.workflow!);
      // this.visitorInfoFields = JSON.parse('[{"id": 187905, "check_in_option_id": 145510, "label": "first_name", "label_polyglot": {}, "is_custom": false, "order": 0, "data_type": "dl_first_name", "options": [], "display_label": "First Name" }, {"id": 187906, "check_in_option_id": 145510, "label": "last_name", "label_polyglot": {}, "is_custom": false, "order": 1, "data_type": "dl_last_name", "options": [], "display_label": "Last Name" }]');
    }

    // add photo capture step
    if (this.shouldPhotoCapture) {
      // add before success
      const successIndex = this.steps.findIndex((step) => step.id === StepId.SUCCESS);
      if (successIndex !== -1) {
        this.steps.splice(successIndex, 0, { id: StepId.PHOTO_CAPTURE });
      } else {
        this.steps.splice(this.steps.length - 1, 0, { id: StepId.PHOTO_CAPTURE });
      }
    }

    this.shouldPrintBadge =
      workflowAPI.print_badge ||
      Object.values(this.alternativeWorkflow?.nodes ?? {}).find((node) => node.type == 'print_badge') != null;

    this.shouldSearchEmployee = workflowAPI.search_employees;

    this.visitorInfoFields = workflowAPI.visitor_info_fields
      .filter((model) => {
        // Don't display static fields. This is to allow non-interactive values that are basically metadata.
        return model.data_type !== 'static';
      })
      .map((model) => {
        return new VisitorInfoFieldModel(model);
      });

    this.visitorInfoFields.sort((a, b) => {
      if (a.order > b.order) {
        return 1;
      } else if (a.order < b.order) {
        return -1;
      } else {
        return 0;
      }
    });

    this.hasNDASignature = workflowAPI.nda_signature;

    this.ndaBody = workflowAPI.nda_body;
    // add signature to steps
    if (this.hasNDASignature) {
      const successIndex = this.steps.findIndex((step) => step.id === StepId.SUCCESS);
      const signatureStep = { id: StepId.SIGNATURE };
      if (successIndex !== -1) {
        // replace success step
        this.steps.splice(successIndex, 0, signatureStep);
      } else {
        // add at the end
        this.steps.push(signatureStep);
      }
    }

    this.customSuccessMsg = workflowAPI.custom_success_message;
    this.customSuccessMsgPolyglot = workflowAPI.custom_success_message_polyglot;
    this.customSignatureMsg = workflowAPI.custom_message_for_signature;
    this.customSignatureMsgPolyglot = workflowAPI.custom_message_for_signature_polyglot;

    switch (this.type) {
      case WorkflowType.VISIT_HOST:
        this.successImgSrc = Paths.GIF + '/Msg.gif';
        break;
      case WorkflowType.FOOD_DELIVERY:
        this.successImgSrc = Paths.GIF + '/Food.gif';
        break;
      case WorkflowType.PACKAGE_DELIVERY:
        this.successImgSrc = Paths.GIF + '/Package.gif';
        break;
      case WorkflowType.EMPLOYEE_CHECK_IN:
        this.successImgSrc = Paths.GIF + '/Msg.gif';
        this.steps = [];
        this.steps.push({ id: StepId.EMPLOYEE_CHECK_IN });
        this.steps.push({ id: StepId.SUCCESS });
        break;
      default:
        this.successImgSrc = Paths.GIF + '/Check.gif';
        break;
    }

    this.shouldVerifyGuest = workflowAPI.verify_guest !== null && workflowAPI.verify_guest;
    // add scan to steps
    if (this.shouldVerifyGuest) {
      const visitorIndex = this.steps.findIndex((step) => step.id === StepId.VISITOR);
      const scanStep = { id: StepId.SCAN };
      if (visitorIndex !== -1) {
        // add before visitor step
        this.steps.splice(visitorIndex, 0, scanStep);
      } else {
        // add at the start
        this.steps.splice(0, 0, scanStep);
      }
    }
  }
}
