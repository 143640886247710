import OutCheckInRecordModel from '../OutCheckInRecordModel';

export default class OutCheckInRecordAPIModel {
  host_id?: number;
  delivery_signature_required?: boolean;
  visitor_info_fields?: any;
  check_in_option_id?: number;
  signature_record_id?: number;
  to_print?: boolean;
  visitor_photo?: string;
  visitor_photo_file_name?: string;
  visitor_photo_content_type?: string;
  driver_license_front_photo?: string;
  driver_license_front_photo_file_name?: string;
  driver_license_front_photo_content_type?: string;
  driver_license_back_photo?: string;
  driver_license_back_photo_file_name?: string;
  driver_license_back_photo_content_type?: string;
  checked_in?: string;
  location_id?: number;
  temperature?: string;
  send_text?: string;
  steps: any;
  success_check_in?: boolean;
  old_check_in_record_id?: number;
  device_id: string | null;
  visitor_photo_attached?: boolean;
  attachments_update?: boolean;

  constructor(outCheckInRecord: OutCheckInRecordModel) {
    this.host_id = outCheckInRecord.hostId;
    this.delivery_signature_required = outCheckInRecord.deliverySignatureRequired;
    this.visitor_info_fields = outCheckInRecord.visitorInfoFieldValues;
    this.check_in_option_id = outCheckInRecord.checkInOptionId;
    this.signature_record_id = outCheckInRecord.signatureRecordId;
    this.to_print = outCheckInRecord.toPrint;
    this.location_id = outCheckInRecord.locationId;
    this.temperature = outCheckInRecord.temperature;
    this.send_text = outCheckInRecord.send_text;
    this.steps = outCheckInRecord.steps;
    this.success_check_in = outCheckInRecord.success_check_in;
    this.old_check_in_record_id = outCheckInRecord.old_check_in_record_id;
    this.device_id = outCheckInRecord?.device_id || null;
    this.visitor_photo_attached = outCheckInRecord.visitor_photo_attached;
    this.attachments_update = outCheckInRecord.attachments_update;
  }
}
