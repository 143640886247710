import Utils from './Utils';

export default class HiddenMenuHelper {
  private static readonly CLICK_TO_ACTION = 3;

  resetClickCounterTimeout: any;
  clickCounter: number = 0;

  clearTimeout = () => {
    if (this.resetClickCounterTimeout !== null) {
      clearTimeout(this.resetClickCounterTimeout);
    }
  };

  onClick = (actionCall?: () => void) => {
    if (Utils.isNative()) {
      this.clickCounter += 1;
      if (this.clickCounter === HiddenMenuHelper.CLICK_TO_ACTION) {
        actionCall && actionCall();
      }

      if (this.resetClickCounterTimeout !== null) {
        clearTimeout(this.resetClickCounterTimeout);
      }

      this.resetClickTimeout();
    }
  };

  private resetClickTimeout = () => {
    this.resetClickCounterTimeout = setTimeout(() => {
      this.clickCounter = 0;
      this.resetClickCounterTimeout = null;
    }, 500);
  };
}
