import React from 'react';
import AppStore from '../mobx/AppStore';
import { IonButton } from '@ionic/react';
import '../theme/components/hiddenMenu.css';
import { useTranslation } from 'react-i18next';

interface Props {
  appStore: AppStore;
  close: () => void;
}

const LanguageMenu: React.FC<Props> = ({ appStore, close }) => {
  const { i18n } = useTranslation();

  const changeTabletLanguage = (value: any) => {
    const { locationStore } = appStore;
    locationStore.setLanguage(value);
    close();
  };

  const { locationStore } = appStore;

  return (
    <div className="hidden-menu">
      <div className="hidden-container">
        {locationStore.location!.terminologySetting.tabletTerminologies.map((tabletTerminology, index) => {
          if (tabletTerminology.languageName === null) {
            return (
              <IonButton
                key={index}
                className={`hidden-btn ${'English' === i18n.language ? 'active' : ''}`}
                onClick={(event) => {
                  event.stopPropagation();
                  changeTabletLanguage(tabletTerminology.languageName);
                }}
              >
                English
              </IonButton>
            );
          } else {
            return (
              <IonButton
                key={index}
                className={`hidden-btn ${tabletTerminology.languageName === i18n.language ? 'active' : ''}`}
                onClick={(event) => {
                  event.stopPropagation();
                  changeTabletLanguage(tabletTerminology.languageName);
                }}
              >
                {tabletTerminology.languageName}
              </IonButton>
            );
          }
        })}
        <IonButton
          className="hidden-btn"
          onClick={(event) => {
            event.stopPropagation();
            close();
          }}
        >
          Close
        </IonButton>
      </div>
    </div>
  );
};

export default LanguageMenu;
