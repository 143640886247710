import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, isPlatform } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { setupIonicReact } from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/generic.css';
import NoMatch from './pages/NoMatch';
import { PathName } from './commons/Constants';
import MainWeb from './pages/MainWeb';

/* FontAwesome libray for all app */
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import NoParams from './pages/NoParams';
import UnknownError from './pages/UnknownError';
import SessionExpired from './pages/SessionExpired';

import MainNative from './pages/MainNative';

import WebStore from './mobx/WebStore';
import NativeStore from './mobx/NativeStore';

import { Observer } from 'mobx-react';
import { Capacitor } from '@capacitor/core';
import Rollbar from 'rollbar';
import ENV from './env';

import InitManager from './plugins/InitManager';

library.add(far, fas);

export const rollbar = new Rollbar({
  accessToken: '482a54da71eb405eb2293d48747a7ee6',
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: ENV.stage,
});

function App() {
  const nativeStore = new NativeStore();
  const webStore = new WebStore();
  const appStore = Capacitor.isNativePlatform() ? nativeStore : webStore;

  setupIonicReact();

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      if (isPlatform('ios')) {
        InitManager.init();
      }
      window.addEventListener('resize', () => nativeStore.onWindowResize());
      nativeStore.onWindowResize();
    } else {
      window.addEventListener('resize', () => webStore.onWindowResize());
      webStore.onWindowResize();
    }
  }, []);

  return (
    <Observer>
      {() => {
        return (
          <IonApp>
            {Capacitor.isNativePlatform() ? (
              <MainNative nativeStore={appStore} />
            ) : (
              <IonReactRouter>
                <IonRouterOutlet>
                  <Route path="/" render={() => <MainWeb webStore={appStore as WebStore} />} exact />
                  <Route path={PathName.RETRY} render={() => <NoParams />} exact />
                  <Route path={PathName.SESSION_EXPIRED} render={() => <SessionExpired />} exact />
                  <Route path={PathName.UNKNOWN_ERROR} render={() => <UnknownError />} exact />
                  <Route path={PathName.NOT_FOUND} render={() => <NoMatch />} exact />
                  <Redirect to={PathName.NOT_FOUND} exact />
                </IonRouterOutlet>
              </IonReactRouter>
            )}
          </IonApp>
        );
      }}
    </Observer>
  );
}

export default App;
