import WorkflowNodeAPI from '../api/v2/WorkflowNodeAPI';

export default class WorkflowNode {
  id: string;
  type: string;
  checkInOptionId: number | null;
  formId: number | null;
  formData: any | null;
  data: any | null;

  constructor(workflowNodeAPI: WorkflowNodeAPI) {
    this.id = workflowNodeAPI.id;
    this.type = workflowNodeAPI.data['node_type'] ?? workflowNodeAPI.type;
    this.checkInOptionId = workflowNodeAPI.checkInOptionId;
    this.formId = workflowNodeAPI.formId;
    this.data = workflowNodeAPI.data;
    this.formData = workflowNodeAPI.data['formData'];
  }
}
