import { IonItem, IonLabel } from '@ionic/react';
import React from 'react';
import { InAppBrowser } from '@ionic-native/in-app-browser';
import '../theme/components/url_field.css';
import Utils from '../utils/Utils';
import i18next, { t } from 'i18next';

interface Props {
  urlLabel?: string;
  url?: string;
  i18nBase?: string;
}

class URL_Field extends React.Component<Props> {
  item = React.createRef<HTMLIonItemElement>();

  onClick = () => {
    const browser = InAppBrowser.create(this.props.url ?? '', Utils.isMobileApp() ? '_blank' : '_system', {
      usewkwebview: 'yes',
      closebuttoncaption: 'Close',
    });
    browser.show();
  };

  render() {
    const i18Key = `${this.props.i18nBase}.description`;
    const optionValue = i18next.exists(i18Key) ? t(i18Key) : this.props.urlLabel || '';

    return (
      <IonItem button ref={this.item} lines="none" className="url_field" onClick={this.onClick}>
        <IonLabel className="name">{optionValue}</IonLabel>
      </IonItem>
    );
  }
}

export default URL_Field;
