import React, { useEffect, useRef, useState } from 'react';

import GreetlyIcon from '../../../GreetlyIcon';
import { resizeText } from '../WorkflowsBtnHelper';
import '../style.scss';
import './style.scss';

function WorkflowsDarkBtn(props: any) {
  const { themeButton, setWorkflow, label, workflowId, iconName } = props;
  const [labelSize, setLabelSize] = useState('');
  const ref: any = useRef(null);
  const labelRef: any = useRef(null);
  const textRef = useRef(null);

  const countSpace = () => {
    resizeText({
      element: labelRef.current,
      parent: textRef.current,
      step: 0.5,
    });
  };

  // Check DeviceRotation
  useEffect(() => {
    window?.screen?.orientation?.addEventListener('change', countSpace);
    window.addEventListener('resize', countSpace);

    return () => {
      window?.screen?.orientation?.removeEventListener('change', countSpace);
      window.removeEventListener('resize', countSpace);
    };
  }, []);

  useEffect(() => {
    countSpace();
  }, [ref?.current?.clientHeight]);

  return (
    <div className={`workflow-item-${themeButton} bottom`} onClick={setWorkflow}>
      <div className="workflow-button">
        <div className="workflow-container" ref={ref}>
          <div className="image-container">
            <GreetlyIcon iconName={iconName} />
          </div>
          <div className="text-container">
            <div className="workflow-label" ref={textRef} id={workflowId}>
              <span className="text label-text" ref={labelRef}>
                {label}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkflowsDarkBtn;
