import React from 'react';
import BarcodeScannerOptions from '../models/BarcodeScannerOptions';
import { BrowserMultiFormatReader, DecodeHintType, BarcodeFormat } from '@zxing/library';
import BarcodeScanner, { BarcodeScannerProps } from './BarcodeScanner';
import '../theme/components/webBarcodeScanner.css';

type State = {
  error: string | null;
  shouldShowMask: boolean;
  maskWidth: string;
  maskLeft: string;
  maskSubHeight: string;
  maskSub1Top: string;
  lineColor: string;
};

class BarcodeFormatEnumStrPair {
  enum!: BarcodeFormat;
  str!: string;
}

// @TODO stop, restart, orientation change
class WebBarcodeScanner extends BarcodeScanner<BarcodeScannerProps, State> {
  private barcodeFormatEnumStrMap = new Array<BarcodeFormatEnumStrPair>();
  private browserMultiFormatReader: BrowserMultiFormatReader | null = null;
  private previewVideo = React.createRef<HTMLVideoElement>();
  private lineColorTimeout: NodeJS.Timeout | null = null;

  state: State = {
    error: null,
    shouldShowMask: false,
    maskWidth: '0px',
    maskLeft: '0px',
    maskSubHeight: '0px',
    maskSub1Top: '0px',
    lineColor: 'red'
  };

  constructor(props: BarcodeScannerProps) {
    super(props);

    this.barcodeFormatEnumStrMap.push({ enum: BarcodeFormat.PDF_417, str: 'PDF_417' });
    this.barcodeFormatEnumStrMap.push({ enum: BarcodeFormat.QR_CODE, str: 'QR_CODE' });
    this.barcodeFormatEnumStrMap.push({ enum: BarcodeFormat.EAN_8, str: 'EAN_8' });
    this.barcodeFormatEnumStrMap.push({ enum: BarcodeFormat.EAN_13, str: 'EAN_13' });
    this.barcodeFormatEnumStrMap.push({ enum: BarcodeFormat.CODE_39, str: 'CODE_39' });
    this.barcodeFormatEnumStrMap.push({ enum: BarcodeFormat.CODE_93, str: 'CODE_93' });
    this.barcodeFormatEnumStrMap.push({ enum: BarcodeFormat.CODE_128, str: 'CODE_128' });
    this.barcodeFormatEnumStrMap.push({ enum: BarcodeFormat.UPC_A, str: 'UPC_A' });
    this.barcodeFormatEnumStrMap.push({ enum: BarcodeFormat.UPC_E, str: 'UPC_E' });
    this.barcodeFormatEnumStrMap.push({ enum: BarcodeFormat.CODABAR, str: 'CODABAR' });
    this.barcodeFormatEnumStrMap.push({ enum: BarcodeFormat.ITF, str: 'ITF' });
    this.barcodeFormatEnumStrMap.push({ enum: BarcodeFormat.DATA_MATRIX, str: 'DATA_MATRIX' });
    this.barcodeFormatEnumStrMap.push({ enum: BarcodeFormat.AZTEC, str: 'AZTEC' });
    this.barcodeFormatEnumStrMap.push({ enum: BarcodeFormat.RSS_EXPANDED, str: 'RSS_EXPANDED' });
  }

  componentDidMount() {
    if (this.props.shouldAutoStart) {
      this.tryStart(this.props.options);
    }
  }

  componentWillUnmount() {
    if (this.lineColorTimeout !== null) {
      clearTimeout(this.lineColorTimeout);
      this.lineColorTimeout = null;
    }

    if (this.browserMultiFormatReader !== null) {
      this.browserMultiFormatReader.stopContinuousDecode();
      this.browserMultiFormatReader = null;

      if (this.previewVideo.current !== null && this.previewVideo.current.srcObject !== null) {
        const mediaStream = this.previewVideo.current.srcObject as MediaStream;
        const tracks = mediaStream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
      }
    }
  }

  tryStart(options?: BarcodeScannerOptions) {
    let facingMode;
    if (options !== undefined && options.isFrontCamera !== undefined) {
      facingMode = options.isFrontCamera ? 'user' : 'environment';
    } else {
      facingMode = 'environment';
    }
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: facingMode } })
      .then(() => {
        this.start(options);
      })
      .catch(() => {
        this.setState({ error: 'Please allow camera access' });
      });
  }

  start(options?: BarcodeScannerOptions) {
    let hints: Map<any, any> | undefined = undefined;
    if (options !== undefined) {
      if (options.possibleFormats !== undefined) {
        const possibleBarcodeFormats: Array<BarcodeFormat> = [];
        for (let i = 0; i < options.possibleFormats.length; ++i) {
          const barcodeFormat = this.barcodeFormatStrToEnum(options.possibleFormats[i]);
          if (barcodeFormat !== undefined) {
            possibleBarcodeFormats.push(barcodeFormat);
          }
        }

        hints = new Map();
        hints.set(DecodeHintType.POSSIBLE_FORMATS, possibleBarcodeFormats);
      }
    }

    this.browserMultiFormatReader = new BrowserMultiFormatReader(hints);

    if (this.browserMultiFormatReader.canEnumerateDevices) {
      this.browserMultiFormatReader
        .listVideoInputDevices()
        .then((deviceInfos) => {
          if (deviceInfos.length !== 0) {
            let deviceInfo: MediaDeviceInfo | undefined;
            if (options !== undefined && options.isFrontCamera !== undefined) {
              const findStr = options.isFrontCamera ? 'front' : 'back';
              deviceInfo = deviceInfos.find((deviceInfo) => deviceInfo.label.match(new RegExp(findStr, 'i')));
            }

            if (deviceInfo === undefined) {
              deviceInfo = deviceInfos[0];
            }

            this.browserMultiFormatReader!.decodeFromVideoDevice(
              deviceInfo.deviceId,
              'web-barcode-scanner-preview-video',
              (res) => {
                if (!this.state.shouldShowMask && this.previewVideo.current!.videoWidth !== 0) {
                  const rect = this.previewVideo.current!.getBoundingClientRect();
                  const maxWidth = rect.width;
                  const maxHeight = rect.height;
                  const width = this.previewVideo.current!.videoWidth;
                  const height = this.previewVideo.current!.videoHeight;
                  const ratioW = maxWidth / width;
                  const ratioH = maxHeight / height;

                  let scaledWidth: number;
                  if (ratioH > ratioW) {
                    scaledWidth = maxWidth;
                  } else {
                    scaledWidth = width * ratioH;
                  }

                  let maskPercent: number;
                  if (options !== undefined && options.maskPercent !== undefined) {
                    maskPercent = options.maskPercent;
                  } else {
                    maskPercent = 0.4;
                  }

                  const maskSubPercent = maskPercent * 0.5;
                  const notMaskPercent = 1 - maskPercent;

                  const maskSubPercent100 = maskSubPercent * 100;

                  const maskSub1TopPercent = maskSubPercent + notMaskPercent;

                  this.setState({
                    shouldShowMask: true,
                    maskWidth: scaledWidth + 'px',
                    maskLeft: maxWidth * 0.5 - scaledWidth * 0.5 + 0.5 + 'px',
                    maskSubHeight: maskSubPercent100 + '%',
                    maskSub1Top: maskSub1TopPercent * 100 + '%'
                  });
                }

                if (res !== null && this.lineColorTimeout === null) {
                  this.setState({
                    lineColor: '#00FF00'
                  });

                  this.lineColorTimeout = setTimeout(() => {
                    this.setState({
                      lineColor: 'red'
                    });
                    this.lineColorTimeout = null;
                  }, 1000);

                  this.props.onSuccess?.({
                    barcodeFormat: this.barcodeFormatEnumToStr(res.getBarcodeFormat()),
                    text: res.getText()
                  });
                }
              }
            );
          } else {
            throw new Error('No camera');
          }
        })
        .catch((err) => {
          if (err.message !== undefined) {
            if (err.message === 'No camera') {
              this.setState({ error: 'Please allow camera access' });
            }
          }
        });
    } else {
      this.setState({ error: 'Please allow camera access' });
    }

    this.props.onStart?.();
  }

  barcodeFormatEnumToStr(barcodeFormat: BarcodeFormat): string {
    const pair = this.barcodeFormatEnumStrMap.find((pair) => pair.enum === barcodeFormat);
    return pair !== undefined ? pair.str : 'Unknown';
  }

  barcodeFormatStrToEnum(barcodeFormat: string): BarcodeFormat | undefined {
    const pair = this.barcodeFormatEnumStrMap.find((pair) => pair.str === barcodeFormat);
    return pair !== undefined ? pair.enum : undefined;
  }

  render() {
    return (
      <div className={this.props.className}>
        <div className="web-barcode-scanner">
          {this.state.error !== null ? (
            <div className="error">{this.state.error}</div>
          ) : (
            <>
              <div className="preview">
                <video ref={this.previewVideo} id="web-barcode-scanner-preview-video" />

                {this.state.shouldShowMask && (
                  <div
                    className="mask"
                    style={{
                      width: this.state.maskWidth,
                      left: this.state.maskLeft
                    }}
                  >
                    <div
                      className="sub0"
                      style={{
                        height: this.state.maskSubHeight
                      }}
                    />
                    <div
                      className="sub1"
                      style={{
                        height: this.state.maskSubHeight,
                        top: this.state.maskSub1Top
                      }}
                    />
                    <div
                      className="line"
                      style={{
                        backgroundColor: this.state.lineColor
                      }}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default WebBarcodeScanner;
