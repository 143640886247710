import BadgeSettingAPIModel from './api/BadgeSettingAPIModel';

export default class BadgeSettingModel {
  ipAddress: string;
  badgeText: string;
  shouldDisplayName: boolean;
  shouldDisplayHost: boolean;
  shouldDisplayDate: boolean;

  constructor(badgeSettingAPI: BadgeSettingAPIModel) {
    this.ipAddress = badgeSettingAPI.ip_address;
    this.badgeText = badgeSettingAPI.badge_text === null ? '' : badgeSettingAPI.badge_text;
    this.shouldDisplayName = badgeSettingAPI.display_name;
    this.shouldDisplayHost = badgeSettingAPI.display_host;
    this.shouldDisplayDate = badgeSettingAPI.display_date;
  }
}
