import { IonContent, IonPage, IonLabel, IonButton } from '@ionic/react';
import { Redirect } from 'react-router-dom';
import React from 'react';
import { observer } from 'mobx-react';
import { t } from 'i18next';

import Step, { StepProps } from '../Step';
import Loading from '../../../components/Loading';
import SignatureCanvas from 'react-signature-canvas';
import { WorkflowStorePostSignatureError } from '../../../mobx/WorkflowStore';
import { PathName } from '../../../commons/Constants';
import Utils from '../../../utils/Utils';
import DesignHelper from '../DesignHelper';
import './style.scss';

type State = {
  isLoading: boolean;
  width: number;
  shouldRedirectToSessionExpired: boolean;
  shouldRedirectToUnknownError: boolean;
};

class Signature extends Step<StepProps, State> {
  private modifiedHTML: string;
  private penColor: string;

  constructor(props: StepProps) {
    super(props);
    const { workflowStore } = this.props.appStore;
    this.penColor = getComputedStyle(document.documentElement).getPropertyValue('--text-color');
    this.modifiedHTML =
      workflowStore.workflow!.ndaBody?.replace(new RegExp('color: black', 'g'), 'color: ' + this.penColor) ?? '';
  }

  state: State = {
    isLoading: false,
    width: window.innerWidth,
    shouldRedirectToSessionExpired: false,
    shouldRedirectToUnknownError: false,
  };

  signatureCanvas: SignatureCanvas = React.createRef<SignatureCanvas>().current!;
  contentRef = React.createRef<any>().current!;

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    // this.contentRef.scrollToBottom(0);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  goToNextStep = async () => {
    this.setState({ isLoading: true });
    const { workflowStore } = this.props.appStore;
    workflowStore.postSignature(this.signatureCanvas.toDataURL('image/png')).catch((err) => {
      if (Utils.isNative()) {
        Utils.showErrorToast('An error occurred on signing the document');
        // this.setState({ isLoading: false });
      } else {
        switch (err) {
          case WorkflowStorePostSignatureError.UNAUTHORIZED:
            this.setState({ shouldRedirectToSessionExpired: true });
            break;
          case WorkflowStorePostSignatureError.UNKNOWN:
          default: {
            this.setState({ shouldRedirectToUnknownError: true });
          }
        }
      }
    });
    workflowStore.goToNextStep();
  };

  clearSignature = () => {
    this.signatureCanvas.clear();
  };

  checkSignAndNext = () => {
    if (!this.signatureCanvas.isEmpty()) {
      this.goToNextStep();
    } else {
      document.getElementById('ndaError')?.classList.remove('hidden');
    }
  };

  visitorFullName = () => {
    const visitorFields = this.props.appStore.workflowStore.checkInRecord!.visitorInfoFieldValues!;
    if (
      visitorFields != undefined &&
      (visitorFields.first_name !== undefined || visitorFields.last_name !== undefined)
    ) {
      if (visitorFields.last_name !== undefined) {
        return visitorFields.first_name + ' ' + visitorFields.last_name;
      }
      return visitorFields.first_name;
    } else {
      return '';
    }
  };

  visitorEmail = () => {
    const visitorFields = this.props.appStore.workflowStore.checkInRecord!.visitorInfoFieldValues!;
    if (visitorFields.email !== undefined) {
      return visitorFields.email;
    } else {
      return '';
    }
  };

  getDate = (inDate: string | number | Date) => {
    const event = new Date(inDate);

    // @TODO use actual locale of device
    return event.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
  };

  render() {
    if (!Utils.isNative()) {
      if (this.state.shouldRedirectToSessionExpired) {
        return <Redirect to={PathName.SESSION_EXPIRED} />;
      } else if (this.state.shouldRedirectToUnknownError) {
        return <Redirect to={PathName.UNKNOWN_ERROR} />;
      }
    }

    const { locationStore, workflowStore } = this.props.appStore;

    const themeMain = locationStore.themeMain;
    const themeButton = locationStore.themeButton;

    return (
      <IonPage className="signature">
        <div className="signature-header">
          {Utils.isNative() && DesignHelper.renderHomeButton(themeButton, () => workflowStore.reset(true))}
          {DesignHelper.renderThemeLabelTitle(themeMain, locationStore.tabletTerminologies.pleaseSign)}
        </div>
        <IonContent ref={(ref) => (this.contentRef = ref!)} scrollEvents={true}>
          {workflowStore.workflow!.hasNDASignature && (
            <div className="NDA-text" dangerouslySetInnerHTML={{ __html: this.modifiedHTML }}></div>
          )}
        </IonContent>
        {/* @TODO Terminology missing */}
        <IonLabel className="signature-text">Signature</IonLabel>

        <SignatureCanvas
          ref={(ref) => (this.signatureCanvas = ref!)}
          canvasProps={{ className: 'sign-canvas', width: this.state.width - 21, height: 200 }}
          penColor={this.penColor}
        />
        <div className="signature-above">
          <IonLabel className="date">{this.getDate(new Date())}</IonLabel>
          <IonLabel className="fullName">{this.visitorFullName()}</IonLabel>
          <IonButton className="clearbtn white-button" onClick={this.clearSignature}>
            Clear
          </IonButton>
        </div>
        <div className="ndaError">
          &nbsp;
          <span id="ndaError" className="error hidden">
            Please Sign The Document To Continue
          </span>
          &nbsp;
        </div>

        <div className="signature-below">
          <IonButton className="generic-button" onClick={this.checkSignAndNext}>
            {/* used to be angle-double-right */}
            <div>{t('next')}</div>
          </IonButton>
        </div>

        {/* @TODO Terminology missing */}
        {/* @TODO Until back email setUP, we will hide this area, currently not sending email */}
        {/* <IonLabel className="email-copy">A copy of the signed agreement will be sent to: <br /> {this.visitorEmail()}</IonLabel> */}
        {this.state.isLoading && <Loading />}
      </IonPage>
    );
  }
}

export default observer(Signature);
