import React, { useEffect, useState } from 'react';
import { Capacitor, Plugins } from '@capacitor/core';
import { observer } from 'mobx-react';
import { Network } from '@capacitor/network';
import { Insomnia } from '@ionic-native/insomnia';
import { NavigationBar } from '@ionic-native/navigation-bar';
import { NavigationBar as NB } from '@hugotomazi/capacitor-navigation-bar';

import Login from './Login';
import LoggedIn from './LoggedIn';
import NoConnection from './NoConnection';
import GrantPermissions from './GrantPermissions';
import Utils from '../utils/Utils';
const { StatusBar } = Plugins;

const MainNative = observer((props: any) => {
  const { nativeStore } = props;
  const [isConnected, setIsConnected] = useState(false);

  //componentWillMount
  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      nativeStore.requestPermissions();
    }

    Insomnia.keepAwake();
    if (Utils.isMobileApp()) {
      NB.hide();
      // NavigationBar.hideNavigationBar();
      StatusBar?.hide();
    }

    Network.addListener('networkStatusChange', (status: any) => {
      nativeStore.onNetworkStatusChange(status);
    });

    // ask for all required native permissions
    Network.getStatus().then((props) => {
      setIsConnected(props.connected);
    });
  }, [nativeStore]);

  return (
    <>
      {nativeStore.arePermissionsGranted ? (
        <>
          {isConnected ? (
            <>
              {/* is logged in */}
              {nativeStore.account !== null ? <LoggedIn {...props} /> : <Login {...props} />}
            </>
          ) : (
            <NoConnection />
          )}
        </>
      ) : (
        <>
          <GrantPermissions {...props} />
        </>
      )}
    </>
  );
});

export default MainNative;
