export default class DriverLicenseDataModel {
  dl_id?: string;
  last_name?: string;
  first_name?: string;
  dob?: string;
  street_address1?: string;
  street_address2?: string;
  city?: string;
  jurisdiction_code?: string;
  postal_code?: string;
  gender?: string;

  static readonly visitorInfoFieldDLDataKeyMap: Record<string, string> = {
    dl_license_no: 'dl_id',
    dl_license_no_edit: 'dl_id',
    dl_first_name: 'first_name',
    dl_last_name: 'last_name',
    dl_dob: 'dob',
    dl_street_address1: 'street_address1',
    dl_street_address2: 'street_address2',
    dl_city: 'city',
    dl_jurisdiction_code: 'jurisdiction_code',
    dl_postal_code: 'postal_code',
    dl_gender: 'gender',
  };

  static parseText(text: string) {
    const codeFieldMap = [
      ['DAQ', 'dl_id'],
      ['DCS', 'last_name'],
      ['DAB', 'last_name'],
      ['DBO', 'last_name'],
      ['DCT', 'first_name'],
      ['DBP', 'first_name'],
      ['DAC', 'first_name'],
      ['DBC', 'gender'],
      ['DBB', 'dob'],
      ['DBL', 'dob'],
      ['DAG', 'street_address1'],
      ['DAM', 'street_address2'],
      ['DAI', 'city'],
      ['DAJ', 'jurisdiction_code'],
      ['DAK', 'postal_code'],
    ];

    const data: any = {};
    codeFieldMap.forEach((codeFieldPair) => {
      const code = codeFieldPair[0];
      if (!data[codeFieldPair[1].trim()]) {
        const regExpStr = code + '.*?\\n';
        const matches = text.match(new RegExp(regExpStr));
        if (matches) {
          const match = matches[0];
          let val = match.substring(code.length, match.length - 1);
          val = ['DBB', 'DBL'].includes(code) ? (val = val.replace(/^(\d{2})(\d{2})(\d{4})$/, '$3-$1-$2')) : val; // we got Drivres License date format '25021988' instead '1988-02-25'
          data[codeFieldPair[1]] = val.trim();
        }
      }
    });

    // if postal code longer than 5 digit, slice last 0 numbers
    // @TODO need to test/check if slice correct place and number
    const POSTAL_CODE_LENGTH = 5;
    if (data.postal_code && data.postal_code.length >= POSTAL_CODE_LENGTH) {
      data.postal_code = data.postal_code.substring(0, POSTAL_CODE_LENGTH);
    }

    return data as DriverLicenseDataModel;
  }
}
