import TerminologyLanguageAPIModel from './api/TerminologyLanguageAPIModel';

export default class TerminologyLanguageModel {
  homeScreen: string | null;
  homeScreen_btn: string | null;
  visitorCheckin: string | null;
  dlScanTitle: string | null;
  language: string | null;
  employeeSearch: string | null;
  signatureRequired: string | null;
  yes: string | null;
  no: string | null;
  successMessage: string | null;
  success: string | null;
  exit: string | null;
  notifyRecipient: string | null;
  whoIsVisiting: string | null;
  gdpr: string | null;
  ndaAgreement: string | null;
  pleaseSign: string | null;
  prereg: string | null;
  repeat: string | null;
  preRegistBefore: string | null;
  visitBefore: string | null;
  enterPin: string | null;
  searchByEmail: string | null;
  smile: string | null;
  smileCount: string | null;
  pleaseConfirmInformation: string | null;
  yourHost: string | null;
  cancel: string | null;
  search: string | null;
  next: string | null;
  submit: string | null;
  reset: string | null;
  checkoutHeaderLabel: string | null;
  checkoutSuccessLabel: string | null;
  checkoutPlaceholder: string | null;
  noTouchTitle: string | null;
  noTouchQrCode: string | null;
  noTouchBarcodeScan: string | null;
  languageName: string | null;
  default?: boolean;
  frontSideDriverLicense: string | null;
  backSideDriverLicense: string | null;
  frontSideDriverLicenseHeader: string | null;
  backSideDriverLicenseHeader: string | null;
  packageBlockTitle: string | null;

  constructor(terminologyLanguageAPI: TerminologyLanguageAPIModel) {
    this.homeScreen = terminologyLanguageAPI.home_screen;
    this.homeScreen_btn = terminologyLanguageAPI.home_screen_btn;
    this.visitorCheckin = terminologyLanguageAPI.visitor_checkin;
    this.dlScanTitle = terminologyLanguageAPI.dl_scan_title;
    this.language = terminologyLanguageAPI.language;
    this.employeeSearch = terminologyLanguageAPI.employee_search;
    this.signatureRequired = terminologyLanguageAPI.signature_required;
    this.yes = terminologyLanguageAPI.yes;
    this.no = terminologyLanguageAPI.no;
    this.successMessage = terminologyLanguageAPI.success_message;
    this.success = terminologyLanguageAPI.success;
    this.exit = terminologyLanguageAPI.exit;
    this.notifyRecipient = terminologyLanguageAPI.notify_recipient;
    this.whoIsVisiting = terminologyLanguageAPI.who_is_visiting;
    this.gdpr = terminologyLanguageAPI.gdpr;
    this.ndaAgreement = terminologyLanguageAPI.nda_agreement;
    this.pleaseSign = terminologyLanguageAPI.please_sign;
    this.prereg = terminologyLanguageAPI.prereg;
    this.repeat = terminologyLanguageAPI.repeat;
    this.preRegistBefore = terminologyLanguageAPI.pre_regist_before;
    this.visitBefore = terminologyLanguageAPI.visit_before;
    this.enterPin = terminologyLanguageAPI.enter_pin;
    this.searchByEmail = terminologyLanguageAPI.search_by_email;
    this.smile = terminologyLanguageAPI.smile;
    this.smileCount = terminologyLanguageAPI.smile_count;
    this.pleaseConfirmInformation = terminologyLanguageAPI.please_confirm_information;
    this.yourHost = terminologyLanguageAPI.your_host;
    this.cancel = terminologyLanguageAPI.cancel;
    this.search = terminologyLanguageAPI.search;
    this.next = terminologyLanguageAPI.next;
    this.submit = terminologyLanguageAPI.submit;
    this.reset = terminologyLanguageAPI.reset;
    this.checkoutHeaderLabel = terminologyLanguageAPI.banner_checkout;
    this.checkoutSuccessLabel = terminologyLanguageAPI.checkout_title;
    this.checkoutPlaceholder = terminologyLanguageAPI.checkout_placeholder;
    this.noTouchTitle = terminologyLanguageAPI.no_touch_title;
    this.noTouchQrCode = terminologyLanguageAPI.no_touch_qr_code;
    this.noTouchBarcodeScan = terminologyLanguageAPI.no_touch_barcode_scan;
    this.languageName = terminologyLanguageAPI.language_name;
    this.default = terminologyLanguageAPI.default;
    this.frontSideDriverLicense =
      terminologyLanguageAPI.front_driver_license ?? 'Take photo of front side of driver license';
    this.backSideDriverLicense =
      terminologyLanguageAPI.back_driver_license ?? 'Take photo of back side of driver license';
    this.frontSideDriverLicenseHeader = terminologyLanguageAPI.front_driver_license ?? 'front side';
    this.backSideDriverLicenseHeader = terminologyLanguageAPI.back_driver_license ?? 'back side';
    this.packageBlockTitle = terminologyLanguageAPI.package_block_title ?? 'Capture package photo';
  }
}
