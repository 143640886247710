import OutCheckInRecordModel from './OutCheckInRecordModel';

export default class PostCheckInRecordBodyModel {
  checkInRecord: OutCheckInRecordModel;
  surrogateId?: string;

  constructor(outCheckInRecord: OutCheckInRecordModel) {
    this.checkInRecord = outCheckInRecord;
  }
}
