export const deviceHashModel: {model: string, name: string}[]= [
  {
    "model": "iPad1,1",
    "name": "iPad"
  },
  {
    "model": "iPad1,1",
    "name": "iPad"
  },
  {
    "model": "iPad2,1",
    "name": "iPad 2"
  },
  {
    "model": "iPad2,2",
    "name": "iPad 2"
  },
  {
    "model": "iPad2,3",
    "name": "iPad 2"
  },
  {
    "model": "iPad2,4",
    "name": "iPad 2"
  },
  {
    "model": "iPad3,1",
    "name": "iPad (3rd generation)"
  },
  {
    "model": "iPad3,2",
    "name": "iPad (3rd generation)"
  },
  {
    "model": "iPad3,3",
    "name": "iPad (3rd generation)"
  },
  {
    "model": "iPad3,4",
    "name": "iPad (4th generation)"
  },
  {
    "model": "iPad3,5",
    "name": "iPad (4th generation)"
  },
  {
    "model": "iPad3,6",
    "name": "iPad (4th generation)"
  },
  {
    "model": "iPad6,11",
    "name": "iPad (5th generation)"
  },
  {
    "model": "iPad6,12",
    "name": "iPad (5th generation)"
  },
  {
    "model": "iPad7,5",
    "name": "iPad (6th generation)"
  },
  {
    "model": "iPad7,6",
    "name": "iPad (6th generation)"
  },
  {
    "model": "iPad7,11",
    "name": "iPad (7th generation)"
  },
  {
    "model": "iPad7,12",
    "name": "iPad (7th generation)"
  },
  {
    "model": "iPad11,6",
    "name": "iPad (8th generation)"
  },
  {
    "model": "iPad11,7",
    "name": "iPad (8th generation)"
  },
  {
    "model": "iPad12,1",
    "name": "iPad (9th generation)"
  },
  {
    "model": "iPad12,2",
    "name": "iPad (9th generation)"
  },
  {
    "model": "iPad4,1",
    "name": "iPad Air"
  },
  {
    "model": "iPad4,2",
    "name": "iPad Air"
  },
  {
    "model": "iPad4,3",
    "name": "iPad Air"
  },
  {
    "model": "iPad5,3",
    "name": "iPad Air 2"
  },
  {
    "model": "iPad5,4",
    "name": "iPad Air 2"
  },
  {
    "model": "iPad11,3",
    "name": "iPad Air (3rd generation)"
  },
  {
    "model": "iPad11,4",
    "name": "iPad Air (3rd generation)"
  },
  {
    "model": "iPad13,1",
    "name": "iPad Air (4th generation)"
  },
  {
    "model": "iPad13,2",
    "name": "iPad Air (4th generation)"
  },
  {
    "model": "iPad13,16",
    "name": "iPad Air (5th generation)"
  },
  {
    "model": "iPad13,17",
    "name": "iPad Air (5th generation)"
  },
  {
    "model": "iPad6,7",
    "name": "iPad Pro (12.9-inch)"
  },
  {
    "model": "iPad6,8",
    "name": "iPad Pro (12.9-inch)"
  },
  {
    "model": "iPad6,3",
    "name": "iPad Pro (9.7-inch)"
  },
  {
    "model": "iPad6,4",
    "name": "iPad Pro (9.7-inch)"
  },
  {
    "model": "iPad7,1",
    "name": "iPad Pro (12.9-inch, 2nd generation)"
  },
  {
    "model": "iPad7,2",
    "name": "iPad Pro (12.9-inch, 2nd generation)"
  },
  {
    "model": "iPad7,3",
    "name": "iPad Pro (10.5-inch)"
  },
  {
    "model": "iPad7,4",
    "name": "iPad Pro (10.5-inch)"
  },
  {
    "model": "iPad8,1",
    "name": "iPad Pro (11-inch)"
  },
  {
    "model": "iPad8,2",
    "name": "iPad Pro (11-inch)"
  },
  {
    "model": "iPad8,3",
    "name": "iPad Pro (11-inch)"
  },
  {
    "model": "iPad8,4",
    "name": "iPad Pro (11-inch)"
  },
  {
    "model": "iPad8,3",
    "name": "iPad Pro (11-inch)"
  },
  {
    "model": "iPad8,4",
    "name": "iPad Pro (11-inch)"
  },
  {
    "model": "iPad8,3",
    "name": "iPad Pro (11-inch)"
  },
  {
    "model": "iPad8,4",
    "name": "iPad Pro (11-inch)"
  },
  {
    "model": "iPad8,5",
    "name": "iPad Pro (12.9-inch) (3rd generation)"
  },
  {
    "model": "iPad8,6",
    "name": "iPad Pro (12.9-inch) (3rd generation)"
  },
  {
    "model": "iPad8,7",
    "name": "iPad Pro (12.9-inch) (3rd generation)"
  },
  {
    "model": "iPad8,8",
    "name": "iPad Pro (12.9-inch) (3rd generation)"
  },
  {
    "model": "iPad8,7",
    "name": "iPad Pro (12.9-inch) (3rd generation)"
  },
  {
    "model": "iPad8,8",
    "name": "iPad Pro (12.9-inch) (3rd generation)"
  },
  {
    "model": "iPad8,7",
    "name": "iPad Pro (12.9-inch) (3rd generation)"
  },
  {
    "model": "iPad8,8",
    "name": "iPad Pro (12.9-inch) (3rd generation)"
  },
  {
    "model": "iPad8,9",
    "name": "iPad Pro (11-inch) (2nd generation)"
  },
  {
    "model": "iPad8,10",
    "name": "iPad Pro (11-inch) (2nd generation)"
  },
  {
    "model": "iPad8,11",
    "name": "iPad Pro (12.9-inch) (4th generation)"
  },
  {
    "model": "iPad8,12",
    "name": "iPad Pro (12.9-inch) (4th generation)"
  },
  {
    "model": "iPad13,4",
    "name": "iPad Pro (11-inch) (3rd generation)"
  },
  {
    "model": "iPad13,5",
    "name": "iPad Pro (11-inch) (3rd generation)"
  },
  {
    "model": "iPad13,6",
    "name": "iPad Pro (11-inch) (3rd generation)"
  },
  {
    "model": "iPad13,7",
    "name": "iPad Pro (11-inch) (3rd generation)"
  },
  {
    "model": "iPad13,8",
    "name": "iPad Pro (12.9-inch) (5th generation)"
  },
  {
    "model": "iPad13,9",
    "name": "iPad Pro (12.9-inch) (5th generation)"
  },
  {
    "model": "iPad13,10",
    "name": "iPad Pro (12.9-inch) (5th generation)"
  },
  {
    "model": "iPad13,11",
    "name": "iPad Pro (12.9-inch) (5th generation)"
  },
  {
    "model": "iPad2,5",
    "name": "iPad mini"
  },
  {
    "model": "iPad2,6",
    "name": "iPad mini"
  },
  {
    "model": "iPad2,7",
    "name": "iPad mini"
  },
  {
    "model": "iPad4,4",
    "name": "iPad mini 2"
  },
  {
    "model": "iPad4,5",
    "name": "iPad mini 2"
  },
  {
    "model": "iPad4,6",
    "name": "iPad mini 2"
  },
  {
    "model": "iPad4,7",
    "name": "iPad mini 3"
  },
  {
    "model": "iPad4,8",
    "name": "iPad mini 3"
  },
  {
    "model": "iPad4,9",
    "name": "iPad mini 3"
  },
  {
    "model": "iPad5,1",
    "name": "iPad mini 4"
  },
  {
    "model": "iPad5,2",
    "name": "iPad mini 4"
  },
  {
    "model": "iPad11,1",
    "name": "iPad mini (5th generation)"
  },
  {
    "model": "iPad11,2",
    "name": "iPad mini (5th generation)"
  },
  {
    "model": "iPad14,1",
    "name": "iPad mini (6th generation)"
  },
  {
    "model": "iPad14,2",
    "name": "iPad mini (6th generation)"
  },
]