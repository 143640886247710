import { IonPage, IonImg, IonButton, IonText, CreateAnimation } from '@ionic/react';
import React from 'react';
import { observer } from 'mobx-react';
import { Device } from '@capacitor/device';
import QRCode from 'qrcode.react';

import '../theme/components/inputs.css';
import { Etc, ThemeType, WorkflowType } from '../commons/Constants';
import '../theme/components/welcome.css';
import { getCheckInURL } from '../utils/BaseUrls';
import Utils from '../utils/Utils';
import NativeBarcodeScanner from '../components/NativeBarcodeScanner';
import Banner from '../components/Banner';
import DriverLicenseDataModel from '../models/DriverLicenseDataModel';
import AppStore from '../mobx/AppStore';
import HiddenMenu from '../components/HiddenMenu';
import HiddenMenuHelper from '../utils/HiddenMenuHelper';
import BannerFull from '../components/BannerFull';
import DesignHelper from './steps/DesignHelper';
import LanguageMenu from '../components/LanguageMenu';

interface Props {
  appStore: AppStore;
}
type State = {
  qrCode: string;
  shouldShowHiddenMenu: boolean;
  shouldShowLanguageMenu: boolean;
};

class Welcome extends React.Component<Props, State> {
  private barcodeScanner = React.createRef<NativeBarcodeScanner>();

  private thermalTimeoutMillis = 20000;
  private thermalTimeout: NodeJS.Timeout | null = null;

  private animationRef: CreateAnimation | null = React.createRef<CreateAnimation>().current;
  private animationFinished: boolean = false;

  private hiddenMenuHelper = new HiddenMenuHelper();

  constructor(props: Props) {
    super(props);
    // this.simulateQRScanning()
    this.animationRef?.animation.onFinish(async (callBack: any) => {
      this.animationFinished = true;
    });
    this.state = { qrCode: '', shouldShowHiddenMenu: false, shouldShowLanguageMenu: false };
  }

  private simulateQRScanning = () => {
    setTimeout(() => {
      this.onBarcodeScannerSuccess({ barcodeFormat: 'QR_CODE', text: 'TidVXAI2CzoyOEpxpUXSRmBrPcnQU8Bns4dnlj7JOEM=' });
    }, 3000);
  };

  componentDidMount() {
    const { workflowStore, locationStore } = this.props.appStore;
    if (locationStore.location!.isThermalCheckInEnabled) {
      this.thermalTimeout = setTimeout(() => {
        // goes back to tauri app
        workflowStore.reset();
      }, this.thermalTimeoutMillis);
    }
    this.getQrCode();
  }

  componentWillUnmount() {
    if (this.thermalTimeout !== null) {
      clearTimeout(this.thermalTimeout);
    }
  }

  getBarcodeScannerOptions = () => {
    const { locationStore } = this.props.appStore;
    const { location } = locationStore;

    let possibleFormats = [];
    if (location?.prereg.enablePrereg) {
      possibleFormats.push('QR_CODE');
    }

    if (location?.hasWorkflow(WorkflowType.EMPLOYEE_CHECK_IN)) {
      possibleFormats = possibleFormats.concat([
        'EAN_8',
        'EAN_13',
        'CODE_39',
        'CODE_93',
        'CODE_128',
        'UPC_A',
        'UPC_E',
        'CODABAR',
        'PDF_417',
        'ITF',
        'RSS14',
        'MSI',
        'DATA_MATRIX',
        'AZTEC',
        'RSS_EXPANDED',
      ]);
    }

    return {
      possibleFormats: possibleFormats,
      isFrontCamera: true,
      maskPercent: 0,
      aspectRatioEqual: true,
    };
  };

  onBarcodeScannerSuccess = (res: any) => {
    const scannedText = res.text as string;
    // assumes that < 20 or not '=' at the end -> it's not pre-reg code
    const { appStore } = this.props;
    const { workflowStore } = appStore;
    let successScanned = () => {
      appStore.shouldShowWelcomePage = false;
    };
    let errorScanned = () => {
      Utils.showErrorToast('Failed to process ' + (res.barcodeFormat === 'QR_CODE' ? 'QR' : 'barcode') + ' Code');
      this.barcodeScanner.current?.addScannerListener();
    };

    const finishPreReg = (text: any) => {
      workflowStore
        .finishPrereg(text)
        .then(() => {
          successScanned();
        })
        .catch(() => {
          errorScanned();
        });
    };

    const dl_info = res.barcodeFormat === 'PDF_417' ? DriverLicenseDataModel.parseText(res.text) : null;
    if (dl_info?.dl_id) {
      // it's code using if DL scanned
      let dl_info_text = { dl_id: dl_info.dl_id };
      workflowStore
        .checkInEmployee(dl_info_text)
        .then(() => {
          successScanned();
        })
        .catch(() => {
          finishPreReg(dl_info_text);
        });
    } else {
      if (scannedText.length > 20) {
        //checking for QR codes
        finishPreReg(scannedText);
      } else {
        //checking barcode for employee internal code (it checks surrogateID value)
        workflowStore
          .checkInEmployee(scannedText)
          .then(() => {
            successScanned();
          })
          .catch(() => {
            errorScanned();
          });
      }
    }
  };

  _nextPage() {
    const { appStore } = this.props;
    appStore.shouldShowWelcomePage = false;
  }

  onTap = () => {
    if (this.animationFinished) {
      const duration = 300;
      this.animationRef?.setupAnimation({ delay: 0, play: true, duration: duration, direction: 'reverse' });
      setTimeout(() => {
        this._nextPage();
      }, duration / 2);
    } else {
      this._nextPage();
    }
  };

  onBarcodeScannerError = (err: any) => {
    Utils.showErrorToast('An error has occurred');
    console.error(Etc.LOG_PREFIX + JSON.stringify(err));
  };

  getQrCode = async () => {
    const { appStore } = this.props;
    const { locationStore, workflowStore } = appStore;
    const { location } = locationStore;
    const { thermalCheckInRecord } = workflowStore;
    const checkInURL = await getCheckInURL();

    Device.getInfo().then((info: any) => {
      const deviceId = `device_id=${info.uuid}`;
      let qrCode;
      if (location!.isThermalCheckInEnabled) {
        qrCode =
          checkInURL +
          '/' +
          location!.noTouchID +
          '/?check_in_record_id=' +
          thermalCheckInRecord.id +
          `&${deviceId}`;
      } else {
        qrCode = checkInURL + '/' + location!.noTouchID + `?${deviceId}`;
      }
      this.setState({ qrCode: qrCode });
    });
  };

  private showHiddenMenu() {
    this.hiddenMenuHelper.onClick(() => this.setState({ shouldShowHiddenMenu: true }));
  }

  private onLanguageClick = () => {
    this.setState({ shouldShowLanguageMenu: true });
  };

  render() {
    const { appStore } = this.props;
    const { locationStore } = appStore;
    const { location } = locationStore;

    const shouldShowCamera =
      location?.hasWorkflow(WorkflowType.EMPLOYEE_CHECK_IN) ||
      location?.prereg.enablePrereg ||
      location?.prereg.repeatVisitor;
    const theme = locationStore.theme;
    const themeMain = locationStore.themeMain;
    const themeButton = locationStore.themeButton;
    const tabletTerms = locationStore?.location?.terminologySetting.tabletTerminologies || {};

    let noTouchText = (classNameValue: string) => {
      return (
        <>
          {themeButton === ThemeType.Old && (
            <div className={classNameValue}>
              <IonText className="no-touch-text">
                {Utils.checkValueOfTabletTerminology(locationStore.tabletTerminologies.noTouchTitle)
                  ? 'No Touch Check In'
                  : locationStore.tabletTerminologies.noTouchTitle}
              </IonText>
            </div>
          )}
        </>
      );
    };

    let descriptionText = (text: string | null) => {
      return (
        <div className={addThemeType(addNewThemeStyle('scan-this'), themeButton)}>
          <IonText>{text}</IonText>
        </div>
      );
    };

    let addNewThemeStyle = (text: string) => text + (themeButton != ThemeType.Old ? ' newtheme' : '');
    let addThemeType = (text: string, theme: string | null) => {
      let themeString = '';
      switch (theme) {
        case ThemeType.Dark:
          themeString = ' dark';
          break;
        case ThemeType.Modern:
          themeString = ' modern';
          break;
        case ThemeType.Whimsical:
          themeString = ' whimsical';
          break;
        default:
          break;
      }
      return text + themeString;
    };

    //{Utils.isNative() && <div className="hidden-menu-button" onClick={(event) => { event.stopPropagation(); this.showHiddenMenu()}}/> }
    // {this.state.shouldShowHiddenMenu ?
    // 	<HiddenMenu {...this.props} appVersion={this.props.appStore.appVersion} close={() => this.setState({ shouldShowHiddenMenu: false })} stopPropagation={true} />
    // 	:

    return (
      <IonPage className="welcome" onClick={this.onTap}>
        {this.state.shouldShowLanguageMenu ? (
          <LanguageMenu {...this.props} close={() => this.setState({ shouldShowLanguageMenu: false }, () => {})} />
        ) : this.state.shouldShowHiddenMenu ? (
          <HiddenMenu
            {...this.props}
            appVersion={this.props.appStore.appVersion}
            close={() => this.setState({ shouldShowHiddenMenu: false })}
            stopPropagation={true}
          />
        ) : (
          <>
            {theme?.welcomeBackground && themeButton != ThemeType.Old ? (
              <BannerFull appStore={this.props.appStore} src={theme.welcomeBackground} />
            ) : null}
            {theme?.welcomeSplash && themeButton === ThemeType.Old ? (
              <Banner appStore={this.props.appStore} src={locationStore.location!.theme.welcomeSplash || ''} />
            ) : null}
            {theme?.welcomeBackgroundVideo && (
              <CreateAnimation
                ref={(ref) => (this.animationRef = ref!)}
                play={true}
                delay={3000}
                duration={500}
                iterations={1}
                fromTo={[{ property: 'opacity', fromValue: '0', toValue: '1' }]}
              >
                <video
                  loop={true}
                  playsInline={true}
                  preload={'metadata'}
                  autoPlay={true}
                  muted={true}
                  src={theme.welcomeBackgroundVideo}
                />
              </CreateAnimation>
            )}
            {locationStore.location!.theme.welcomeBackgroundVideo === null && themeMain === ThemeType.Old && (
              <IonImg className="image-welcome" />
            )}
            <div className="container">
              {Utils.isNative() && (
                <div
                  className="hidden-menu-button"
                  onClick={(event) => {
                    event.stopPropagation();
                    this.showHiddenMenu();
                  }}
                />
              )}
              {Object.keys(tabletTerms).length > 1 ? (
                DesignHelper.renderLanguageButton(
                  themeButton,
                  () => this.onLanguageClick(),
                  locationStore.defaultLanguage,
                )
              ) : (
                <></>
              )}
              <div className="top-quarter">
                <div className={addNewThemeStyle('welcome-img-container')}>
                  <img
                    className={locationStore.location?.logo ? 'brand-logo' : 'brand-logo opacity-zero'}
                    alt=""
                    src={locationStore.location?.logo || ''}
                  ></img>
                </div>
                {/* <img className="welcome-image" alt="" src={locationStore.location!.logo !== null ?
							locationStore.location!.logo : ""}></img> */}
                {themeButton === ThemeType.Old && (
                  <IonText className="title">{locationStore.tabletTerminologies.homeScreen} </IonText>
                )}
                {/* <IonText className="title">{locationStore.tabletTerminologies.homeScreen} </IonText> */}
              </div>
              <div className={addNewThemeStyle('mid-quarter')}>
                <IonButton
                  className={
                    (themeMain != ThemeType.Old ? `welcome-btn-${themeMain} ` : 'welcome-btn ') +
                    location!.theme.welcomeAnimationOption
                  }
                >
                  {locationStore.tabletTerminologies.homeScreen_btn}
                </IonButton>
              </div>
              {DesignHelper.renderTopWaveDesign(themeButton, theme?.welcomeAccentColor)}
              <div className={addThemeType(addNewThemeStyle('footer-w'), themeButton)}>
                {location!.theme.isNoTouchEnabled && (
                  <div className="no-touch-footer">
                    {noTouchText('no-touch-cont')}
                    <div className="bottom-footer">
                      <div className={!shouldShowCamera ? 'scan-cont single-qr' : 'scan-cont'}>
                        <div className={!shouldShowCamera ? 'qr-code single-qr' : 'qr-code'}>
                          <div className="qr-code-org">
                            {this.state.qrCode && <QRCode value={this.state.qrCode} size={200} />}
                          </div>
                          {descriptionText(
                            Utils.checkValueOfTabletTerminology(locationStore.tabletTerminologies.noTouchQrCode)
                              ? 'Scan Code To Start Check In'
                              : locationStore.tabletTerminologies.noTouchQrCode,
                          )}
                        </div>
                      </div>
                      {shouldShowCamera && location!.theme.isNoTouchEnabled ? (
                        <>
                          <div className={addNewThemeStyle('mid-bar')}>
                            {themeButton === ThemeType.Old && (
                              <>
                                <div className="vertical-bar"></div>
                                <div className="or">or</div>
                                <div className="vertical-bar"></div>
                              </>
                            )}
                          </div>
                          {noTouchText('no-touch-cont-landscape')}
                          <div className="prev-cont">
                            <div className="cam-prev-sec">
                              {Utils.isMobileApp() ? (
                                <NativeBarcodeScanner
                                  ref={this.barcodeScanner}
                                  className="camera-prev"
                                  shouldAutoStart
                                  options={this.getBarcodeScannerOptions()}
                                  onSuccess={(res) => this.onBarcodeScannerSuccess(res)}
                                  onError={(err) => this.onBarcodeScannerError(err)}
                                />
                              ) : (
                                <div className="camera-prev" />
                              )}
                              {}
                              {descriptionText(
                                Utils.checkValueOfTabletTerminology(
                                  locationStore.tabletTerminologies.noTouchBarcodeScan,
                                )
                                  ? 'Hold Code To Complete Check In'
                                  : locationStore.tabletTerminologies.noTouchBarcodeScan,
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                )}

                {shouldShowCamera && !location!.theme.isNoTouchEnabled && (
                  <>
                    <div className="cam-prev-sec only-front-cam">
                      {Utils.isMobileApp() ? (
                        <NativeBarcodeScanner
                          ref={this.barcodeScanner}
                          className="camera-prev"
                          shouldAutoStart
                          options={this.getBarcodeScannerOptions()}
                          onSuccess={(res) => this.onBarcodeScannerSuccess(res)}
                          onError={(err) => this.onBarcodeScannerError(err)}
                        />
                      ) : (
                        <div className="camera-prev" />
                      )}
                      {descriptionText(
                        Utils.checkValueOfTabletTerminology(locationStore.tabletTerminologies.noTouchBarcodeScan)
                          ? 'Hold Code To Complete Check In'
                          : locationStore.tabletTerminologies.noTouchBarcodeScan,
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </IonPage>
    );
  }
}

export default observer(Welcome);
