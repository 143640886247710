import { IonDatetime, IonDatetimeButton, IonItem, IonLabel, IonModal } from '@ionic/react';
import React, { useCallback } from 'react';

import './TimePickerField.css';
import './TimePicker.css';

interface Props {
  name?: string;
  timeLabel: string;
  onChange?: (value: string) => void;
  isRequired?: boolean;
  ref?: any;
}

const TimePicker = React.forwardRef((props: Props, ref: any) => {
  const item = React.createRef<HTMLIonItemElement>();
  const dateTime = React.createRef<HTMLIonDatetimeElement>();

  const { timeLabel, isRequired, onChange } = props;

  const validate = useCallback(() => {
    if (isRequired) {
      const isValid = dateTime.current!.value != null;
      if (!isValid) {
        item.current?.classList.add('warning');
      }
      return isValid;
    } else {
      return true;
    }
  }, []);

  const onValueChange = useCallback((value: CustomEvent) => {
    const time = new Date(value.detail.value);
    const timeString = time.getHours() + ':' + time.getMinutes();
    onChange?.(timeString);
    if (validate()) {
      item.current?.classList.remove('warning');
    }
  }, []);

  //TODO: set format displayFormat='HH:mm'
  return (
    <>
      <IonItem ref={ref} className="timepicker" id="open-modal">
        <IonLabel className="time-label">{timeLabel}</IonLabel>
        <IonDatetimeButton className="time-btn" datetime="datetime"></IonDatetimeButton>
      </IonItem>

      <IonModal className="picker-modal" keepContentsMounted={true} color={'dark'} trigger="open-modal">
        <IonDatetime
          ref={dateTime}
          presentation="time"
          color="primary"
          size="cover"
          id="datetime"
          showDefaultButtons={true}
          onIonChange={onValueChange}
        ></IonDatetime>
      </IonModal>
    </>
  );
});

TimePicker.displayName = 'TimePicker';

export default TimePicker;
