import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import ScrollContainer from 'react-indiana-drag-scroll';

import Step, { StepProps } from '../Step';
import Banner from '../../../components/Banner';
import Inputs from '../../../components/Inputs';
import SearchHostsItem from '../../../components/SearchHostItem';
import Utils from '../../../utils/Utils';
import Loading from '../../../components/Loading';
import { PathName } from '../../../commons/Constants';
import { HostsStoreFetchErrorCode } from '../../../mobx/HostsStore';
import DesignHelper from '../DesignHelper';
import './searchHost.css';
import './style.scss';

type State = {
  isLoadingInit: boolean;
  isLoadingCurrent: boolean;
  shouldRedirectToSessionExpired: boolean;
  shouldRedirectToUnknownError: boolean;
  typingTimeout: any;
};

class SearchHost extends Step<StepProps, State> {
  state: State = {
    isLoadingInit: true,
    isLoadingCurrent: true,
    shouldRedirectToSessionExpired: false,
    shouldRedirectToUnknownError: false,
    typingTimeout: null,
  };

  constructor(props: StepProps) {
    super(props);
    const { hostsStore } = this.props.appStore;
    hostsStore.clearHosts();
  }

  componentDidMount(): void {
    this._loadHosts('', 100);
  }

  componentWillUnmount() {
    this.props.appStore.hostsStore.setSearchStr('');
  }

  private _loadHosts = (searchString: string = '', timeout: number = 500) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({ isLoadingCurrent: true });
    this.setState({
      typingTimeout: setTimeout(() => {
        const { hostsStore, workflowStore } = this.props.appStore;
        hostsStore.setSearchStr(searchString);
        if (workflowStore?.workflow?.id) {
          hostsStore
            .fetch(workflowStore.workflow.id, searchString)
            .catch((err) => {
              if (Utils.isNative()) {
                Utils.showErrorToast('An error has occurred');
                this.setState({ isLoadingInit: false });
              } else {
                switch (err) {
                  case HostsStoreFetchErrorCode.UNAUTHORIZED:
                    this.setState({ shouldRedirectToSessionExpired: true });
                    break;
                  case HostsStoreFetchErrorCode.UNKNOWN:
                  default: {
                    this.setState({ shouldRedirectToUnknownError: true });
                  }
                }
              }
            })
            .finally(() => {
              this.setState({ isLoadingInit: false, isLoadingCurrent: false });
            });
        }
      }, timeout),
    });
  };

  render() {
    if (!Utils.isNative()) {
      if (this.state.shouldRedirectToSessionExpired) {
        return <Redirect to={PathName.SESSION_EXPIRED} />;
      } else if (this.state.shouldRedirectToUnknownError) {
        return <Redirect to={PathName.UNKNOWN_ERROR} />;
      }
    }

    const { locationStore, hostsStore, workflowStore } = this.props.appStore;
    const themeMain = locationStore.themeMain;
    const themeButton = locationStore.themeButton;
    return (
      <IonPage className="search-host">
        <IonContent scrollEvents={false}>
          <Banner {...this.props} />
          {DesignHelper.renderThemeDesignTop(themeMain, locationStore.location?.logo || null)}
          {Utils.isNative() && DesignHelper.renderHomeButton(themeButton, () => workflowStore.reset(true))}
          {DesignHelper.renderThemeLabelTitle(themeMain, locationStore.tabletTerminologies.employeeSearch)}

          <Inputs
            forPlaceholder={locationStore.tabletTerminologies.search || ''}
            forIcon={this.state.isLoadingCurrent && !this.state.isLoadingInit ? 'spinner' : 'search'}
            type="search"
            onChange={(e) => this._loadHosts((e.target as HTMLInputElement).value!)}
          />

          <ScrollContainer className="employee-list-container">
            {hostsStore.filteredHosts.map((host, index) => {
              return <SearchHostsItem key={index} {...this.props} host={host} />;
            })}
          </ScrollContainer>
        </IonContent>
        {this.state.isLoadingInit && <Loading />}
      </IonPage>
    );
  }
}

export default observer(SearchHost);
