import { IonItem, IonLabel, IonGrid, IonRow } from '@ionic/react';
import React from 'react';
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import '../theme/components/slider.css';
import 'rc-slider/assets/index.css';
import Handle from 'rc-slider/lib/Handles/Handle';

interface Props {
  name?: string;
  sliderLabel: string;
  min: number;
  max: number;
  step: number;
  onChange?: (value: number | number[]) => void;
  isRequired?: boolean;
}

class SliderGreetly extends React.Component<Props> {
  item = React.createRef<HTMLIonItemElement>();
  rating = React.createRef<HTMLIonToggleElement>();
  marks: any;

  constructor(props: Props) {
    super(props);
    const min = this.props.min;
    const max = this.props.max;
    this.marks = new Object();
    this.marks[min] = { style: { fontSize: '24px' }, label: min.toString() };
    this.marks[max] = { style: { fontSize: '24px' }, label: max.toString() };
  }

  onChange = (value: number | number[]) => {
    // if (this.props.isRequired) {
    // 	const isChecked = this.toggle.current!.checked;
    // 	if (isChecked) {
    // 		this.item.current!.classList.remove("warning");
    // 	}
    // }

    this.props.onChange?.(value);
  };

  validate = () => {
    return true;
    // if (this.props.isRequired) {
    // 	const isChecked = this.toggle.current!.checked;
    // 	if (!isChecked) {
    // 		this.item.current!.classList.add("warning");
    // 	}
    // 	return isChecked;
    // } else {
    // 	return true;
    // }
  };

  handle = (props: any) => {
    const { value, dragging, index, style, ...restProps } = props;
    return (
      <Tooltip
        prefixCls="rc-slider-tooltip"
        overlay={`${value}`}
        visible={dragging}
        placement="top"
        key={index}
        overlayInnerStyle={{ paddingBottom: '25px', fontSize: '24px', ...style }}
        overlayStyle={{ width: '40px', ...style }}
      >
        <Handle value={value} {...restProps} />
      </Tooltip>
    );
  };

  render() {
    const min = this.props.min;
    const max = this.props.max;
    return (
      <IonItem ref={this.item} lines="none" className="slider">
        <IonGrid>
          <IonRow>
            <IonLabel className="name">
              {this.props.sliderLabel}
              {this.props.isRequired ? '*' : ''}
            </IonLabel>
          </IonRow>
          <IonRow className="slider_row">
            <Slider
              className="rc_slider"
              marks={this.marks}
              // handle={this.handle}
              onChange={this.onChange}
              dots={true}
              min={min}
              max={max}
              step={this.props.step}
            />
          </IonRow>
        </IonGrid>
      </IonItem>
    );
  }
}

export default SliderGreetly;
