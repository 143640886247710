import { IonItem, IonLabel, IonRadioGroup, IonListHeader, IonRadio } from '@ionic/react';
import React from 'react';
import '../theme/components/radioselect.css';
import { toJS } from 'mobx';
import i18next, { t } from 'i18next';

interface Props {
  name?: string;
  radioTitle: string;
  radioOptions: Array<any>;
  value?: string;
  onChange?: (value: string) => void;
  isRequired?: boolean;
  i18nBase?: string;
}

type State = {
  radioValue: string;
};

class RadioSelect extends React.Component<Props, State> {
  container = React.createRef<HTMLDivElement>();
  radioGroup = React.createRef<HTMLIonRadioGroupElement>();

  state: State = {
    radioValue: this.props.value || '',
  };

  setSelected = (e: any) => {
    this.setState({ radioValue: e.detail.value });

    if (this.props.isRequired) {
      this.container.current!.classList.remove('warning');
    }

    this.props.onChange?.(e.detail.value);
  };

  validate = () => {
    if (this.props.isRequired) {
      const isSomethingSelected =
        this.radioGroup.current!.value !== undefined &&
        this.radioGroup.current!.value !== null &&
        this.radioGroup.current!.value !== '';
      if (!isSomethingSelected) {
        this.container.current!.classList.add('warning');
      }
      return isSomethingSelected;
    } else {
      return true;
    }
  };

  render() {
    const i18QuestionKey = `${this.props.i18nBase}.question`;
    const radioTitle = i18next.exists(i18QuestionKey) ? t(i18QuestionKey) : this.props.radioTitle;
    return (
      <div ref={this.container} className="radioselect">
        <IonRadioGroup
          ref={this.radioGroup}
          name={this.props.name}
          value={this.state.radioValue}
          onIonChange={this.setSelected}
        >
          <IonListHeader>
            <IonLabel className="title-radio">
              {radioTitle}
              {this.props.isRequired ? '*' : ''}
            </IonLabel>
          </IonListHeader>
          {this.props.radioOptions.map((option, index) => {
            const i18Key = `${this.props.i18nBase}.options.${index}`;
            const optionValue = i18next.exists(i18Key) ? t(i18Key) : option.label ?? option ?? '';
            return (
              <IonItem lines="none" key={index}>
                <IonLabel>{optionValue}</IonLabel>
                <IonRadio slot="end" name={optionValue} value={optionValue} />
              </IonItem>
            );
          })}
          <input type="hidden" className="aux-input" name={this.props.name} value={this.state.radioValue} />
        </IonRadioGroup>
      </div>
    );
  }
}

export default RadioSelect;
