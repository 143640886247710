import { IonLabel, IonButton } from '@ionic/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import i18next, { t } from 'i18next';

import HostModel from '../models/HostModel';
import { observer } from 'mobx-react';
import AppStore from '../mobx/AppStore';
import '../theme/components/userCard.css';

interface Props {
  appStore: AppStore;
  host: HostModel;
}

class SearchHostsItem extends React.Component<Props> {
  onClick = () => {
    const { appStore, host } = this.props;
    const { workflowStore } = appStore;
    workflowStore.setSelectedHost(host);
    workflowStore.workflow?.useAlternativeWorkflow == true
      ? workflowStore.goToNextNode(host)
      : workflowStore.goToNextStep();
  };

  initials() {
    const hostFullName = this._nameOrCompanyOrEmail();
    const initial = hostFullName
      .split(' ')
      .map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
      .join('');
    return initial;
  }

  _nameOrCompanyOrEmail = () =>
    this.props.host.fullName ?? this.props.host.company ?? this.props.host.email ?? 'undefined';

  render() {
    const alternativeLabel = '>>';
    const nextBtnI18n = 'employee_next';
    const displayNextBtn = i18next.exists(nextBtnI18n) ? t(nextBtnI18n) : alternativeLabel;

    return (
      <IonButton
        className={'scroll-to-' + this._nameOrCompanyOrEmail().substring(0, 1).toUpperCase() + ' user-card'}
        onClick={() => this.onClick()}
      >
        <div className="user-cont">
          {(this.props.host.profilePhoto == null ||
            this.props.host.profilePhoto === 'user_profile_photo_missing.jpg') && (
            <div className="user-img">
              <div className="initials">{this.initials()}</div>
            </div>
          )}
          {this.props.host.profilePhoto != null &&
            this.props.host.profilePhoto !== 'user_profile_photo_missing.jpg' && (
              <img className="user-img" src={this.props.host.profilePhoto} alt="placeforimg" />
            )}
          {/* @TODO why 'company' not host-title, need to check */}
          <div className="info-container">
            <IonLabel className="name">{this._nameOrCompanyOrEmail()}</IonLabel>
            {this.props.host.fullName && (
              <IonLabel className="position">
                {this.props.host.company && this.props.host.company !== '' ? (
                  this.props.host.company
                ) : (
                  <span>&nbsp;</span>
                )}
              </IonLabel>
            )}
          </div>
          {/* @TODO terminology-settings for notify user */}
          <IonLabel className="icon arrow-btn">
            <FontAwesomeIcon icon="chevron-right" />
          </IonLabel>
          <div className="deco-btn tablet-btn">
            {/* used to be angle-double-right */}
            <div>{displayNextBtn}</div>
          </div>
        </div>
      </IonButton>
    );
  }
}

export default observer(SearchHostsItem);
