import React from 'react';
import { IonLoading } from '@ionic/react';

interface Props {
  message?: string;
  cssClass?: string;
}

export function Loading(props: Props) {
  if (props.message) {
    return <IonLoading isOpen={true} message={props.message || ''} cssClass={props.cssClass} />;
  }

  return <IonLoading isOpen={true} cssClass={props.cssClass} />;
}

export default Loading;
