import { IonPage, IonHeader, IonToolbar, IonTitle } from '@ionic/react';
import React from 'react';

export class NoMatch extends React.Component {
  render() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>404 - Not Found</IonTitle>
          </IonToolbar>
        </IonHeader>
      </IonPage>
    );
  }
}

export default NoMatch;
