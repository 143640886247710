import React from 'react';
import NativeStore from '../mobx/NativeStore';
import { IonPage, IonImg, IonButton, IonText } from '@ionic/react';
import { Paths } from '../commons/Constants';
import '../theme/pages/grantPermissions.css';

interface Props {
  nativeStore: NativeStore;
}

class GrantPermissions extends React.Component<Props> {
  render() {
    const { nativeStore } = this.props;

    return (
      <IonPage className="grant-permissions">
        <div className="container">
          <IonImg className="img" src={Paths.IMAGE + '/greetly_logo.png'} />
          <IonText className="title">
            Greetly needs permission to access the device camera for basic functionality.
          </IonText>
          <IonButton className="submit" onClick={() => nativeStore.goToSettings()}>
            Go to settings
          </IonButton>
        </div>
      </IonPage>
    );
  }
}

export default GrantPermissions;
