export default class WorkflowStep {
  id: string;
  type: string;
  value: any | null;

  constructor(
    id: string = "<undefined, it's a error value>",
    type: string = "<undefined, it's a error value>",
    value: any | null
  ) {
    this.id = id;
    this.type = type;
    this.value = value;
  }
}
