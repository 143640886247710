import { Preferences as Storage } from '@capacitor/preferences';

import ENV from '../env';

export const getBaseUrl = async (): Promise<string> => {
    const isEU = await Storage.get({ key: 'is_eu' });

    return isEU?.value ? 'https://eu.greetly.com' : ENV?.baseURL;
}

export const getRubyServerUrl = async (): Promise<string> => {
    const isEU = await Storage.get({ key: 'is_eu' });

    return isEU?.value ? 'https://eu.greetly.com' : ENV?.rubyServerURL;
}

export const getNodeServerURL = async (): Promise<string> => {
    const isEU = await Storage.get({ key: 'is_eu' });

    return isEU?.value ? 'https://eu-visitor.greetly.com' : ENV?.nodeServerURL;
}

export const getCheckInURL = async (): Promise<string> => {
    const isEU = await Storage.get({ key: 'is_eu' });

    return isEU?.value ? 'https://eu.greetly.com/check_in' : ENV?.checkInURL;
}
