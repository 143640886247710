import React, { useEffect, useState } from 'react';
import { IonContent, IonPage, IonLabel, IonButton } from '@ionic/react';

import { StepProps } from './Step';
import '../../theme/pages/checkout.css';
import Inputs from '../../components/Inputs';
import { observer } from 'mobx-react';
import Loading from '../../components/Loading';
import Utils from '../../utils/Utils';
import Banner from '../../components/Banner';
import InCheckInRecordModel from '../../models/InCheckInRecordModel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DesignHelper from './DesignHelper';

const CheckOut = observer((props: StepProps) => {
  const [isLoading, setIsLoading] = useState(true);

  const { appStore } = props;
  const { checkInRecordsStore, locationStore, workflowStore } = appStore;

  useEffect(() => {
    checkInRecordsStore!.setSearchStr('');
    checkInRecordsStore!
      .fetch()
      .catch(() => {
        Utils.showErrorToast('An error has occurred');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [checkInRecordsStore]);

  const checkOut = (checkInRecord: InCheckInRecordModel) => {
    workflowStore.setCheckOutCheckInRecord(checkInRecord);
    workflowStore.goToNextStep();
  };

  const getButton = (checkInRecord: InCheckInRecordModel, i: number) => {
    let lastNameInitial: string;

    if (checkInRecord.visitorInfoFieldValues.last_name) {
      lastNameInitial = checkInRecord.visitorInfoFieldValues.last_name[0] + '.';
    } else {
      lastNameInitial = '';
    }

    return (
      <IonButton key={i} className="visitor-section" onClick={() => checkOut(checkInRecord)}>
        <div className="visitor-cont">
          <div className="info-container">
            <div className="row">
              <IonLabel className="name">{checkInRecord.visitorInfoFieldValues.first_name} </IonLabel>
              <IonLabel className="name">{lastNameInitial} </IonLabel>
            </div>
          </div>
          <IonLabel className="icon">
            <FontAwesomeIcon icon="chevron-right" />
          </IonLabel>
        </div>
      </IonButton>
    );
  };

  const checkoutPlaceholder = Utils.checkValueOfTabletTerminology(locationStore.tabletTerminologies.checkoutPlaceholder)
    ? 'Enter your first or last name to check out'
    : locationStore.tabletTerminologies.checkoutPlaceholder;
  const themeMain = locationStore.themeMain;
  const themeButton = locationStore.themeButton;

  return (
    <IonPage className="checkout">
      <IonContent>
        <Banner {...props} />
        {DesignHelper.renderThemeDesignTop(themeMain, locationStore.location?.logo || null)}
        {Utils.isNative() && DesignHelper.renderHomeButton(themeButton, () => workflowStore.reset(true))}
        {DesignHelper.renderThemeLabelTitle(themeMain, locationStore.tabletTerminologies.checkoutHeaderLabel)}
        <Inputs
          forPlaceholder={` ${checkoutPlaceholder}`}
          forIcon="search"
          type="search"
          onChange={(e) => checkInRecordsStore.setSearchStr((e.target as HTMLInputElement).value!)}
          value={checkInRecordsStore!.searchStr}
        />
        <div className="visitor-list-container">
          {checkInRecordsStore.filteredCheckInRecords.map((checkInRecord, i) => {
            return getButton(checkInRecord, i);
          })}
        </div>
      </IonContent>
      {isLoading && <Loading />}
    </IonPage>
  );
});

export default CheckOut;
