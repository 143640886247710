/* eslint-disable no-lone-blocks */
import { Observer, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { PathName } from '../commons/Constants';
import Loading from '../components/Loading';
import { LocationStoreFetchErrorCode } from '../mobx/LocationStore';
import WebStore, { WebStoreInitErrorCode } from '../mobx/WebStore';
import { FetchPreRegCheckInRecordError } from '../mobx/WorkflowStore';
import Welcome from './Welcome';
import Workflow from './Workflow';
import Workflows from './Workflows/Workflows';
import { useCallback } from 'react';

interface Props {
  webStore: WebStore;
  location?: any;
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const MainWeb = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showRetryPage, setShowRetryPage] = useState(false);
  const [showUnkowPage, setShowUnkowPage] = useState(false);

  const { webStore } = props;
  const { locationStore, workflowStore } = webStore;

  //const query = queryString.parse(location?.search);
  const query = useQuery();
  const sessionId = query.get('sessionId');

  const showPages = useCallback(() => {
    return !showRetryPage && !showUnkowPage;
  }, [showRetryPage, showUnkowPage]);

  useEffect(() => {
    if (sessionId) {
      webStore
        .fetchLocationId(sessionId)
        .then(() => {
          return locationStore.fetch(true);
        })
        .then(() => {
          return workflowStore.fetchServerData();
        })
        .catch((err: any) => {
          switch (err) {
            case LocationStoreFetchErrorCode.UNAUTHORIZED:
            case FetchPreRegCheckInRecordError.UNAUTHORIZED:
            case WebStoreInitErrorCode.UNAUTHORIZED: {
              setShowRetryPage(true);
              return;
            }
            case LocationStoreFetchErrorCode.UNKNOWN:
            case FetchPreRegCheckInRecordError.UNKNOWN:
            case WebStoreInitErrorCode.UNKNOWN:
            default: {
              setShowUnkowPage(true);
              return;
            }
          }
        })
        .finally(() => {
          webStore.shouldShowWelcomePage = webStore.previewMode;
          setIsLoading(false);
        });
    } else {
      setShowRetryPage(true);
      setIsLoading(false);
    }
  }, []);

  return (
    <Observer>
      {() => {
        return (
          <>
            {isLoading ? (
              <Loading />
            ) : (
              <>
                {showRetryPage && <Redirect to={PathName.RETRY} exact />}
                {showUnkowPage && <Redirect to={PathName.UNKNOWN_ERROR} />}
                {showPages() &&
                  (webStore.previewMode && webStore.shouldShowWelcomePage ? (
                    <Welcome {...props} appStore={webStore} />
                  ) : workflowStore.workflow !== null ? (
                    <Workflow {...props} appStore={webStore} />
                  ) : (
                    <Workflows {...props} appStore={webStore} />
                  ))}
              </>
            )}
          </>
        );
      }}
    </Observer>
  );
};

export default MainWeb;
