import HostAPIModel from './api/HostAPIModel';

export default class HostModel {
  id: number;
  company: string;
  firstName: string;
  lastName: string | null;
  email: string;
  profilePhoto: string;
  phone1: string;
  phone2: string;
  phone2Extension: string;
  slackHandle: string;
  notificationByEmail: boolean;
  notificationBySMS: boolean;
  notificationByVoice: boolean;
  notificationBySlack: boolean;
  integrationOverwrite: boolean;

  constructor(hostAPI: HostAPIModel) {
    this.id = hostAPI.id;
    this.company = hostAPI.company;
    this.firstName = hostAPI.first_name;
    this.lastName = hostAPI.last_name;
    this.email = hostAPI.email;
    this.profilePhoto = hostAPI.profile_photo;
    this.phone1 = hostAPI.phone_1;
    this.phone2 = hostAPI.phone_2;
    this.phone2Extension = hostAPI.phone_2_extension;
    this.slackHandle = hostAPI.slack_handle;
    this.notificationByEmail = hostAPI.notification_by_email;
    this.notificationBySMS = hostAPI.notification_by_sms;
    this.notificationByVoice = hostAPI.notification_by_voice;
    this.notificationBySlack = hostAPI.notification_by_slack;
    this.integrationOverwrite = hostAPI.integration_overwrite;
  }

  public get fullName() {
    return `${this.firstName !== null ? this.firstName + ' ' : ''}${this.lastName ?? ''}`;
  }
}
