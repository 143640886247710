import { computed, action, observable, makeObservable } from 'mobx';
import { Device, DeviceId, DeviceInfo } from '@capacitor/device';
import Axios, { AxiosResponse } from 'axios';
import { Capacitor } from '@capacitor/core';
import i18next from 'i18next';

import LocationAPIModel from '../models/api/LocationAPIModel';
import { Etc, WorkflowType } from '../commons/Constants';
import LocationModel from '../models/LocationModel';
import ThemeModel from '../models/ThemeModel';
import packageJson from '../../package.json';
import Utils from '../utils/Utils';
import AppStore from './AppStore';
import i18n from '../i18n';
import { deviceHashModel } from './deviceHash';
import { getBaseUrl } from '../utils/BaseUrls';

export class LocationStoreFetchErrorCode {
  static get UNAUTHORIZED() {
    return 'location_store_fetch_unauthorized';
  }
  static get UNKNOWN() {
    return 'location_store_fetch_unknown';
  }
}

export default class LocationStore {
  private _appStore!: AppStore;
  private _isLoading = true;
  private _error: any = null;
  private _location: LocationModel | null = null;
  private _language: string = 'English';
  private _defaultLanguage: string = '';

  constructor(appStore: AppStore) {
    makeObservable<LocationStore, '_isLoading' | '_error' | '_location' | '_language' | '_defaultLanguage'>(this, {
      _isLoading: observable,
      _error: observable,
      _location: observable,
      _language: observable,
      _defaultLanguage: observable,
      isLoading: computed,
      error: computed,
      location: computed,
      language: computed,
      defaultLanguage: computed,
      fetch: action,
    });

    this._appStore = appStore;
  }

  get isLoading() {
    return this._isLoading;
  }

  get error() {
    return this._error;
  }

  get location() {
    return this._location;
  }

  get language() {
    return this._language;
  }

  get defaultLanguage() {
    return this._defaultLanguage;
  }

  get theme() {
    return this.location?.theme ?? null;
  }

  get themeMain() {
    return this.location?.theme.themeMain ?? null;
  }

  get themeButton() {
    return this.location?.theme.themeButton ?? null;
  }

  get visibleWorkflows() {
    return this._location?.workflows.filter((workflow) => workflow.type !== WorkflowType.EMPLOYEE_CHECK_IN) || [];
  }

  get tabletTerminologies(): any {
    let tabletTerms = this._location?.terminologySetting?.tabletTerminologies || [];

    if (tabletTerms?.length === 0) {
      return this._location?.terminologySetting.english;
    } else if (tabletTerms?.length === 1) {
      return tabletTerms[0];
    } else {
      const i = tabletTerms?.findIndex((terms) => terms.languageName === this._language);
      return tabletTerms[i];
    }
  }

  resetError() {
    this._error = null;
  }

  setLanguage(languageName: string) {
    this._language = languageName;
    i18n.changeLanguage(languageName);
  }

  setDefaultLanguage(languageName: string) {
    this._defaultLanguage = languageName;
  }

  resetLanguage() {
    this.setLanguage(this._defaultLanguage);
  }

  async fetch(shouldClearLocation: boolean, appVersion?: string) {
    this._isLoading = true;
    let qParams = '';
    const baseUrl = await getBaseUrl();

    if (shouldClearLocation) {
      this._location = null;
    }

    if (Capacitor.isNativePlatform()) {
      const deviceInfo: DeviceInfo = await Device.getInfo();
      const deviceId: DeviceId = await Device.getId();
      const unitInfo =  deviceHashModel.find((item) => item.model === deviceInfo.model);

      const appVersion = packageJson.version;
      const uuid = deviceId.uuid;
      const model = unitInfo?.name || deviceInfo.model;
      const platform = deviceInfo.platform;
      const osVersion = deviceInfo.osVersion;

      qParams = `?model=${model}&platform=${platform}&version=${osVersion}&uuid=${uuid}&app_version=${appVersion}`;
      console.info({ qParams });
    }

    return Axios.get(baseUrl + '/api/locations/' + this._appStore.locationId + qParams, {
      headers: {
        ...this._appStore.authenticationHeader,
        'app-version': appVersion || '',
      },
    })
      .then((res: AxiosResponse<LocationAPIModel>) => {
        this._error = null;
        this._location = new LocationModel(res.data);
        ThemeModel.setTheme(this._location!.theme);
        let defaultLanguage = Utils.getDefaultLanguage(this._location?.terminologySetting.tabletTerminologies);
        this.setLanguage(defaultLanguage);
        this.setI18n(res.data.terminology_setting.tablet_terminologies);
        this.setDefaultLanguage(defaultLanguage);
      })
      .catch((err) => {
        this._error = err;
        console.error(Etc.LOG_PREFIX + JSON.stringify(err));
        if (err.response !== undefined) {
          if (err.response.status === 401) {
            throw LocationStoreFetchErrorCode.UNAUTHORIZED;
          } else {
            throw LocationStoreFetchErrorCode.UNKNOWN;
          }
        } else {
          throw LocationStoreFetchErrorCode.UNKNOWN;
        }
      })
      .finally(() => {
        this._isLoading = false;
      });
  }

  setI18n(languageArr: any) {
    languageArr.forEach((language: any) => {
      const i18Language = { ...language };
      delete i18Language.default;
      delete i18Language.language_name;

      i18next.addResourceBundle(language.language_name, 'translation', i18Language, true);
      if (language.default) {
        i18n.changeLanguage(language.language_name);
      }
    });
  }
}
