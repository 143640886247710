import { ThemeType } from '../../../commons/Constants';

export const getFontSize = (currentSpace: number, themeName: string | null): string => {
  let fSize = 'fs-1_0_rem';

  if (currentSpace > 70000) {
    fSize = 'fs-2_1_rem';
  } else if (currentSpace > 50000) {
    fSize = 'fs-1_8_rem';
  } else if (currentSpace > 44000) {
    fSize = 'fs-1_6_rem';
  } else if (currentSpace > 39000) {
    fSize = 'fs-1_5_rem';
  } else if (currentSpace > 37500) {
    fSize = 'fs-1_4_rem';
  } else if (currentSpace > 28000) {
    fSize = 'fs-1_3_rem';
  } else if (currentSpace > 27000) {
    fSize = 'fs-1_2_rem';
  } else if (currentSpace > 26500) {
    fSize = 'fs-1_1_rem';
  } else if (currentSpace > 22000) {
    fSize = 'fs-1_0_rem';
  }

  if ((themeName === ThemeType.Modern || themeName === ThemeType.Whimsical) && currentSpace < 15000) {
    fSize = 'display-none';
  }

  return fSize;
};

export const getWidthHeight = (component: HTMLElement): { width: number, height: number } => {
  return {
    width: component?.offsetWidth || 0,
    height: component?.offsetHeight || 0,
  }
}

export const getCurrentCompSpace = (component: HTMLElement): number => {
  const height = component?.clientHeight - 60;
  const width = component?.clientWidth;

  if (!height || !width) {
    return 0;
  }

  return height * width || 0;
};

export const setFontSize = (spaceOffset = 0, currentSpace = 0, themeButton: any, setLabelSize: any) => {
  const fSize = getFontSize(currentSpace + spaceOffset, themeButton);
  setLabelSize(fSize);
};

export const resizeText = ({ element, parent, minSize = 18, maxSize = 28, step = 1, unit = 'px' }: any) => {
  if (element && parent) {
    let i = minSize;
    let overflow = false;

    while (!overflow && i < maxSize) {
      element.style.fontSize = `${i}${unit}`;
      overflow = isOverflown(parent);

      if (!overflow) i += step;
    }

    const fontSize = i - step;
    element.style.fontSize = `${fontSize}${unit}`;
  }
}

function isOverflown({ clientWidth, clientHeight, scrollWidth, scrollHeight }: any) {
  return scrollWidth > clientWidth || scrollHeight > clientHeight;
}
