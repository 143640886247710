import React from 'react';
import AppStore from '../../mobx/AppStore';
import WorkflowNode from '../../models/v2/WorkflowNode';

export interface StepProps {
  appStore: AppStore;
}

export interface WorkflowProps extends StepProps {
  node: WorkflowNode;
}

class Step<P extends StepProps, S = {}> extends React.Component<P, S> {}

export default Step;
