import React from 'react';
import { Plugins } from '@capacitor/core';
import { Subscription } from 'rxjs';
import { ScreenOrientation } from '@ionic-native/screen-orientation';
import BarcodeScannerOptions from '../models/BarcodeScannerOptions';
import BarcodeScannerResults from '../models/BarcodeScannerResults';
import BarcodeScanner, { BarcodeScannerProps } from './BarcodeScanner';
import Utils from '../utils/Utils';

const { BarcodeScannerManager } = Plugins;

class NativeBarcodeScanner extends BarcodeScanner<BarcodeScannerProps> {
  private _barcodeEventName = 'BarcodeScannerEvent';
  private startTimeout: NodeJS.Timeout | null = null;
  private containerDiv = React.createRef<HTMLDivElement>();
  private screenOrientationChangeSubscription: Subscription | null = null;
  private _barcodeEventListener: any = null;

  componentDidMount() {
    this.screenOrientationChangeSubscription = ScreenOrientation.onChange().subscribe(() => this.restart());

    if (this.props.shouldAutoStart) {
      this.start(this.props.options);
    }
  }

  componentWillUnmount() {
    this.screenOrientationChangeSubscription?.unsubscribe();

    if (this.startTimeout !== null) {
      clearTimeout(this.startTimeout);
      this.startTimeout = null;
    }

    this.stop();
  }

  start(options?: BarcodeScannerOptions) {
    return new Promise<void>((res) => {
      // @TODO get div dimen without timeout
      this.startTimeout = setTimeout(() => {
        const rect = this.containerDiv.current!.getBoundingClientRect();

        BarcodeScannerManager.startBarcodeScanner({
          previewWidth: rect.width,
          previewHeight: rect.height,
          previewX: rect.x + (Utils.isAndroid() ? 0.5 : 0),
          previewY: rect.y + (Utils.isAndroid() ? 0.5 : 0),
          event: this._barcodeEventName,
          ...options,
        })
          //FIX ONE SCANNING ON QR_CODE ERROR
          // .then((res: BarcodeScannerResults) =>  {
          // 	this.props.onSuccess?.(res);
          // })
          .catch((err: any) => this.props.onError?.(err));
        this.addScannerListener();
        this.props.onStart?.();
        res();
      }, 1000);
    });
  }

  addScannerListener() {
    this._barcodeEventListener = Plugins.BarcodeScannerManager.addListener(
      this._barcodeEventName,
      (res: BarcodeScannerResults) => {
        this.props.onSuccess?.(res);
        this._barcodeEventListener.remove();
      },
    );
  }

  stop() {
    return BarcodeScannerManager.stopBarcodeScanner().then(() => {
      this._barcodeEventListener?.remove();
      this.props.onStop?.();
    });
  }

  async restart(options?: BarcodeScannerOptions) {
    return this.stop().then(() => {
      return this.start(options ? options : this.props.options);
    });
  }

  render() {
    return <div ref={this.containerDiv} className={this.props.className} />;
  }
}

export default NativeBarcodeScanner;
