import { IonLabel } from '@ionic/react';
import React from 'react';
import '../theme/components/inputs.css';
import '../theme/components/multiselect.css';
import Checkbox from './Checkbox';

interface Props {
  name?: string;
  multiTitle: string;
  multiOptions: Array<string>;
  values?: string;
  onChange?: (values: string) => void;
  isRequired?: boolean;
}

type State = {
  valueArray: Array<string>;
};

class MultiSelect extends React.Component<Props, State> {
  state: State = {
    valueArray: this.getInitialValueArray()
  };

  getInitialValueArray() {
    if (this.props.values !== undefined) {
      return this.props.values.split(',').filter((value) => this.props.multiOptions.includes(value));
    }

    return [];
  }

  addToArray = (e: any) => {
    let tempValueArray = this.state.valueArray;
    if (!this.state.valueArray.includes(e.detail.value)) {
      if (e.detail.checked) {
        tempValueArray.push(e.detail.value);
      }
    } else {
      if (!e.detail.checked) {
        tempValueArray = tempValueArray.filter((el) => el !== e.detail.value);
      }
    }

    this.setState({ valueArray: tempValueArray }, () => {
      this.onChange(tempValueArray.join(','));
    });
  };

  container = React.createRef<HTMLDivElement>();

  onChange = (e: any) => {
    if (this.props.isRequired) {
      const isChecked = this.state.valueArray.length !== 0;
      if (isChecked) {
        this.container.current!.classList.remove('warning');
      }
    }
    this.props.onChange?.(e);
  };

  validate = () => {
    if (this.props.isRequired) {
      const isChecked = this.state.valueArray.length !== 0;
      if (!isChecked) {
        this.container.current!.classList.add('warning');
      }
      return isChecked;
    } else {
      return true;
    }
  };

  render() {
    return (
      <div ref={this.container} className="multiselect">
        <IonLabel className="title">
          {this.props.multiTitle}
          {this.props.isRequired ? '*' : ''}
        </IonLabel>
        {this.props.multiOptions.map((checkbox, index) => {
          return (
            <Checkbox
              key={index}
              checkboxLabel={checkbox}
              nameValue={checkbox}
              isChecked={this.state.valueArray.includes(checkbox)}
              onIonChange={this.addToArray}
              isForMultiselect
            />
          );
        })}
        <input type="hidden" className="aux-input" name={this.props.name} value={this.state.valueArray} />
      </div>
    );
  }
}

export default MultiSelect;
