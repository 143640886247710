import React from 'react';
import { observer } from 'mobx-react';
import SearchHost from './steps/SearchHost/SearchHost';
import Visitor from './steps/Visitor';
import Signature from './steps/Signature/Signature';
import { NodeId, StepId } from '../commons/Constants';
import PackageSignature from './steps/PackageSignature';
import PhotoCapture from './steps/PhotoCapture';
import AppStore from '../mobx/AppStore';
import CheckOut from './steps/Checkout';
import Scan from './steps/Scan';
import EmployeeCheckIn from './steps/EmployeeCheckIn';
import { RollbarErrorHandler } from '../components/RollbarErrorHandler';
import Loading from '../components/Loading';
import Visitor_v2 from './steps/Visitor_v2';
import FinishWorkflow from './steps/FinishWorkflow';
import VideoPlayer from './steps/VideoPlayer';
import Scan_v2 from './steps/Scan_v2';
import StubLoading from './steps/StubLoading';
import DriverLicenseCapture from './steps/DriverLicenseCapture';
import WorkflowNode from '../models/v2/WorkflowNode';
import PackageCapture from './steps/PackageCapture';

interface Props {
  appStore: AppStore;
}

class Workflow extends React.Component<Props> {
  getStepPage = () => {
    const { workflowStore } = this.props.appStore;
    const dumbNode = { id: '', type: '', checkInOptionId: 0, formId: 0, data: {}, formData: {} } as WorkflowNode;
    const workflowProps = { node: dumbNode, ...this.props };
    switch (workflowStore.step?.id) {
      case StepId.SUCCESS:
        return <FinishWorkflow {...workflowProps} />;
      case StepId.SEARCH_HOST:
        return <SearchHost {...this.props} />;
      case StepId.VISITOR:
        return <Visitor {...this.props} />;
      case StepId.PACKAGE_SIGNATURE:
        return <PackageSignature {...this.props} />;
      case StepId.SIGNATURE:
        return <Signature {...this.props} />;
      case StepId.PHOTO_CAPTURE:
        return <PhotoCapture {...workflowProps} />;
      case StepId.CHECK_OUT:
        return <CheckOut {...this.props} />;
      case StepId.SCAN:
        return <Scan {...this.props} />;
      case StepId.EMPLOYEE_CHECK_IN:
        return <EmployeeCheckIn {...this.props} />;
      default:
        return null;
    }
  };

  getNodePage = () => {
    const { workflowStore } = this.props.appStore;
    const workflowProps = { node: workflowStore.node!, ...this.props };
    switch (workflowStore.node?.type) {
      case NodeId.EMPLOYEE_SEARCH:
        return <SearchHost {...this.props} />;

      case NodeId.END_CHECKIN_VISITOR:
      case NodeId.CHECK_IN_FAILED:
      case NodeId.END_SUCCESS:
      case NodeId.SEND_QR_CODE:
        return <FinishWorkflow {...workflowProps} />;

      case NodeId.IS_EMPLOYEE_WORK_HOURS:
        return <Loading message={'Getting work hours'} />;

      case NodeId.CUSTOM_FORM:
        return <Visitor_v2 {...workflowProps} />;

      case NodeId.TAKE_PHOTO:
        return <PhotoCapture {...workflowProps} />;

      case NodeId.WATCHLIST:
        return <StubLoading {...this.props} />;
      case NodeId.SEND_TEXT:
        return <StubLoading message={'Send Text Message'} {...this.props} />;

      case NodeId.SIGN_DOCUMENT:
        return <Signature {...this.props} />;

      // for NodeId.CHECK_OUT internally adds finish node to show success result page
      // you can check WorkflowLogic _checkout method
      case NodeId.CHECK_OUT:
        return <CheckOut {...this.props} />;

      case NodeId.SCAN:
        return <Scan_v2 {...workflowProps} />;

      case NodeId.VIDEO_PLAYER:
        return <VideoPlayer {...workflowProps} />;

      case NodeId.DRIVERS_LICENSE_PHOTO:
        return <DriverLicenseCapture {...workflowProps} />;

      case NodeId.PACKAGE_PHOTO:
        return <PackageCapture {...workflowProps} />;
      default:
        return null;
    }
  };

  render() {
    const { workflowStore } = this.props.appStore;
    return (
      <RollbarErrorHandler workflowStore={workflowStore}>
        {workflowStore.workflow?.useAlternativeWorkflow == true ? this.getNodePage() : this.getStepPage()}
      </RollbarErrorHandler>
    );
  }
}

export default observer(Workflow);
