import Axios from 'axios';
import { toJS } from 'mobx';
import { NodeId, TemperatureSettings, WorkflowType } from '../commons/Constants';
import ENV from '../env';
import WorkflowStore from '../mobx/WorkflowStore';
import WorkflowEdge from '../models/v2/WorkflowEdge';
import WorkflowModel_v2 from '../models/v2/WorkflowModel_v2';
import WorkflowNode from '../models/v2/WorkflowNode';
import WorkflowStep from '../models/v2/WorkflowStep';
import ConditionLogic, { Condition } from './ConditionLogic';
import { getBaseUrl } from '../utils/BaseUrls';

type WorkflowModel = WorkflowModel_v2;

// WorkflowLogic is using for new / altrenative workflow system (nodes and edges)
export default class WorkflowLogic {
  private _workflowStore: WorkflowStore | undefined;
  private _workflow: WorkflowModel | null | undefined;

  constructor(workflowStore?: WorkflowStore) {
    this._workflowStore = workflowStore;
    this._workflow = workflowStore?.workflow?.alternativeWorkflow;
  }

  hasNextNode(index: string, value?: any | null): boolean {
    let workflowEdges = this._workflow?.edges ?? [];
    return (
      workflowEdges.filter((edge) => edge.source === index && (value == null || this._checkEdgeValue(edge, value)))
        .length > 0
    );
  }

  nextNodeIndex(index: string, value?: boolean | null): string {
    let workflowEdges = this._workflow?.edges ?? [];
    const possibleEdges = workflowEdges.filter((edge) => edge.source === index);
    switch (possibleEdges.length) {
      case 0: {
        return '';
      }
      case 1: {
        return possibleEdges[0].target;
      }
      default: {
        return possibleEdges.filter((edge) => this._checkEdgeValue(edge, value))[0].target;
      }
    }
  }

  finishNode(data?: any) {
    this._workflowStore?.goToNextNode(data);
  }

  async runWorkflowLogic(node: WorkflowNode | undefined) {
    switch (node?.type) {
      case NodeId.IS_EMPLOYEE_WORK_HOURS:
        this._workHoursCondition().then((value) => {
          this.finishNode(value);
        });
        break;
      case NodeId.WATCHLIST:
        this._watchlist().then((value) => {
          this.finishNode(value);
        });
        break;
      case NodeId.SEND_TEXT:
        this._todoSendText(node).then(() => {
          this.finishNode();
        });
        break;
      case NodeId.PRINT_BADGE:
        this._printBadge(node).then((value) => {
          this.finishNode(value);
        });
        break;
      case NodeId.CHECK_OUT:
        this._checkOut();
        break;
      case NodeId.IS_PREREGISTRATION:
        this.finishNode(this._isPreregistation());
        break;
      case NodeId.IS_USER_REGISTERED:
        this.finishNode(this._isUserRegistered());
        break;
      case NodeId.CUSTOM_CONDITION:
        this.finishNode(this._checkCustomCondition(node));
        break;
      case NodeId.FIELD_CONDITION:
        this.finishNode(this._checkFieldCondition(node));
        break;
      case NodeId.TEMPERATURE:
        this.finishNode(this._checkTemperature());
        break;
      case NodeId.EMPLOYEE_SEARCH:
        this._employeeSearch(node);
        break;
      case NodeId.SIGN_DOCUMENT:
        this._enableSigningDocument();
        break;
      case NodeId.CUSTOM_FORM:
      case NodeId.TAKE_PHOTO:
      case NodeId.END_CHECKIN_VISITOR:
      case NodeId.END_SUCCESS:
      case NodeId.SCAN:
      case NodeId.CHECK_IN_FAILED:
      case NodeId.VIDEO_PLAYER:
      case NodeId.SEND_QR_CODE:
      case NodeId.DRIVERS_LICENSE_PHOTO:
      case NodeId.PACKAGE_PHOTO:
        break;
      default:
        this.finishNode();
        break;
    }
  }

  isSuccessCheckIn(node: WorkflowNode) {
    return node.type != NodeId.CHECK_IN_FAILED;
  }

  private _checkEdgeValue(edge: WorkflowEdge, valueToCheck: any): boolean {
    switch (edge.type) {
      case 'boolean': {
        return edge.value == valueToCheck;
      }
      default:
        return true;
    }
  }

  private async _workHoursCondition() {
    const workflowSteps = this._workflowStore?.workflowSteps;
    const baseUrl = await getBaseUrl();
    if (workflowSteps != null && workflowSteps.length > 0) {
      const employeeStep = workflowSteps.find((step) => step.type === NodeId.EMPLOYEE_SEARCH);
      const hostId = employeeStep?.value.id ?? 0;

      if (hostId != 0) {
        const currentTime = encodeURIComponent(new Date().toISOString().replace('Z', '+0000'));

        const url =
          baseUrl +
          '/api/users/' +
          hostId +
          '/check_working_hours?checked_time=' +
          currentTime +
          '&location_id=' +
          this._workflowStore?.locationInfo.id;

        return Axios.get(url, {
          headers: {
            ...this._workflowStore?.autenticationHeader,
          },
        })
          .then((res) => {
            return res.data['time_valid'] ?? true;
          })
          .catch((err) => {
            return true;
          });
      }
    }
    return false;
  }

  private async _watchlist() {
    const step = this._findStepWithValue('first_name');
    if (step != null) {
      const formValues = step.value;
      const date = new Date(formValues.date_of_birth);
      const params = {
        first_name: formValues.first_name,
        last_name: formValues.last_name,
        dob:
          date.toString() != 'Invalid Date'
            ? String(date.getMonth() + 1).padStart(2, '0') +
              '-' +
              String(date.getDate()).padStart(2, '0') +
              '-' +
              date.getFullYear()
            : null,
        location_id: this._workflowStore?.locationInfo.id,
      };
      const baseUrl = await getBaseUrl();
      const url = baseUrl + '/api/watchlist_visitors/blacklisted';
      return Axios.get(url, {
        headers: {
          ...this._workflowStore?.autenticationHeader,
        },
        params: params,
      })
        .then((res) => {
          return res.data.match ?? false;
        })
        .catch((error) => {
          return false;
        });
    } else {
      return false;
    }
  }

  private async _todoSendText(node: WorkflowNode) {
    let data = toJS(node.data);
    if (data.phone_number != null) {
      data.phone_number = '+' + data.phone_number;
    }
    this._workflowStore!.checkInRecord!.send_text = data;
  }

  private async _printBadge(node: WorkflowNode) {
    this._workflowStore!.workflow!.shouldPrintBadge = true;
    return node.data.selected.html;
  }

  private _checkOut() {
    const configureAdditionalInternalSteps = () => {
      Object.entries(this._workflow?.nodes ?? {}).forEach(([index, node]) => {
        if (node.type == NodeId.CHECK_OUT) {
          const new_step_name = 'internal_step_finish';
          this._workflow?.edges.push(
            new WorkflowEdge({ source: index, target: new_step_name, sourceHandle: index, data: {} }),
          );
          let checkout_message = { message: node.data.message };
          this._workflow!.nodes[new_step_name] = new WorkflowNode({
            id: new_step_name,
            type: NodeId.END_SUCCESS,
            checkInOptionId: null,
            formId: null,
            data: checkout_message,
          });
        }
      });
    };
    configureAdditionalInternalSteps();
    this._workflowStore!.workflow!.type = WorkflowType.CHECK_OUT;
  }

  private _isPreregistation(): boolean {
    return this._workflowStore?.isPreReg == true;
  }

  private _isUserRegistered(): boolean {
    return this._workflowStore?.isUserRegistered == true;
  }

  private _nodeToCondition(node: WorkflowNode): Condition {
    return {
      value: node.data.value,
      operator: node.data.comparison_operator,
    };
  }

  private _checkCustomCondition(node: WorkflowNode): boolean {
    const valueName = node.data.selected_field?.label ?? node.data.label;
    const step = this._findStepWithValue(valueName);
    let result = false;
    if (step != null) {
      const formValues = step.value;
      result = ConditionLogic.calculate(this._nodeToCondition(node), formValues[valueName]);
    }
    return result;
  }

  private _checkFieldCondition(node: WorkflowNode): boolean {
    const valueNameFirst = node.data.selected_field?.label;
    const valueNameSecond = node.data.selected_field_for_comparison?.label;
    const stepFirst = this._findStepWithValue(valueNameFirst);
    const stepSecond = this._findStepWithValue(valueNameSecond);
    let result = false;
    if (stepFirst != null && stepSecond != null) {
      result = ConditionLogic.calculate(
        {
          value: stepSecond.value[valueNameFirst],
          operator: node.data.comparison_operator,
        },
        stepSecond.value[valueNameSecond],
      );
    }
    return result;
  }

  private _findStepWithValue(valueName: string): WorkflowStep | null {
    const workflowSteps = this._workflowStore?.workflowSteps;
    if (workflowSteps != null && workflowSteps.length > 0) {
      const listFormSteps = workflowSteps.filter((step) => step.value != undefined && step.value[valueName] != null);
      if (listFormSteps.length > 0) {
        const stepWithValue = listFormSteps[listFormSteps.length - 1];
        return stepWithValue;
      }
    }
    return null;
  }

  private _checkTemperature() {
    return (
      this._workflowStore?.thermalCheckInRecord?.visitor_info_fields.temperature ??
      Number.MAX_SAFE_INTEGER < TemperatureSettings.MAX()
    );
  }

  private _employeeSearch(node: WorkflowNode): void {
    if (this._workflowStore?.workflow != null) {
      this._workflowStore!.workflow!.shouldSearchEmployee =
        node.data.is_result_hidden_until_search == false ?? this._workflowStore!.workflow!.shouldSearchEmployee;
    }
  }

  private _enableSigningDocument(): void {
    if (this._workflowStore?.workflow != null) {
      this._workflowStore!.workflow!.hasNDASignature = true;
    }
  }
}
