import TerminologySettingAPIModel from './api/TerminologySettingsAPIModel';
import TerminologyLanguageModel from './TerminologyLanguageModel';

export default class TerminologySettingModel {
  title: string;
  company: string;
  photoCountdown: number;
  english: TerminologyLanguageModel;
  tabletTerminologies: Array<TerminologyLanguageModel> = [];

  constructor(terminologySettingAPI: TerminologySettingAPIModel) {
    this.title = terminologySettingAPI.title;
    this.company = terminologySettingAPI.company;
    this.photoCountdown = terminologySettingAPI.photo_countdown;
    this.english = new TerminologyLanguageModel(terminologySettingAPI.english);

    for (let i = 0; i < terminologySettingAPI.tablet_terminologies.length; ++i) {
      this.tabletTerminologies.push(new TerminologyLanguageModel(terminologySettingAPI.tablet_terminologies[i]));
    }
  }
}
