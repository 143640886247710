import { IonItem, IonLabel, IonToggle } from '@ionic/react';
import React from 'react';
import { ThemeType } from '../commons/Constants';
import '../theme/design_helper.css';
import i18next, { t } from 'i18next';

interface Props {
  header?: string;
  theme?: string | null;
  i18nBase?: string;
}

class Header extends React.Component<Props> {
  private i18nBase = this.props.i18nBase || '';
  render() {
    return (
      <IonItem
        className={
          this.props.theme == ThemeType.Old
            ? 'title app-screen-title'
            : `app-screen-title-new-design ${this.props.theme}`
        }
        id="title"
        lines="none"
      >
        <IonLabel className="label-new-line">
          {i18next.exists(this.i18nBase) ? t(this.i18nBase) : this.props.header}
        </IonLabel>
      </IonItem>
    );
  }
}

export default Header;
