import WorkflowModel from './WorkflowModel';
import LocationAPIModel from './api/LocationAPIModel';
import TerminologySettingModel from './TerminologySettingsModel';
import ThemeModel from './ThemeModel';
import PreregModel from './PreregModel';
import SettingsModel from './SettingsModel';
import BadgeSettingModel from './BadgeSettingModel';
import { WorkflowType } from '../commons/Constants';

export default class LocationModel {
  id: number;
  name: string;
  logo: string | null;
  workflows: Array<WorkflowModel> = [];
  terminologySetting: TerminologySettingModel;
  theme: ThemeModel;
  badgeSetting: BadgeSettingModel;
  noTouchID: string | null = '';
  gdpr: string | null = '';
  prereg: PreregModel;
  settings: SettingsModel | null;
  isThermalCheckInEnabled: boolean;
  isPrintBadgeEnabled: boolean = false;
  isSubscriptionValid: boolean;

  constructor(locationAPI: LocationAPIModel) {
    this.id = locationAPI.id;
    this.name = locationAPI.name;
    this.logo = locationAPI.logo;
    this.noTouchID = locationAPI.no_touch_id;

    locationAPI.active_check_in_options.forEach((workflowAPI) => {
      this.workflows.push(new WorkflowModel(workflowAPI));
    });

    this.workflows.sort((a, b) => {
      if (a.order > b.order) {
        return 1;
      } else if (a.order < b.order) {
        return -1;
      } else {
        return 0;
      }
    });

    this.terminologySetting = new TerminologySettingModel(locationAPI.terminology_setting);
    this.theme = new ThemeModel(locationAPI.theme);
    this.badgeSetting = new BadgeSettingModel(locationAPI.badge_setting);
    this.gdpr = locationAPI.gdpr;
    this.prereg = new PreregModel(locationAPI.prereg);
    this.isThermalCheckInEnabled = locationAPI.enable_thermal_checkin;
    this.isPrintBadgeEnabled =
      locationAPI.print_badge ??
      (locationAPI.active_check_in_options.find((workflowAPI) => workflowAPI.print_badge) != null ||
        this.workflows.find((newWorkflow) => newWorkflow.shouldPrintBadge) != null);

    this.isSubscriptionValid = locationAPI.subscription != null ? locationAPI.subscription : true;
    this.settings = locationAPI.settings != null ? new SettingsModel(locationAPI.settings) : null;

    this.logo = this.theme.welcomeLogo ?? this.theme?.buttonLogo ?? locationAPI.logo;
  }

  getWorkflowById(id: number) {
    const i = this.workflows.findIndex((workflow) => workflow.id === id);
    if (i !== -1) {
      return this.workflows[i];
    }

    return null;
  }

  hasWorkflow(workflowType: WorkflowType) {
    return this.workflows.findIndex((workflow) => workflow.type === workflowType) !== -1;
  }
}
