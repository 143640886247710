import { IonContent, IonPage, IonButton } from '@ionic/react';
import React from 'react';
import { StepProps } from './Step';
import '../../theme/pages/finishworkflow.css';
import { observer } from 'mobx-react';
import Utils from '../../utils/Utils';
import Banner from '../../components/Banner';
import DriverLicenseDataModel from '../../models/DriverLicenseDataModel';
import NativeBarcodeScanner from '../../components/NativeBarcodeScanner';
import '../../theme/pages/scan.css';
import BarcodeScannerOptions from '../../models/BarcodeScannerOptions';
import BarcodeScannerResults from '../../models/BarcodeScannerResults';
import WebBarcodeScanner from '../../components/WebBarcodeScanner';
import { useErrorHandler } from 'react-error-boundary';
import DesignHelper from './DesignHelper';
import { ThemeType } from '../../commons/Constants';

const Scan = observer((props: StepProps): JSX.Element => {
  const errorHandler = useErrorHandler();

  const goToNextStepSkipped = () => {
    const { workflowStore } = props.appStore;
    workflowStore.isDLScanSkipped = true;
    workflowStore.goToNextStep();
  };

  const getBarcodeScannerOptions = (): BarcodeScannerOptions => {
    return {
      possibleFormats: ['PDF_417'],
      isFrontCamera: false,
      aspectRatioEqual: true,
    };
  };

  const onBarcodeScannerSuccess = (res: BarcodeScannerResults) => {
    if (res.barcodeFormat === 'PDF_417') {
      const { workflowStore } = props.appStore;
      const dlData = DriverLicenseDataModel.parseText(res.text);
      workflowStore.setDriverLicenseData(dlData);

      workflowStore.goToNextStep();
    }
  };

  const onBarcodeScannerError = (err: any) => {
    errorHandler(err);
    if (Utils.isNative()) {
      Utils.showErrorToast('An error has occurred');
    }
  };

  const { locationStore, workflowStore } = props.appStore;
  const themeMain = locationStore.themeMain;
  const themeButton = locationStore.themeButton;
  return (
    <IonPage className="scan">
      <IonContent>
        <Banner {...props} />
        {DesignHelper.renderThemeDesignTop(themeMain, locationStore.location?.logo || null)}
        {Utils.isNative() && DesignHelper.renderHomeButton(themeButton, () => workflowStore.reset(true))}

        <div
          className={themeMain == ThemeType.Old ? 'barcode-scanner-container' : 'barcode-scanner-container-new-design'}
        >
          {/* @TODO style */}
          {DesignHelper.renderThemeLabelTitle(themeMain, locationStore.tabletTerminologies.dlScanTitle)}
          {Utils.isNative() && Utils.isMobileApp() ? (
            <NativeBarcodeScanner
              className="barcode-scanner"
              shouldAutoStart
              options={getBarcodeScannerOptions()}
              onSuccess={(res) => onBarcodeScannerSuccess(res)}
              onError={(err) => onBarcodeScannerError(err)}
            />
          ) : (
            <WebBarcodeScanner
              className="barcode-scanner"
              shouldAutoStart
              options={getBarcodeScannerOptions()}
              onSuccess={(res) => onBarcodeScannerSuccess(res)}
              onError={(err) => onBarcodeScannerError(err)}
            />
          )}
          {/* @TODO style & terminology */}
          <div className="btn-container">
            <IonButton className="generic-button skip" onClick={() => goToNextStepSkipped()}>
              Skip
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
});

export default Scan;
