import { IonLabel, IonButton } from '@ionic/react';
import React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import '../theme/pages/signatureEmbedded.css';

interface Props {
  signatureText: string;
  clearText: string;
}

type State = {
  width: number;
};

class SignatureEmbedded extends React.Component<Props, State> {
  state: State = {
    width: 0,
  };

  signatureCanvas: SignatureCanvas = React.createRef<SignatureCanvas>().current!;
  divContent: any = React.createRef<any>().current!;

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    setTimeout(() => {
      this.updateDimensions();
    }, 50);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: this.divContent.clientWidth });
  };

  clearSignature = () => {
    this.signatureCanvas.clear();
  };

  // visitorFullName = () => {
  // 	const visitorFields = this.props.appStore.workflowStore
  // 		.checkInRecord!.visitorInfoFieldValues!;
  // 	if (visitorFields.first_name !== undefined && visitorFields.last_name !== undefined) {
  // 		if (visitorFields.last_name !== undefined) {
  // 			return visitorFields.first_name + " " + visitorFields.last_name;
  // 		}
  // 		return visitorFields.first_name;
  // 	} else {
  // 		return "";
  // 	}
  // }

  // visitorEmail = () => {
  // 	const visitorFields = this.props.appStore.workflowStore
  // 		.checkInRecord!.visitorInfoFieldValues!;
  // 	if (visitorFields.email !== undefined) {
  // 		return visitorFields.email;
  // 	} else {
  // 		return "";
  // 	}
  // }

  getDate = (inDate: string | number | Date) => {
    const event = new Date(inDate);

    // @TODO use actual locale of device
    return event.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
  };

  render() {
    return (
      <div ref={(ref) => (this.divContent = ref!)} className="signatureEmbedded">
        <IonLabel className="signature-text">{this.props.signatureText}</IonLabel>
        <SignatureCanvas
          ref={(ref) => (this.signatureCanvas = ref!)}
          canvasProps={{ className: 'sign-canvas', width: this.state.width, height: 200 }}
        />
        <div className="signature-above">
          <IonLabel className="date">{this.getDate(new Date())}</IonLabel>
          {/* <IonLabel className="fullName">{this.visitorFullName()}</IonLabel> */}
          <IonButton className="clearbtn white-button" onClick={this.clearSignature}>
            {this.props.clearText}
          </IonButton>
        </div>
      </div>
    );
  }
}

export default SignatureEmbedded;
