import React from 'react';
import { IonContent, IonPage } from '@ionic/react';

import Step, { StepProps } from './Step';
import '../../theme/pages/employeeCheckIn.css';
import Banner from '../../components/Banner';
import { observer } from 'mobx-react';
import Utils from '../../utils/Utils';
import Inputs from '../../components/Inputs';
import DesignHelper from './DesignHelper';

class EmployeeCheckIn extends Step<StepProps> {
  onSubmit = (e: any) => {
    e.preventDefault();
    const { workflowStore } = this.props.appStore;
    workflowStore.goToNextStep();
  };

  render() {
    const { appStore } = this.props;
    const { locationStore, workflowStore } = appStore;
    const themeButton = locationStore.themeButton;

    return (
      <IonPage className="employee-check-in">
        <IonContent>
          <Banner {...this.props} />
          <div className="img-container">
            <img
              className={locationStore.location?.logo ? 'brand-logo' : 'brand-logo opacity-zero'}
              alt=""
              src={locationStore.location?.logo || ''}
            ></img>
          </div>
          {Utils.isNative() && DesignHelper.renderHomeButton(themeButton, () => workflowStore.reset(true))}
          <form onSubmit={(e) => this.onSubmit(e)}>
            <Inputs
              type={'text'}
              forPlaceholder={'Employee Id'}
              forIcon="id-card-o"
              isRequired
              value={workflowStore.surrogateId}
              onChange={(e) => workflowStore.setSurrogateId((e.target as HTMLInputElement).value)}
            />
            {DesignHelper.renderNextScreenButton(appStore.screenType)}
          </form>
        </IonContent>
      </IonPage>
    );
  }
}

export default observer(EmployeeCheckIn);
