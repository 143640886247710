import { Preferences as Storage } from '@capacitor/preferences';
import { KEY_FAILED_CHECK_INS } from './Constants';

//Singleton class for fetching and managing failed check ins
export class FailedCheckInCounter {
  private static _instance: FailedCheckInCounter;
  private _failedCheckInNumberCurrentSession = 0;
  private _failedCheckInNumberTotal = 0;

  private constructor() {}

  public static get shared() {
    return this._instance || (this._instance = new this());
  }

  public static init = () => {
    return FailedCheckInCounter.shared;
  };

  get failedCheckInNumber(): number {
    return this._failedCheckInNumberCurrentSession;
  }

  async failedCheckInNumberTotal(): Promise<number> {
    return this._failedCheckInNumberTotal !== 0 ? this._failedCheckInNumberTotal : this.getTotalCheckIns();
  }

  increaseFailedCheckInNumber = () => {
    this._failedCheckInNumberCurrentSession += 1;
    this.updateTotalCheckIns(this._failedCheckInNumberTotal + 1);
  };

  private getTotalCheckIns = async () => {
    return Storage.get({ key: KEY_FAILED_CHECK_INS }).then((data) => {
      this._failedCheckInNumberTotal = isNaN(Number(data.value)) ? 0 : Number(data.value);
      return this._failedCheckInNumberTotal;
    });
  };

  private updateTotalCheckIns = (value: number) => {
    this._failedCheckInNumberTotal = value;
    Storage.set({
      key: KEY_FAILED_CHECK_INS,
      value: value.toString()
    });
  };
}
