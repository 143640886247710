import { IonPage, IonContent, IonLabel, isPlatform, IonImg, IonButton } from '@ionic/react';
import React from 'react';
import WebCam from 'react-webcam';
import { Subscription } from 'rxjs';
import { CameraPreview } from '@ionic-native/camera-preview';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { t } from 'i18next';

import Step, { WorkflowProps } from './Step';
import '../../theme/pages/photoCapture.css';
import { PathName } from '../../commons/Constants';
import Utils from '../../utils/Utils';
import { ScreenOrientation } from '@ionic-native/screen-orientation';
import { ScreenType } from '../../commons/Constants';
import GreetlyIcon from '../../components/GreetlyIcon';

enum PhotoStep {
  front,
  back,
  both, // end of photo workflow
}

type State = {
  shouldRedirectToUnknownError: boolean;
  shouldShowWarning: boolean;
  isCountdown: boolean;
  remainingTimeSeconds: number;
  isInCountdown: boolean;
  shouldShowCamera: boolean;
  isReviewRequired: boolean;
  imageFrontBase64: string | null;
  imageBackBase64: string | null;
  currentPhotoStep: PhotoStep;
};

class DriverLicenseCapture extends Step<WorkflowProps, State> {
  private photoWorkflowType: PhotoStep = this._getPhotoType(this.props.node.data.dl_capture_settings);

  state: State = {
    shouldRedirectToUnknownError: false,
    shouldShowWarning: false,
    isCountdown: false,
    remainingTimeSeconds: this.props.appStore.locationStore!.location?.terminologySetting.photoCountdown!,
    isInCountdown: false,
    shouldShowCamera: false,
    isReviewRequired: true,
    imageFrontBase64: null,
    imageBackBase64: null,
    currentPhotoStep: this.photoWorkflowType === PhotoStep.back ? PhotoStep.back : PhotoStep.front,
  };

  webCamRef = React.createRef<WebCam & HTMLVideoElement>();
  private screenOrientationChangeSubscription: Subscription | null = null;

  componentDidMount() {
    if (Utils.isNative()) {
      this.screenOrientationChangeSubscription = ScreenOrientation.onChange().subscribe((next) =>
        this.restartNativeCamera(),
      );
      this.startNativeCamera();
    } else {
      this.setupMediaDevice()
        .then(() => {
          this.setState({ shouldShowCamera: true }, () => {
            if (this.state.isCountdown) {
              if (!this.state.isInCountdown) {
                this.setState({ isInCountdown: true }, () => {
                  this.takePictureCountdown();
                });
              }
            }
          });
        })
        .catch(() => {
          this.setState({ shouldShowWarning: true });
        });
    }
  }

  componentWillUnmount() {
    if (Utils.isNative()) {
      this.screenOrientationChangeSubscription?.unsubscribe();

      const root = document.documentElement;

      this.stopNativeCamera();
      root.style.setProperty('--ion-background-color', 'var(--greetly-background-color)');
    }
  }

  startNativeCamera() {
    let width: number | undefined = undefined;
    let height: number | undefined = undefined;
    if (isPlatform('ios')) {
      if (
        ScreenOrientation.type === ScreenOrientation.ORIENTATIONS.LANDSCAPE ||
        ScreenOrientation.type === ScreenOrientation.ORIENTATIONS.LANDSCAPE_PRIMARY ||
        ScreenOrientation.type === ScreenOrientation.ORIENTATIONS.LANDSCAPE_SECONDARY
      ) {
        width = window.screen.height;
        height = window.screen.width;
      }
    }

    CameraPreview.startCamera({
      width: width,
      height: height,
      previewDrag: false,
      tapFocus: false,
      tapPhoto: false,
      camera: 'rear',
      toBack: true,
      disableExifHeaderStripping: window.innerWidth > window.innerHeight,
    }).then(() => {
      const root = document.documentElement;
      root.style.setProperty('--ion-background-color', 'transparent');

      this.setState({ shouldShowCamera: true }, () => {
        if (this.state.isCountdown) {
          if (!this.state.isInCountdown) {
            this.setState({ isInCountdown: true }, () => {
              this.takePictureCountdown();
            });
          }
        }
      });
    });
  }

  stopNativeCamera() {
    return CameraPreview.stopCamera();
  }

  restartNativeCamera() {
    this.stopNativeCamera().then(() => {
      this.startNativeCamera();
    });
  }

  takePictureCountdown = () => {
    if (this.state.remainingTimeSeconds <= 0) {
      this.setState({ remainingTimeSeconds: 0, isInCountdown: false }, () => {
        this.takePicture();
      });
    } else {
      setTimeout(() => {
        this.setState({ remainingTimeSeconds: this.state.remainingTimeSeconds - 1 }, () => {
          this.takePictureCountdown();
        });
      }, 1000);
    }
  };

  _setImageClosePage(imageBase64: string | false | null) {
    const { workflowStore } = this.props.appStore;

    if (typeof imageBase64 === 'string') {
      let finishAndNextNode = false;
      if (this.state.currentPhotoStep === PhotoStep.front) {
        workflowStore.setFrontDriverLicenseImage(imageBase64);
        if (this.photoWorkflowType === PhotoStep.both) {
          this.setState({ currentPhotoStep: PhotoStep.back });
          if (Utils.isNative()) {
            this.startNativeCamera();
          } else {
            this.setupMediaDevice();
          }
        } else {
          finishAndNextNode = true;
        }
      } else if (this.state.currentPhotoStep === PhotoStep.back) {
        workflowStore.setBackDriverLicenseImage(imageBase64);
        finishAndNextNode = true;
      }
      if (finishAndNextNode) {
        workflowStore.goToNextNode();
      }
    }
  }

  _getPhotoType(photoType: string | null | undefined) {
    switch (photoType) {
      case 'front only':
        return PhotoStep.front;
      case 'back only':
        return PhotoStep.back;
      default:
        return PhotoStep.both;
    }
  }

  takePicture = () => {
    if (this.state.isInCountdown) {
      return;
    }

    let _finishWithImage = (imageBase64: string) => {
      if (!this.state.isReviewRequired) {
        this._setImageClosePage(imageBase64);
      } else {
        this.stopNativeCamera();
        if (this.state.currentPhotoStep === PhotoStep.front) {
          this.setState({ imageFrontBase64: imageBase64 });
        } else if (this.state.currentPhotoStep === PhotoStep.back) {
          this.setState({ imageBackBase64: imageBase64 });
        }
      }
    };

    if (Utils.isNative()) {
      CameraPreview.takePicture({
        quality: 100,
      })
        .then((imageBase64) => {
          if (imageBase64.length === 1) {
            imageBase64 = imageBase64[0];
          }
          _finishWithImage(imageBase64);
        })
        .catch(() => {
          Utils.showErrorToast('An error has occurred');
        });
    } else {
      const imageBase64Url = this.webCamRef.current!.getScreenshot();
      if (imageBase64Url !== null) {
        const imageBase64 = imageBase64Url.substring(imageBase64Url.lastIndexOf(',') + 1, imageBase64Url.length);
        _finishWithImage(imageBase64);
      } else {
        this.setState({ shouldRedirectToUnknownError: true });
      }
    }
  };

  retakePhoto() {
    if (Utils.isNative()) {
      this.startNativeCamera();
    } else {
      this.setupMediaDevice()
        .then(() => {
          this.setState({ shouldShowCamera: true });
        })
        .catch(() => {
          this.setState({ shouldShowWarning: true });
        });
    }

    if (this.state.currentPhotoStep === PhotoStep.front) {
      this.setState({ imageFrontBase64: null });
    } else if (this.state.currentPhotoStep === PhotoStep.back) {
      this.setState({ imageBackBase64: null });
    }
  }

  private setupMediaDevice = () => {
    return navigator.mediaDevices.getUserMedia({
      video: { facingMode: 'user', height: { ideal: 4096 }, width: { ideal: 2160 } },
    });
  };

  render() {
    if (!Utils.isNative()) {
      if (this.state.shouldRedirectToUnknownError) {
        return <Redirect to={PathName.UNKNOWN_ERROR} />;
      }
    }

    const { locationStore } = this.props.appStore;

    const photoForReview =
      (this.state.currentPhotoStep === PhotoStep.front && this.state.imageFrontBase64) ||
      (this.state.currentPhotoStep === PhotoStep.back && this.state.imageBackBase64);

    let photoTextDescription =
      this.state.currentPhotoStep === PhotoStep.front
        ? locationStore.tabletTerminologies.frontSideDriverLicenseHeader
        : locationStore.tabletTerminologies.backSideDriverLicenseHeader;
    photoTextDescription =
      photoTextDescription +
      (this.photoWorkflowType === PhotoStep.both
        ? this.state.currentPhotoStep === PhotoStep.front
          ? ' (1/2)'
          : ' (2/2)'
        : '');
    return (
      <IonPage className="photocapture">
        <IonContent scroll-y="false">
          {this.state.shouldShowWarning ? (
            <>
              <div className="warning">
                {/* @TODO styling */}
                Photo is required. Please allow the app to access the camera and try again.
              </div>
            </>
          ) : (
            <>
              {this.state.isReviewRequired && photoForReview ? (
                <div className="photo-container">
                  <div>
                    <IonImg className="photo-image" src={'data:image/jpeg;base64, ' + photoForReview} />
                    <IonButton
                      className="generic-button"
                      onClick={() => this._setImageClosePage(photoForReview)}
                      style={{ position: 'absolute', bottom: 0, right: '10%' }}
                    >
                      <div>{t('next')}</div>
                    </IonButton>
                    <IonButton
                      className="generic-button-cancel"
                      onClick={() => this.retakePhoto()}
                      style={{ position: 'absolute', bottom: 0, left: '10%' }}
                    >
                      {this.props.appStore.screenType === ScreenType.Phone && <GreetlyIcon iconName="rotate-left" />}
                      {this.props.appStore.screenType === ScreenType.Tablet && 'RE-TAKE'}
                    </IonButton>
                  </div>
                </div>
              ) : (
                this.state.shouldShowCamera && (
                  <>
                    {/* <div className="driver-photo-title">{photoHeaderDescription?.toUpperCase()}</div> */}
                    {!Utils.isNative() && (
                      <div className="cam-cont">
                        <WebCam
                          className="cam-prev"
                          ref={this.webCamRef}
                          audio={false}
                          screenshotFormat="image/jpeg"
                          videoConstraints={{
                            facingMode: 'environment',
                          }}
                        ></WebCam>
                      </div>
                    )}
                    <div className="capture-container">
                      {!this.state.isCountdown && (
                        <IonLabel className="prev-text">{photoTextDescription?.toUpperCase()}</IonLabel>
                      )}
                      {/* { this.state.isCountdown && <IonLabel className="prev-text">{locationStore.tabletTerminologies.smileCount}</IonLabel> } */}
                      <button className="capture-button" onClick={() => this.takePicture()}>
                        {this.state.isCountdown ? this.state.remainingTimeSeconds : ''}
                      </button>
                    </div>
                  </>
                )
              )}
            </>
          )}
        </IonContent>
      </IonPage>
    );
  }
}

export default observer(DriverLicenseCapture);
