import { IonItem, IonLabel } from '@ionic/react';
import React from 'react';
import Rating from 'react-rating';

import '../theme/components/starRating.css';

interface Props {
  name?: string;
  ratingLabel: string;
  onChange?: (value: number) => void;
  isRequired?: boolean;
}

class StarRating extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }
  state = {
    selectedRating: 0,
  };
  item = React.createRef<HTMLIonItemElement>();

  onChange = (value: number) => {
    this.setState({ selectedRating: value });
    this.item.current!.classList.remove('warning');
    this.props.onChange?.(value);
  };

  validate = () => {
    if (this.props.isRequired) {
      const isChecked = this.state.selectedRating > 0;
      if (!isChecked) {
        this.item.current!.classList.add('warning');
      }
      return isChecked;
    } else {
      return true;
    }
  };

  render() {
    return (
      <IonItem ref={this.item} lines="none" className="starRating">
        <IonLabel className="name">{this.props.ratingLabel}</IonLabel>

        <Rating
          quiet={true}
          initialRating={this.state.selectedRating}
          className="stars"
          emptySymbol="fa-regular fa-star fa-2x"
          fullSymbol="fa-solid fa-star fa-2x"
          onClick={this.onChange}
        />
      </IonItem>
    );
  }
}

export default StarRating;
