import { action, observable, computed, makeObservable } from 'mobx';
import HostModel from '../models/HostModel';
import ENV from '../env';
import Axios, { AxiosResponse } from 'axios';
import AppStore from './AppStore';
import { Etc } from '../commons/Constants';
import HostAPIModel from '../models/api/HostAPIModel';
import { getBaseUrl } from '../utils/BaseUrls';

export class HostsStoreFetchErrorCode {
  static get UNAUTHORIZED() {
    return 'hosts_store_fetch_unauthorized';
  }
  static get UNKNOWN() {
    return 'hosts_store_fetch_unknown';
  }
}

export default class HostsStore {
  private _appStore!: AppStore;
  private _isLoading = false;
  private _error: any = null;
  private _hosts: Array<HostModel> = [];
  private _searchStr = '';

  constructor(appStore: AppStore) {
    makeObservable<HostsStore, '_isLoading' | '_error' | '_hosts' | '_searchStr'>(this, {
      _isLoading: observable,
      _error: observable,
      _hosts: observable,
      _searchStr: observable,
      isLoading: computed,
      error: computed,
      hosts: computed,
      filteredHosts: computed,
      searchStr: computed,
      fetch: action,
      setSearchStr: action,
      clearHosts: action,
    });

    this._appStore = appStore;
  }

  get isLoading() {
    return this._isLoading;
  }

  get error() {
    return this._error;
  }

  get hosts() {
    return this._hosts;
  }

  get filteredHosts() {
    if (this._appStore.workflowStore.workflow?.shouldSearchEmployee === false) {
      if (this._searchStr.length <= 1) {
        return [];
      }
    }

    return this._hosts;
  }

  get searchStr() {
    return this._searchStr;
  }

  async fetch(workflowId: number, search: string) {
    this._isLoading = true;
    this._error = null;
    const baseUrl = await getBaseUrl();

    const url =
      baseUrl +
      `/api/users/search?location_id=${this._appStore.locationId}&check_in_option_id=${workflowId}` +
      (search.length > 0 ? `&query=${search}` : '');

    return Axios.get(url, {
      headers: {
        ...this._appStore.authenticationHeader,
      },
    })
      .then(async (res: AxiosResponse<Array<HostAPIModel>>) => {
        this._hosts = res.data.map((hostAPI) => {
          return new HostModel(hostAPI);
        });
      })
      .catch((err) => {
        this._error = err;
        console.error(Etc.LOG_PREFIX + JSON.stringify(err));
        if (err.response !== undefined) {
          if (err.response.status === 401) {
            throw HostsStoreFetchErrorCode.UNAUTHORIZED;
          } else {
            throw HostsStoreFetchErrorCode.UNKNOWN;
          }
        } else {
          throw HostsStoreFetchErrorCode.UNKNOWN;
        }
      })
      .finally(() => {
        this._isLoading = false;
      });
  }

  setSearchStr(searchStr: string) {
    this._searchStr = searchStr;
  }

  clearHosts() {
    this._hosts = [];
  }

  getHostById(id?: number) {
    const i = this._hosts.findIndex((host) => host.id === id);
    if (i === -1) return null;
    return this._hosts[i];
  }
}
