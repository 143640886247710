// ConditionLogic uses for custom condition block to calculate the result of condition  in  new / altrenative workflow system (nodes and edges)
export interface Condition {
  value: any;
  operator: string;
}

export default class ConditionLogic {
  static calculate(condition: Condition, compareToValue: any | null) {
    let number;
    let valueNumber;
    let result = false;
    const operator = condition.operator.toUpperCase();
    if (compareToValue == null) {
      result = operator === 'UNDEFINED';
    } else {
      let conditionValue = condition.value;
      switch (operator) {
        case 'CONTAINS':
          if (conditionValue.indexOf(compareToValue) >= 0) {
            result = true;
          }
          break;
        case 'EQUAL':
        case 'EXACT':
          if (typeof conditionValue === 'string' && typeof compareToValue === 'string') {
            result = conditionValue.toLowerCase() === compareToValue.toLowerCase();
          } else if (conditionValue == compareToValue) {
            result = true;
          }
          break;
        case 'NOT_EQUAL':
          if (conditionValue !== compareToValue) {
            result = true;
          }
          break;
        case 'LESS_THAN':
        case 'GREATER_THAN':
          number = parseFloat(conditionValue);
          valueNumber = parseFloat(compareToValue);
          switch (operator) {
            case 'LESS_THAN':
              if (!isNaN(number) && !isNaN(valueNumber) ? valueNumber < number : compareToValue < conditionValue) {
                result = true;
              }
              break;
            case 'GREATER_THAN':
              if (!isNaN(number) && !isNaN(valueNumber) ? valueNumber > number : compareToValue > conditionValue) {
                result = true;
              }
              break;
          }
          break;
        case 'IS':
          result = (compareToValue as string).toBoolean() === (conditionValue as string).toBoolean();
          break;
      }
    }
    return result;
  }
}
