import { IonItem, IonLabel, IonToggle } from '@ionic/react';
import React from 'react';
import '../theme/components/toggle.css';

interface Props {
  name?: string;
  toggleLabel: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  isRequired?: boolean;
}

class Toggle extends React.Component<Props> {
  item = React.createRef<HTMLIonItemElement>();
  toggle = React.createRef<HTMLIonToggleElement>();

  onChange = (e: any) => {
    if (this.props.isRequired) {
      const isChecked = this.toggle.current!.checked;
      if (isChecked) {
        this.item.current!.classList.remove('warning');
      }
    }

    this.props.onChange?.(e);
  };

  validate = () => {
    if (this.props.isRequired) {
      const isChecked = this.toggle.current!.checked;
      if (!isChecked) {
        this.item.current!.classList.add('warning');
      }
      return isChecked;
    } else {
      return true;
    }
  };

  render() {
    return (
      <IonItem ref={this.item} lines="none" className="toggle">
        <IonLabel className="name">
          {this.props.toggleLabel}
          {this.props.isRequired ? '*' : ''}
        </IonLabel>
        <IonToggle
          ref={this.toggle}
          checked={this.props.checked}
          name={this.props.name}
          onIonChange={(e) => this.onChange?.(e.detail.checked)}
        />
      </IonItem>
    );
  }
}

export default Toggle;
