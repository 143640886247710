import { IonPage, IonContent, IonLabel, isPlatform, IonImg, IonButton } from '@ionic/react';
import { observer } from 'mobx-react';
import React from 'react';
import { CameraPreview } from '@ionic-native/camera-preview';
import WebCam from 'react-webcam';
import { Subscription } from 'rxjs';
import { ScreenOrientation } from '@ionic-native/screen-orientation';
import { t } from 'i18next';

import Step, { WorkflowProps } from './Step';
import '../../theme/pages/packageCapture.css';
import { Redirect } from 'react-router-dom';
import { PathName } from '../../commons/Constants';
import Utils from '../../utils/Utils';
import { ScreenType } from '../../commons/Constants';
import GreetlyIcon from '../../components/GreetlyIcon';

type State = {
  shouldRedirectToUnknownError: boolean;
  shouldShowWarning: boolean;
  shouldShowCamera: boolean;
  isReviewRequired: boolean;
  imageBase64: string | null;
};

class PhotoCapture extends Step<WorkflowProps, State> {
  state: State = {
    shouldRedirectToUnknownError: false,
    shouldShowWarning: false,
    shouldShowCamera: false,
    isReviewRequired: this.props.node.data.is_review_before_submission === true,
    imageBase64: null,
  };

  webCamRef = React.createRef<WebCam & HTMLVideoElement>();
  private screenOrientationChangeSubscription: Subscription | null = null;

  componentDidMount() {
    if (Utils.isNative()) {
      this.screenOrientationChangeSubscription = ScreenOrientation.onChange().subscribe(() =>
        this.restartNativeCamera(),
      );
      this.startNativeCamera();
    } else {
      this.setupMediaDevice()
        .then(() => {
          this.setState({ shouldShowCamera: true });
        })
        .catch(() => {
          this.setState({ shouldShowWarning: true });
        });
    }
  }

  componentWillUnmount() {
    if (Utils.isNative()) {
      this.screenOrientationChangeSubscription?.unsubscribe();

      const root = document.documentElement;

      this.stopNativeCamera();
      root.style.setProperty('--ion-background-color', 'var(--greetly-background-color)');
    }
  }

  startNativeCamera() {
    let width: number | undefined = undefined;
    let height: number | undefined = undefined;
    if (isPlatform('ios')) {
      if (
        ScreenOrientation.type === ScreenOrientation.ORIENTATIONS.LANDSCAPE ||
        ScreenOrientation.type === ScreenOrientation.ORIENTATIONS.LANDSCAPE_PRIMARY ||
        ScreenOrientation.type === ScreenOrientation.ORIENTATIONS.LANDSCAPE_SECONDARY
      ) {
        width = window.screen.height;
        height = window.screen.width;
      }
    }

    CameraPreview.startCamera({
      width: width,
      height: height,
      previewDrag: false,
      tapFocus: false,
      tapPhoto: false,
      camera: 'rear',
      toBack: true,
      disableExifHeaderStripping: window.innerWidth > window.innerHeight,
    }).then(() => {
      const root = document.documentElement;
      root.style.setProperty('--ion-background-color', 'transparent');

      this.setState({ shouldShowCamera: true });
    });
  }

  stopNativeCamera() {
    return CameraPreview.stopCamera();
  }

  restartNativeCamera() {
    this.stopNativeCamera().then(() => {
      this.startNativeCamera();
    });
  }

  _setImageClosePage(imageBase64: string) {
    const { workflowStore } = this.props.appStore;
    workflowStore.setPackageImageBase64(imageBase64);
    workflowStore.goToNextNode();
  }

  takePicture = () => {
    let _finishWithImage = (imageBase64: string) => {
      if (!this.state.isReviewRequired) {
        this._setImageClosePage(imageBase64);
      } else {
        this.stopNativeCamera();
        this.setState({ imageBase64: imageBase64 });
      }
    };

    if (Utils.isNative()) {
      CameraPreview.takePicture({
        quality: 100,
      })
        .then((imageBase64) => {
          if (imageBase64.length === 1) {
            imageBase64 = imageBase64[0];
          }
          _finishWithImage(imageBase64);
        })
        .catch(() => {
          Utils.showErrorToast('An error has occurred');
        });
    } else {
      const imageBase64Url = this.webCamRef.current!.getScreenshot();
      if (imageBase64Url !== null) {
        const imageBase64 = imageBase64Url.substring(imageBase64Url.lastIndexOf(',') + 1, imageBase64Url.length);
        _finishWithImage(imageBase64);
      } else {
        this.setState({ shouldRedirectToUnknownError: true });
      }
    }
  };

  retakePhoto() {
    this.setState({ imageBase64: null });

    if (Utils.isNative()) {
      this.startNativeCamera();
    } else {
      this.setupMediaDevice()
        .then(() => {
          this.setState({ shouldShowCamera: true });
        })
        .catch(() => {
          this.setState({ shouldShowWarning: true });
        });
    }
  }

  private setupMediaDevice = () => {
    return navigator.mediaDevices.getUserMedia({
      video: { facingMode: 'user', height: { ideal: 4096 }, width: { ideal: 2160 } },
    });
  };

  render() {
    if (!Utils.isNative()) {
      if (this.state.shouldRedirectToUnknownError) {
        return <Redirect to={PathName.UNKNOWN_ERROR} />;
      }
    }

    const { locationStore } = this.props.appStore;

    return (
      <IonPage className="packagecapture">
        <IonContent scroll-y="false">
          {this.state.shouldShowWarning ? (
            <>
              <div className="warning">
                {/* @TODO styling */}
                Photo is required. Please allow the app to access the camera and try again.
              </div>
            </>
          ) : (
            <>
              {this.state.isReviewRequired && this.state.imageBase64 != null ? (
                <div className="photo-container">
                  <div>
                    <IonImg className="photo-image" src={'data:image/jpeg;base64, ' + this.state.imageBase64} />
                    <IonButton
                      className="generic-button"
                      onClick={() => this._setImageClosePage(this.state.imageBase64!)}
                      style={{ position: 'absolute', bottom: 0, right: '10%' }}
                    >
                      <div>{t('next')}</div>
                    </IonButton>
                    <IonButton
                      className="generic-button-cancel"
                      onClick={() => this.retakePhoto()}
                      style={{ position: 'absolute', bottom: 0, left: '10%' }}
                    >
                      {this.props.appStore.screenType === ScreenType.Phone && <GreetlyIcon iconName="rotate-left" />}
                      {this.props.appStore.screenType === ScreenType.Tablet && 'RE-TAKE'}
                    </IonButton>
                  </div>
                </div>
              ) : (
                this.state.shouldShowCamera && (
                  <>
                    {!Utils.isNative() && (
                      <div className="cam-cont">
                        <WebCam
                          className="cam-prev"
                          ref={this.webCamRef}
                          audio={false}
                          screenshotFormat="image/jpeg"
                          videoConstraints={{
                            facingMode: 'environment',
                          }}
                        ></WebCam>
                      </div>
                    )}
                    <div className="capture-container">
                      {
                        <div>
                          <IonLabel className="prev-text">
                            {locationStore.tabletTerminologies.packageBlockTitle}
                          </IonLabel>
                          <button className="capture-button" onClick={() => this.takePicture()} />
                        </div>
                      }
                    </div>
                  </>
                )
              )}
            </>
          )}
        </IonContent>
      </IonPage>
    );
  }
}

export default observer(PhotoCapture);
