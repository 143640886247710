import { IonItem, IonLabel, IonCheckbox } from '@ionic/react';
import React from 'react';
import '../theme/components/inputs.css';
import { CheckboxChangeEventDetail } from '@ionic/core/components';
import '../theme/components/checkbox.css';
import i18next, { t } from 'i18next';

interface Props {
  name?: string;
  nameValue?: string;
  checkboxLabel: string;
  isChecked?: boolean;
  isForMultiselect?: boolean;
  onIonChange?: (event: CustomEvent<CheckboxChangeEventDetail>) => void;
  isRequired?: boolean;
  i18nBase?: string;
}

class Checkbox extends React.Component<Props> {
  item = React.createRef<HTMLIonItemElement>();
  checkbox = React.createRef<HTMLIonCheckboxElement>();

  onChange = (e: any) => {
    if (this.props.isRequired) {
      const isChecked = this.checkbox.current!.checked;
      if (isChecked) {
        this.item.current!.classList.remove('warning');
      }
    }

    this.props.onIonChange?.(e);
  };

  validate = () => {
    if (this.props.isRequired) {
      const isChecked = this.checkbox.current!.checked;
      if (!isChecked) {
        this.item.current!.classList.add('warning');
      }
      return isChecked;
    } else {
      return true;
    }
  };

  render() {
    const i18Key = `${this.props.i18nBase}.question`;
    const optionValue = i18next.exists(i18Key) ? t(i18Key) : this.props.checkboxLabel || '';
    return (
      <IonItem ref={this.item} lines="none" className="checkbox">
        <IonLabel>
          {optionValue}
          {this.props.isRequired ? '*' : ''}
        </IonLabel>
        <IonCheckbox
          ref={this.checkbox}
          checked={this.props.isChecked}
          name={this.props.name}
          value={this.props.isForMultiselect ? this.props.nameValue : 'on'}
          onIonChange={this.onChange}
        />
      </IonItem>
    );
  }
}

export default Checkbox;
