import { observable, makeObservable } from 'mobx';
import WorkflowStep from './v2/WorkflowStep';

export default class OutCheckInRecordModel {
  hostId?: number;
  deliverySignatureRequired?: boolean;
  visitorInfoFieldValues?: any;
  checkInOptionId?: number;
  signatureRecordId?: number;
  toPrint?: boolean;
  locationId?: number;
  temperature?: string;
  send_text?: string;
  steps?: Array<WorkflowStep>;
  success_check_in?: boolean;
  old_check_in_record_id?: number;
  device_id?: string | null;
  visitor_photo_attached?: boolean;
  attachments_update?: boolean;

  constructor(checkInOptionId: number) {
    makeObservable(this, {
      visitorInfoFieldValues: observable,
    });

    this.checkInOptionId = checkInOptionId;
  }
}
