import InCheckInRecordModel from '../InCheckInRecordModel';
import WorkflowStep from '../v2/WorkflowStep';

export default class InCheckInRecordAPIModel {
  id?: number;
  check_in_option_id?: number;
  account_id?: number;
  host_id?: number;
  claimed?: boolean;
  checked_out?: boolean;
  check_in_time?: string;
  check_out_time?: string | null;
  visitor_info_fields?: any;
  first_name?: string;
  last_name?: string;
  email?: string;
  pin?: string;
  visitor_photo?: string;
  host_name?: string;
  // company?: string;
  first_time?: boolean;
  visitor_name?: string;
  format_check_in_time?: string;
  format_check_out_time?: string | null;
  check_in_option?: any;
  steps?: Array<WorkflowStep>;

  constructor(inCheckInRecord: InCheckInRecordModel) {
    this.id = inCheckInRecord.id;
    this.check_in_option_id = inCheckInRecord.checkInOptionId;
    this.account_id = inCheckInRecord.accountId;
    this.host_id = inCheckInRecord.hostId;
    this.claimed = inCheckInRecord.claimed;
    this.checked_out = inCheckInRecord.checkedOut;
    this.check_in_time = inCheckInRecord.checkInTime;
    this.check_out_time = inCheckInRecord.checkOutTime;
    this.visitor_info_fields = inCheckInRecord.visitorInfoFieldValues;
    this.first_name = inCheckInRecord.firstName;
    this.last_name = inCheckInRecord.lastName;
    this.email = inCheckInRecord.email;
    this.pin = inCheckInRecord.pin;
    this.visitor_photo = inCheckInRecord.visitorPhoto;
    this.host_name = inCheckInRecord.hostName;
    // this.company = inCheckInRecord.company;
    this.first_time = inCheckInRecord.firstTime;
    this.visitor_name = inCheckInRecord.visitorName;
    this.format_check_in_time = inCheckInRecord.formatCheckInTime;
    this.format_check_out_time = inCheckInRecord.formatCheckOutTime;
    this.check_in_option = inCheckInRecord.checkInOption;
  }
}
