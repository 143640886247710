import React, { useState } from 'react';
import { IonPage, IonContent, IonImg, IonLabel, IonButton } from '@ionic/react';

import '../theme/pages/noParams.css';
import '../theme/pages/login.css';
import NativeStore from '../mobx/NativeStore';
import Loading from '../components/Loading';

interface Props {
  nativeStore: NativeStore;
}
const RELOAD_ATTEMPT_LIMIT = 2;

export const NativeRetry = ({ nativeStore }: Props): JSX.Element => {
  const [reloadAttempts, updateReloadAttempts] = useState(0);
  const [isLoading, setLoadingState] = useState(false);
  const { locationStore } = nativeStore;

  const refetchLocation = async () => {
    setLoadingState(true);
    try {
      await nativeStore.fetchLocation(true);
    } catch (error) {
      // we don't need to captue this.
    } finally {
      setLoadingState(false);
      updateReloadAttempts((count) => count + 1);
    }
  };

  const logout = () => {
    locationStore.resetError();
    nativeStore.logout();
  };

  const allowLogout = reloadAttempts > RELOAD_ATTEMPT_LIMIT;
  const label = allowLogout
    ? 'We may be unable to reload this location, try logging out if this continues.'
    : 'Failed To Load Location';

  return (
    <IonPage className="no-params">
      <div style={{ display: 'none' }}>{process.env.REACT_APP_BUILD_STAGE}</div>
      <IonContent>
        {isLoading && <Loading />}
        <IonImg className="greetly-logo" src="./assets/img/greetly_logo.png" alt="" />
        <IonLabel className="title">{label}</IonLabel>
        <IonButton className="retry-button submit" onClick={refetchLocation}>
          Retry
        </IonButton>
        {allowLogout && (
          <IonButton className="retry-button submit" onClick={logout}>
            Log out and Reset
          </IonButton>
        )}
      </IonContent>
    </IonPage>
  );
};

export default NativeRetry;
