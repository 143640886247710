import React from 'react';
import { IonPage, IonImg, IonText } from '@ionic/react';
import { App } from '@capacitor/app';

import NativeStore from '../mobx/NativeStore';
import Loading from '../components/Loading';

interface Props {
  nativeStore: NativeStore;
}

type State = {
  isTauriAvailable: boolean;
};

class ThermalWaiting extends React.Component<Props, State> {
  timeout: NodeJS.Timeout | null = null;

  state: State = {
    isTauriAvailable: true,
  };

  constructor(props: Props) {
    super(props);

    const { nativeStore } = this.props;

    if (nativeStore.isFromSuccess) {
      nativeStore.isFromSuccess = false;
      App.addListener('appStateChange', (state: any) => {
        const { nativeStore } = this.props;
        if (state.isActive) {
          nativeStore.fetchLocation(true);
        } else {
          if (this.timeout !== null) {
            clearTimeout(this.timeout);
          }
          nativeStore.clearThermalCheckInPollTimeout();
        }
      });
    } else {
      nativeStore.setThermalCheckInPollTimeout();
      this.createTimeout();
    }
  }

  componentWillUnmount() {
    const { nativeStore } = this.props;
    App.removeAllListeners();
    nativeStore.clearThermalCheckInPollTimeout();
    if (this.timeout !== null) {
      clearTimeout(this.timeout);
    }
  }

  createTimeout = () => {
    this.timeout = setTimeout(() => {
      this.timeout = null;
      const { nativeStore } = this.props;
      App.addListener('appStateChange', (state: any) => {
        if (state.isActive) {
          nativeStore.fetchLocation(true);
        } else {
          if (this.timeout !== null) {
            clearTimeout(this.timeout);
          }
          nativeStore.clearThermalCheckInPollTimeout();
        }
      });

      nativeStore.openThermal().catch(() => {
        this.setState({ isTauriAvailable: false });
      });
    }, 10000);
  };

  render() {
    return (
      <IonPage className="welcome">
        <IonImg className="image-welcome" />
        <div className="container">
          <div className="mid-quarter">
            <IonText className="title app-screen-title">Retrieving Temperature.</IonText>
            <IonText className="title app-screen-title">Please Wait.</IonText>
            {!this.state.isTauriAvailable && <IonText>Failed to start thermal camera</IonText>}
            <Loading />
          </div>
        </div>
      </IonPage>
    );
  }
}

export default ThermalWaiting;
