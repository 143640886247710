import Axios, { AxiosResponse } from 'axios';
import ENV from '../env';
import InCheckInRecordModel from '../models/InCheckInRecordModel';
import { observable, computed, action, makeObservable } from 'mobx';
import InCheckInRecordAPIModel from '../models/api/InCheckInRecordAPIModel';
import { Etc } from '../commons/Constants';
import AppStore from './AppStore';
import Utils from '../utils/Utils';
import { getBaseUrl } from '../utils/BaseUrls';

export default class CheckInRecordsStore {
  private _appStore!: AppStore;
  private _isLoading = false;
  private _error: any = null;
  private _checkInRecords: Array<InCheckInRecordModel> = [];
  private _searchStr = '';

  constructor(appStore: AppStore) {
    makeObservable<CheckInRecordsStore, '_isLoading' | '_error' | '_checkInRecords' | '_searchStr'>(this, {
      _isLoading: observable,
      _error: observable,
      _checkInRecords: observable,
      _searchStr: observable,
      isLoading: computed,
      error: computed,
      filteredCheckInRecords: computed,
      searchStr: computed,
      fetch: action,
      setSearchStr: action,
    });

    this._appStore = appStore;
  }

  get isLoading() {
    return this._isLoading;
  }

  get error() {
    return this._error;
  }

  get filteredCheckInRecords() {
    if (this._searchStr.length <= 1) {
      return [];
    }

    return this._checkInRecords.filter((checkInRecord) => {
      if (checkInRecord.firstName != null && checkInRecord.lastName != null) {
        return checkInRecord.visitorName!.match(new RegExp(this._searchStr, 'i')) !== null;
      }

      if (checkInRecord.firstName != null) {
        return checkInRecord.firstName.match(new RegExp(this._searchStr, 'i')) !== null;
      }

      if (checkInRecord.lastName != null) {
        return checkInRecord.lastName.match(new RegExp(this._searchStr, 'i')) !== null;
      }

      if (checkInRecord.email != null) {
        return checkInRecord.email.match(new RegExp(this._searchStr, 'i')) !== null;
      }

      return false;
    });
  }

  get searchStr() {
    return this._searchStr;
  }

  async fetch() {
    this._isLoading = true;
    this._error = null;
    const baseUrl = await getBaseUrl();

    let url;
    if (Utils.isNative()) {
      url = baseUrl + `/api/locations/${this._appStore.locationId}/check_in_records?within_last_day=true`;
    } else {
      url = baseUrl + `/api/check-in-records/?within_last_day=true`;
    }

    return Axios.get(url, {
      headers: {
        ...this._appStore.authenticationHeader,
      },
    })
      .then((res: AxiosResponse<Array<InCheckInRecordAPIModel>>) => {
        this._checkInRecords = res.data.map((checkInRecordAPI) => {
          return new InCheckInRecordModel(checkInRecordAPI);
        });
      })
      .catch((err) => {
        this._error = err;
        console.error(Etc.LOG_PREFIX + JSON.stringify(err));
        throw err;
      })
      .finally(() => {
        this._isLoading = false;
      });
  }

  setSearchStr(searchStr: string) {
    this._searchStr = searchStr;
  }
}
