import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { ThemeType } from '../../commons/Constants';
import WorkflowModel from '../../models/WorkflowModel';
import AppStore from '../../mobx/AppStore';
import WorkflowsDarkBtn from './WorkflowsButtons/Dark';
import WorkflowsModernBtn from './WorkflowsButtons/Modern';
import WorkflowOldBtn from './WorkflowsButtons/Old';
import './workflowsItem.scss';

interface Props {
  workflow: WorkflowModel;
  setWorkflow: (workflowModel: WorkflowModel) => Promise<void | AxiosResponse<any> | undefined>;
  appStore: AppStore;
  totalNumber?: number;
}

function WorkflowsItem(props: Props) {
  const [splitName, setSplitName] = useState([]);
  const { workflow, appStore } = props;
  const iconName = workflow.customIconName ?? workflow.faIconName ?? workflow.iconName;

  const setWorkflow = async () => {
    if (!appStore.previewMode) {
      return props.setWorkflow(workflow);
    }
  };

  const { locationStore } = appStore;
  const themeButton = locationStore.themeButton;

  useEffect(() => {
    const nameLanguage = workflow.namePolyglot[`${appStore.locationStore.language}`];

    setSplitName(nameLanguage ? nameLanguage.split(' ') : workflow.name.split(' '));
  }, [workflow, appStore.locationStore.language]);

  switch (themeButton) {
    case ThemeType.Whimsical:
    case ThemeType.Modern: {
      return (
        <WorkflowsModernBtn
          themeButton={themeButton}
          setWorkflow={setWorkflow}
          label={splitName.join(' ')}
          workflowId={workflow.id.toString()}
          iconName={iconName}
        />
      );
    }
    case ThemeType.Dark: {
      return (
        <WorkflowsDarkBtn
          themeButton={themeButton}
          setWorkflow={setWorkflow}
          label={splitName.join(' ')}
          workflowId={workflow.id.toString()}
          iconName={iconName}
        />
      );
    }
    default: {
      return (
        <WorkflowOldBtn
          themeButton={themeButton}
          setWorkflow={setWorkflow}
          label={splitName.join(' ')}
          workflowId={workflow.id.toString()}
          iconName={iconName}
        />
      );
    }
  }
}

export default WorkflowsItem;
