import React from 'react';
import i18next, { t } from 'i18next';
import { IonButton, IonCol, IonItem, IonLabel } from '@ionic/react';

import { ScreenType, ThemeType } from '../../commons/Constants';
import GreetlyIcon from '../../components/GreetlyIcon';
import '../../theme/design_helper.css';

class DesignHelper {
  static renderThemeDesignTop(theme: string | null, locationLogo: string | null, fillColor?: string | null) {
    return (
      <>
        {this.renderTopWaveDesign(theme, fillColor)}
        {theme !== ThemeType.Old && <div className={`background-back-${theme}`} />}
        <div className={theme === ThemeType.Old ? 'img-container' : 'img-container-new-design'}>
          <img
            className={
              locationLogo
                ? theme === ThemeType.Old
                  ? 'brand-logo'
                  : 'brand-logo-new-design'
                : 'brand-logo opacity-zero'
            }
            alt=""
            src={locationLogo || ''}
          ></img>
        </div>
      </>
    );
  }

  static renderTopWaveDesign(theme: string | null, fillColor?: string | null) {
    return (
      <>
        {theme === ThemeType.Whimsical && (
          <div className="whimsical-wave">
            <svg
              viewBox={'0 0 500 50'}
              preserveAspectRatio={'xMidYMax slice'}
              style={{ height: '100%', width: '100%' }}
            >
              <path
                d="M0,50 C150,50 350,10 500,40 L500,00 L0,0 Z"
                style={{ stroke: 'none', fill: fillColor ?? 'white' }}
              ></path>
            </svg>
          </div>
        )}
      </>
    );
  }

  static renderThemeLabelTitle(
    theme: string | null | undefined,
    title: string | null | Array<string>,
    isButtonPage: boolean = false,
  ) {
    const isTitleString = title === null || typeof title === 'string';
    return (
      <>
        {
          <IonItem
            className={
              theme === ThemeType.Old
                ? 'title app-screen-title'
                : `app-screen-title-new-design ${theme} ${isButtonPage ? 'button-page' : ''}`
            }
            id="title"
            lines="none"
          >
            {isTitleString ? (
              <IonLabel>{title}</IonLabel>
            ) : (
              <IonCol>
                {(title as Array<string>).map((element, i) => {
                  return (
                    <IonLabel className="label-cell" key={i}>
                      {element}
                    </IonLabel>
                  );
                })}
              </IonCol>
            )}
          </IonItem>
        }
      </>
    );
  }

  static renderHomeButton(theme: string | null | undefined, action: () => void) {
    return this.renderTopButton(theme, 'home', 'home', action);
  }

  static renderLanguageButton(theme: string | null | undefined, action: () => void, defaultLanguage: string) {
    return this.renderLanguageBtn(theme, 'language', 'globe', action, defaultLanguage);
  }

  private static renderLanguageBtn(
    theme: string | null | undefined,
    iconType: string,
    iconName: string,
    action: () => void,
    defaultLanguage: string,
  ) {
    const activeLanguage = i18next.language === defaultLanguage ? '' : 'active';
    const isWhimsical = theme === 'whimsical';

    return (
      <>
        <IonButton
          className={`${iconType}-button ${theme}`}
          onClick={(event) => {
            event.stopPropagation();
            action();
          }}
        >
          <div className={`shape-button-back ${theme} ${isWhimsical ? activeLanguage : ''}`}>
            <div className={`shape-button language-btn ${theme} ${isWhimsical ? '' : activeLanguage}`}>
              <GreetlyIcon iconName={iconName}></GreetlyIcon>
            </div>
          </div>
        </IonButton>
      </>
    );
  }

  private static renderTopButton(
    theme: string | null | undefined,
    iconType: string,
    iconName: string,
    action: () => void,
  ) {
    return (
      <>
        <IonButton
          className={`${iconType}-button ${theme}`}
          onClick={(event) => {
            event.stopPropagation();
            action();
          }}
        >
          <div className={`shape-button-back ${theme}`}>
            <div className={`shape-button ${theme}`}>
              <GreetlyIcon iconName={iconName}></GreetlyIcon>
            </div>
          </div>
        </IonButton>
      </>
    );
  }

  static renderNextScreenButton(screenType: ScreenType, onClick?: () => void) {
    return (
      <>
        <IonButton className="generic-button" type={onClick === undefined ? 'submit' : undefined} onClick={onClick}>
          <div>{t('next')}</div>
        </IonButton>
      </>
    );
  }
}

export default DesignHelper;
