import WorkflowAPIModel_v2 from '../api/v2/WorkflowModelAPI_v2';
import WorkflowEdge from './WorkflowEdge';
import WorkflowNode from './WorkflowNode';

//wokflowmodel_v2 is using for new workflow system (nodes and edges)
export default class WorkflowModel_v2 {
  id: number;
  name: string;
  nodes: Record<string, WorkflowNode> = {};
  edges: Array<WorkflowEdge> = [];

  constructor(workflowAPI: WorkflowAPIModel_v2) {
    this.id = workflowAPI.id;
    this.name = workflowAPI.name;
    Object.entries(workflowAPI.nodes).forEach(([key, value]) => (this.nodes[key] = new WorkflowNode(value)));
    this.edges = workflowAPI.edges.map((edge) => new WorkflowEdge(edge));
  }
}
