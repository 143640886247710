import React from 'react';
import { IonPage, IonContent, IonImg, IonLabel } from '@ionic/react';
import '../theme/pages/noParams.css';

class NoConnection extends React.Component {
  render() {
    return (
      <IonPage className="no-params">
        <div style={{ display: 'none' }}>{process.env.REACT_APP_BUILD_STAGE}</div>
        <IonContent>
          <IonImg className="greetly-logo" src="./assets/img/greetly_logo.png" alt="" />
          <IonLabel className="find-interface">No Internet Connection</IonLabel>
        </IonContent>
      </IonPage>
    );
  }
}

export default NoConnection;
