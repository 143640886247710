import PreregAPIModel from './api/PreregAPIModel';

export default class PreregModel {
  enablePrereg: boolean;
  repeatVisitor: boolean;

  constructor(preregAPI: PreregAPIModel) {
    this.enablePrereg = preregAPI.enable_prereg;
    this.repeatVisitor = preregAPI.repeat_visitor;
  }
}
