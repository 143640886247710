import StepModel from '../StepModel';
import WorkflowAPIModel_v2 from './v2/WorkflowModelAPI_v2';
import VisitorInfoFieldAPIModel from './VisitorInfoFieldAPIModel';

export default class WorkflowAPIModel {
  id = 0;
  option_type = '';
  name = '';
  name_polyglot: { [index: string]: any } = {};
  steps: Array<StepModel> = [];
  order = 0;
  icon_name = '';
  fa_icon_name = '';
  custom_icon_name = '';
  photo_capture = false;
  print_badge = false;
  nda_signature = false;
  visitor_info_fields: Array<VisitorInfoFieldAPIModel> = [];
  custom_success_message: string | null = null;
  custom_success_message_polyglot: { [index: string]: any } = {};
  custom_message_for_signature: string | null = null;
  custom_message_for_signature_polyglot: { [index: string]: any } = {};
  nda_body: string | null = null;
  verify_guest: boolean | null = null;
  search_employees = false;
  workflow: WorkflowAPIModel_v2 | null = null;
  use_workflow: boolean = false;
}
