import React, { useEffect, useRef } from 'react';

import GreetlyIcon from '../../../GreetlyIcon';
import { resizeText } from '../WorkflowsBtnHelper';
import '../style.scss';
import './style.scss';

function WorkflowsModernBtn(props: any) {
  const { themeButton, setWorkflow, label, workflowId, iconName } = props;
  const ref: any = useRef(null);

  const labelRef: any = useRef(null);
  const textRef = useRef(null);

  const countSpace = () => {
    resizeText({
      element: labelRef.current,
      parent: textRef.current,
      step: 0.5,
    });
  };

  // Check DeviceRotation
  useEffect(() => {
    window?.screen?.orientation?.addEventListener('change', countSpace);
    window.addEventListener('resize', countSpace);

    return () => {
      window?.screen?.orientation?.removeEventListener('change', countSpace);
      window.removeEventListener('resize', countSpace);
    };
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => countSpace(), 300);
    
    return () => clearTimeout(timeoutId);
  }, [ref?.current?.clientHeight]);

  return (
    <div className={`workflow-item-${themeButton} bottom`} onClick={setWorkflow} ref={ref}>
      <div className="workflow-modern-wrapper">
        <div className="workflow-button bottom" ref={textRef}>
          <div className="icon-block modern-element">
            <div className="ellipse-back">
              <div className="ellipse">
                <GreetlyIcon iconName={iconName} />
              </div>
            </div>
          </div>

          <div className="text-container">
            <div className="workflow-label" id={workflowId}>
              <span className="text label-text" ref={labelRef}>
                {label}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkflowsModernBtn;
