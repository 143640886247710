import { IonPage, IonContent, IonButton } from '@ionic/react';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Subscription } from 'rxjs';
import 'react-player';
import ReactPlayer from 'react-player';

import Step, { WorkflowProps } from './Step';
import '../../theme/pages/videoPlayer.css';
import { PathName } from '../../commons/Constants';
import Utils from '../../utils/Utils';
import Loading from '../../components/Loading';

type State = {
  playing: boolean;
  played: number;
  isLoading: boolean;
  seeking: boolean;
  shouldRedirectToUnknownError: boolean;
};

class VideoPlayer extends Step<WorkflowProps, State> {
  state: State = {
    playing: false,
    played: 0,
    isLoading: true,
    seeking: false,
    shouldRedirectToUnknownError: false,
  };

  private _url: string | null = null;
  private _can_skip: boolean = false;
  private _can_pause: boolean = false;

  constructor(props: WorkflowProps) {
    super(props);

    this._url = props.node.data.url;
    this._can_skip = props.node.data.can_skip;
    this._can_pause = props.node.data.can_pause;
  }

  reactPlayer: ReactPlayer = React.createRef<ReactPlayer>().current!;

  private screenOrientationChangeSubscription: Subscription | null = null;

  componentWillUnmount() {
    if (Utils.isNative()) {
      this.screenOrientationChangeSubscription?.unsubscribe();
      const root = document.documentElement;
      root.style.setProperty('--ion-background-color', 'var(--greetly-background-color)');
    }
  }

  _finish = () => {
    const { workflowStore } = this.props.appStore;
    workflowStore.goToNextNode();
  };

  _playPause() {
    this.setState({ playing: !this.state.playing });
  }

  _handleProgress = (state: any) => {
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState({ played: state.played });
    }
  };

  _handleReady = () => {
    this.setState({ isLoading: false, playing: true });
  };

  render() {
    if (!Utils.isNative()) {
      if (this.state.shouldRedirectToUnknownError) {
        return <Redirect to={PathName.UNKNOWN_ERROR} />;
      }
    }

    return (
      <IonPage className="videoPlayer">
        <IonContent>
          {this.state.isLoading && <Loading cssClass="black-loading-class" />}
          <ReactPlayer
            ref={(ref) => (this.reactPlayer = ref!)}
            className="react-player"
            url={this._url ?? ''}
            width="100%"
            height="100%"
            onReady={this._handleReady}
            onEnded={this._finish}
            playing={this.state.playing}
            playsinline={true}
            controls={false} /*progressInterval={100} onProgress={this._handleProgress}*/
          />
          {this._can_pause && (
            <IonButton
              className="generic-button"
              onClick={() => this._playPause()}
              style={{ position: 'absolute', bottom: 0, left: '10%' }}
            >
              {this.state.playing ? 'PAUSE' : 'PLAY'}
            </IonButton>
          )}
          {this._can_skip && (
            <IonButton
              className="generic-button"
              onClick={() => this._finish()}
              style={{ position: 'absolute', bottom: 0, right: '10%' }}
            >
              {'SKIP'}
            </IonButton>
          )}
        </IonContent>
      </IonPage>
    );
  }
}

export default observer(VideoPlayer);
