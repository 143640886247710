import React from 'react';
import '../theme/components/debug.css';
import Utils from '../utils/Utils';
import AppStore from '../mobx/AppStore';
import NativeStore from '../mobx/NativeStore';
import { Etc, StepId } from '../commons/Constants';
import WorkflowModel from '../models/WorkflowModel';
import StepModel from '../models/StepModel';
import ENV from '../env';
import BadgeUtils, { BadgePrintInterface } from '../utils/BadgeUtils';

interface Props {
  appStore: AppStore;
  close: () => void;
}

type State = {
  status: string;
  imageBase64Url: string;
  stringResult: string;
};

class Debug extends React.Component<Props, State> {
  state: State = {
    status: '',
    imageBase64Url: '',
    stringResult: '',
  };

  logout = () => {
    const nativeStore = this.props.appStore as NativeStore;
    nativeStore.logout();
  };

  reloadLocation = () => {
    const nativeStore = this.props.appStore as NativeStore;
    nativeStore.fetchLocation(true).then(() => {
      this.props.close();
    });
  };

  resetState = () => {
    this.setState({ status: '', imageBase64Url: '', stringResult: '' });
  };

  printBadge = async () => {
    const locationStore = this.props.appStore.locationStore;
    this.resetState();
    this.setState({ status: 'Printing' });

    const badgePrint: BadgePrintInterface = {
      visitorInfoFieldValues: {
        first_name: 'TheFirstName',
        last_name: 'TheLastName',
        company: 'TheCompany',
      },
      hostName: 'TheHostName',
      visitorImgUrl: null,
      companyImgUrl: locationStore.location?.logo
        ? await BadgeUtils.addProxyToURL(encodeURIComponent(locationStore.location.logo))
        : null,
      badgeText: locationStore.location!.badgeSetting.badgeText,
    };

    Utils.printBadge(badgePrint, locationStore!.location!.badgeSetting)
      .then(() => {
        console.debug('Printing successfully');
        this.setState({ status: 'Printing successfully' });
      })
      .catch((err) => {
        console.error(Etc.LOG_PREFIX + JSON.stringify(err));
        this.setState({ status: 'Printing error: ' + err });
      });
  };

  showWelcomePage = () => {
    const nativeStore = this.props.appStore as NativeStore;
    nativeStore.shouldShowWelcomePage = true;
  };

  showPhotoCaptureStep = () => {
    const { workflowStore } = this.props.appStore;
    const workflow = {} as WorkflowModel;
    const photoCaptureStep = new StepModel();
    photoCaptureStep.id = StepId.PHOTO_CAPTURE;
    workflow.steps = [photoCaptureStep];
    workflowStore.setWorkflow(workflow);
  };

  render() {
    return (
      <div className="debug">
        <div>Debug</div>
        <div>{'Status: ' + this.state.status}</div>
        {Utils.isNative() && this.state.imageBase64Url !== '' && (
          <img src={this.state.imageBase64Url} alt={'Badge Preview'}></img>
        )}
        {Utils.isNative() && <div>{'Result: ' + this.state.stringResult}</div>}
        <div>
          {Utils.isNative() && (
            <button
              style={{ display: 'block', padding: '10px', marginTop: '10px', marginBottom: '10px' }}
              onClick={() => this.reloadLocation()}
            >
              Reload Location
            </button>
          )}
          {Utils.isNative() && (
            <button
              style={{ display: 'block', padding: '10px', marginTop: '10px', marginBottom: '10px' }}
              onClick={() => this.logout()}
            >
              Logout
            </button>
          )}
          {Utils.isNative() && (
            <button
              style={{ display: 'block', padding: '10px', marginBottom: '10px' }}
              onClick={() => this.printBadge()}
            >
              Print Badge
            </button>
          )}
          {Utils.isNative() && (
            <button
              style={{ display: 'block', padding: '10px', marginBottom: '10px' }}
              onClick={() => this.showWelcomePage()}
            >
              Show Welcome Page
            </button>
          )}
          <button
            style={{ display: 'block', padding: '10px', marginBottom: '10px' }}
            onClick={() => this.showPhotoCaptureStep()}
          >
            Show Photo Capture Step
          </button>
          <button
            style={{ display: 'block', padding: '10px', marginBottom: '10px' }}
            onClick={() => this.props.close()}
          >
            Close
          </button>
        </div>
      </div>
    );
  }
}

export default Debug;
