import { SelectInterface } from '@ionic/core/components';
import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import React from 'react';

import '../theme/components/select.css';
import i18next, { t } from 'i18next';

interface Props {
  name?: string;
  selectTitle: string;
  selectOptions: Array<any>;
  value?: string;
  onChange?: (value: string) => void;
  isRequired?: boolean;
  i18nBase?: string;
  readOnly?: boolean;
  disabled?: boolean;
}

class Select extends React.Component<Props> {
  item = React.createRef<HTMLIonItemElement>();
  select = React.createRef<HTMLIonSelectElement>();

  shadowRootTimeOut: NodeJS.Timeout | null = null;

  componentWillUnmount = () => {
    if (this.shadowRootTimeOut !== null) {
      clearTimeout(this.shadowRootTimeOut);
    }
  };

  onChange = (e: any) => {
    this.updateShadowRoot();

    if (this.props.isRequired) {
      this.item.current!.classList.remove('warning');
    }

    this.props.onChange?.(e.target.value);
  };

  updateShadowRoot = () => {
    const ionSelect: HTMLElement | null | undefined = document
      .getElementById('select-' + this.props.selectTitle)
      ?.querySelector('ion-select')
      ?.shadowRoot?.querySelector('.select-text');
    if (ionSelect) {
      ionSelect.style.whiteSpace = 'unset';
    } else {
      this.shadowRootTimeOut = setTimeout(this.updateShadowRoot, 0);
    }
  };

  validate = () => {
    if (this.props.isRequired) {
      const isSomethingSelected =
        this.select.current!.value !== undefined &&
        this.select.current!.value !== null &&
        this.select.current!.value !== '';
      if (!isSomethingSelected) {
        this.item.current!.classList.add('warning');
      }
      return isSomethingSelected;
    } else {
      return true;
    }
  };

  render() {
    const intefaceStyle: SelectInterface =
      this.props.selectOptions.find((option) => {
        const text = typeof option === 'string' || option instanceof String ? option : option.label;
        return (text as string).length > 130 ? true : false;
      }) != null
        ? 'alert'
        : 'action-sheet';

    const i18QuestionKey = `${this.props.i18nBase}.question`;
    const selectTitle = i18next.exists(i18QuestionKey) ? t(i18QuestionKey) : this.props.selectTitle;

    const interfaceOptions = {
      header: selectTitle,
    };

    return (
      <IonItem ref={this.item} lines="none" className="select" id={'select-' + this.props.selectTitle}>
        <IonLabel className="name">
          {selectTitle}
          {this.props.isRequired ? '*' : ''}
        </IonLabel>
        <IonSelect
          ref={this.select}
          className="value"
          name={this.props.name}
          value={this.props.value}
          disabled={this.props.disabled}
          placeholder="Select"
          okText="Okay"
          interface={intefaceStyle}
          interfaceOptions={interfaceOptions}
          cancelText="Dismiss"
          onIonChange={(e) => {
            this.onChange(e);
          }}
        >
          {this.props.selectOptions.map((option, index) => {
            const i18Key = `${this.props.i18nBase}.options.${index}`;
            const optionValue = i18next.exists(i18Key) ? t(i18Key) : option.label ?? option ?? '';
            return (
              <IonSelectOption key={index} value={optionValue}>
                {optionValue}
              </IonSelectOption>
            );
          })}
        </IonSelect>
      </IonItem>
    );
  }
}

export default Select;
