import React from 'react';
const greetlyselection = require('../json/greetlyselection.json');

interface Props {
  iconName: string;
  forRef?: React.RefObject<HTMLSpanElement>;
}

type State = {
  typeOfIcon: string;
};

class GreetlyIcon extends React.Component<Props, State> {
  state: State = {
    typeOfIcon: ''
  };

  componentDidMount() {
    this.setIconType();
  }

  setIconType() {
    let isFound = false;
    for (let i = 0; i < greetlyselection.icons.length; ++i) {
      const icon = greetlyselection.icons[i];
      if (icon.properties.name === this.props.iconName) {
        this.setState({ typeOfIcon: 'gr gr-' });
        isFound = true;
        break;
      }
    }

    if (!isFound) {
      this.setState({ typeOfIcon: 'fa fa-' });
    }
  }

  render() {
    const n = 30;
    return (
      <span ref={this.props.forRef} className={'options-icon ' + this.state.typeOfIcon + this.props.iconName}>
        {[...Array(n)].map((e, i) => (
          <span className={'gr path' + (i + 1)} key={i}></span>
        ))}
      </span>
    );
  }
}

export default GreetlyIcon;
