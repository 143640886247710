import { initializeApp } from 'firebase/app';
import { Analytics, getAnalytics, logEvent } from 'firebase/analytics';
import {
  browserName,
  browserVersion,
  deviceType,
  mobileModel,
  osName,
  osVersion,
  mobileVendor,
} from 'react-device-detect';

import AnalyticsDataModel from '../models/AnalyticsData';
import AppStore from './AppStore';

export default class AnalyticsStore {
  private _appStore!: AppStore;
  private _analytics: Analytics;
  private _analyticsData = new AnalyticsDataModel();

  constructor(appStore: AppStore) {
    this._appStore = appStore;
    const firebaseConfig = {
      apiKey: 'AIzaSyA7sfX7ynbxFYvrcvE7crs2bNmND7q2OLg',
      authDomain: 'ionicweb-b08f1.firebaseapp.com',
      databaseURL: 'https://ionicweb-b08f1.firebaseio.com',
      projectId: 'ionicweb-b08f1',
      storageBucket: 'ionicweb-b08f1.appspot.com',
      messagingSenderId: '527807024302',
      appId: '1:527807024302:web:e4aff04e8f9e640832313f',
      measurementId: 'G-8H2018KT46',
    };

    initializeApp(firebaseConfig);
    this._analytics = getAnalytics();

    this._analyticsData.startTimeMillis = Date.now();
    this._analyticsData.browserName = browserName;
    this._analyticsData.browserVersion = browserVersion;
    this._analyticsData.osName = osName;
    this._analyticsData.osVersion = osVersion;
    this._analyticsData.deviceType = deviceType;
    this._analyticsData.mobileModel = mobileModel;
    this._analyticsData.mobileVendor = mobileVendor;

    // Temporarily disabled
    // Geolocation.getCurrentPosition()
    // 	.then((geolocationPosition: GeolocationPosition) => {
    // 		this._analyticsData.location = JSON.stringify({
    // 			longitude: geolocationPosition.coords.longitude,
    // 			latitude: geolocationPosition.coords.latitude
    // 		});
    // 	});
  }

  logEvent() {
    this._analyticsData.totalTimeMillis = Date.now() - this._analyticsData.startTimeMillis;

    logEvent(this._analytics, 'user_flow', this._analyticsData);
  }

  setVisitorInfoFieldValues(visitorInfoFieldValues: any) {
    const keys = Object.keys(visitorInfoFieldValues);
    const analyticsData: any = this._analyticsData;
    for (let i = 0; i < keys.length; ++i) {
      const key = keys[i];
      analyticsData[key] = visitorInfoFieldValues[key];
    }
  }
}
