import DomToImage from "dom-to-image";
import Jimp from 'jimp';

import '../theme/badgePrint.css';
import { Paths } from '../commons/Constants';

import Utils from './Utils';
import ENV from '../env';

import { Preferences as Storage } from '@capacitor/preferences';
import { getNodeServerURL } from "./BaseUrls";

export interface BadgePrintInterface {
  visitorInfoFieldValues: any;
  hostName: string | null;
  visitorImgUrl: string | null;
  companyImgUrl: string | null;
  badgeText: string | null;
  customBadgeHTML?: string | null | undefined;
  workflowName?: string | null | undefined;
  visitorId?: number | null | undefined;
}

export default class BadgeUtils {
  private static KEY_PAPER_TYPE = 'KEY_PAPER_TYPE';
  private static printerPaperTypes = ['62mmRB', '62mmx100mm', '62mm', '29mmx90mm'];
  // create png base64 image
  public static async createBadgeImage(badge: BadgePrintInterface): Promise<string> {
    let badgeFirstName: string;
    if (badge.visitorInfoFieldValues.first_name !== undefined) {
      badgeFirstName = badge.visitorInfoFieldValues.first_name;
    } else if (badge.visitorInfoFieldValues.dl_first_name !== undefined) {
      badgeFirstName = badge.visitorInfoFieldValues.dl_first_name;
    } else {
      badgeFirstName = '';
    }
    let badgeLastName: string;
    if (badge.visitorInfoFieldValues.last_name !== undefined) {
      badgeLastName = badge.visitorInfoFieldValues.last_name;
    } else if (badge.visitorInfoFieldValues.dl_last_name !== undefined) {
      badgeLastName = badge.visitorInfoFieldValues.dl_last_name;
    } else {
      badgeLastName = '';
    }
    let badgeCompany: string;
    if (badge.visitorInfoFieldValues.company !== undefined) {
      badgeCompany = 'Company: ' + badge.visitorInfoFieldValues.company;
    } else {
      badgeCompany = '';
    }

    const badgeHost = badge.hostName ?? '';
    const optionsDate = { year: 'numeric', month: 'numeric', day: 'numeric' } as const;
    const optionsTime = { hour: 'numeric', minute: 'numeric', hour12: true } as const;
    const date = new Date();
    const badgeCheckInDate = date.toLocaleDateString('en-US', optionsDate);
    const badgeCheckInTime = date.toLocaleTimeString('en-US', optionsTime);

    const imageContainerWidth = 200;
    const imageContainerHeight = 200;

    let visitorImg: string;
    let companyImg: string;
    if (badge.customBadgeHTML == null && badge.visitorImgUrl !== null) {
      const visitorJimp = await Jimp.read(badge.visitorImgUrl);
      const visitorImgWidth = visitorJimp.getWidth();
      const visitorImgHeight = visitorJimp.getHeight();
      if (visitorImgWidth > imageContainerWidth || visitorImgHeight > imageContainerHeight) {
        visitorJimp.scaleToFit(imageContainerWidth, imageContainerHeight);
        visitorImg = await visitorJimp.getBase64Async('image/jpeg');
      } else {
        visitorImg = badge.visitorImgUrl;
      }
    } else {
      visitorImg = Paths.IMAGE + '/empty-user-photo.png';
    }
    if (badge.companyImgUrl !== null) {
      companyImg = badge.companyImgUrl;
    } else {
      companyImg = Paths.IMAGE + '/greetly_logo.png';
    }

    const width = '600px';
    const height = '372px';

    const node = document.createElement('div'); //62cm*100cm //372px*600px
    node.style.width = width;
    node.style.height = height;
    node.style.color = 'black';

    const hiddenNode = document.createElement('div');
    hiddenNode.style.width = width;
    hiddenNode.style.height = height;
    hiddenNode.style.position = 'absolute';
    hiddenNode.style.left = '-' + '6000px';

    hiddenNode.appendChild(node);
    if (badge.customBadgeHTML != null) {
      var badgeHTML = Utils.b64DecodeUnicode(badge.customBadgeHTML);
      badgeHTML = await this.addProxyToURL(badgeHTML);
      Object.entries(badge.visitorInfoFieldValues).forEach(([key, value]) => {
        badgeHTML = badgeHTML.replace(Utils.humanizeField(key), value as string);
      });
      node.innerHTML = badgeHTML;
      if (badge.visitorImgUrl != null) {
        let svgElements = node.getElementsByTagName('svg');
        if (svgElements.length > 0) {
          svgElements[0].outerHTML = `<img class='photo-custom-badge' src='${visitorImg}'/>`;
        }
      }
    } else {
      const badgeCheckInDateTime = `${badgeCheckInDate}, ${badgeCheckInTime}`;
      node.innerHTML = `<div class='badge'>
					<img class='logo' src='${companyImg}'/>
					<div class='photo-container'>
						<img class='photo' src='${visitorImg}'/>
					</div>
					<div class='information'>
						<div class='names'>${badgeFirstName}</div>
						<div class='names'>${badgeLastName}</div>
						<div class='company'>${badgeCompany}</div>
						<div class='host'>${badgeHost}</div>
						<div class='date'>${badgeCheckInDateTime}</div>
					</div>
					<div class='text'>${badge.badgeText ? badge.badgeText : ''}</div>
				</div>`;
    }
    document.body.appendChild(hiddenNode);

    return DomToImage.toPng(node).finally(() => {
      document.body.removeChild(hiddenNode);
    });
  }

  public static async addProxyToURL(noProxyString: string): Promise<string> {
    const nodeServerURL = await getNodeServerURL()
    let expression =
      /(https?%3A%2F%2F(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/=]*))/gi; //eslint-disable-line
    const matches = noProxyString.match(expression);
    matches?.forEach((url) => {
      noProxyString = noProxyString.replace(url, nodeServerURL + `/api/proxy/?url=${url}`);
    });
    return noProxyString;
  }

  static getPaperType = async () => {
    return Storage.get({ key: BadgeUtils.KEY_PAPER_TYPE }).then(async (result: any) => {
      if (result.value) {
        return result.value;
      } else {
        return await BadgeUtils.nextPaperType();
      }
    });
  };
  static nextPaperType = async () => {
    const currentType =
      (await Storage.get({ key: BadgeUtils.KEY_PAPER_TYPE })).value ??
      BadgeUtils.printerPaperTypes[0];
    const nextIndex =
      BadgeUtils.printerPaperTypes.findIndex(
        (element) => element === currentType
      ) + 1;
    const nextElement =
      BadgeUtils.printerPaperTypes[
      nextIndex % BadgeUtils.printerPaperTypes.length
      ];
    Storage.set({
      key: BadgeUtils.KEY_PAPER_TYPE,
      value: nextElement,
    });
    return nextElement;
  };
}
