import PrinterManager from './PrinterManager';

export default class PrinterUtils {
  static searchConnectedPrinters(isBluetoothPrinter: boolean) {
    PrinterManager.searchConnectedPrinters({ isBluetoothPrinter: isBluetoothPrinter });
  }

  static async isSearchingPrinters(): Promise<boolean> {
    return PrinterManager.isSearchingPrinters()
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  static async isPrinterConnected(): Promise<boolean> {
    return PrinterManager.isPrinterConnected()
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  static async getInfoConnectedPrinters(): Promise<string> {
    return PrinterManager.getInfoConnectedPrinters()
      .then((data: any) => {
        return data.info;
      })
      .catch((error: any) => {
        return error.message;
      });
  }

  static async resetConnectedPrinters() {
    PrinterManager.resetConnectedPrinters();
  }
}

// private searchConnectedPrinters = async () => {
//     if (Utils.isNative()) {
//       this.setState({ status: "Searching connected printers"});
//       PrinterManager.searchConnectedPrinters()
//       while (
//         await PrinterManager.isSearchingPrinters().isSearchingPrinters &&
//         await PrinterManager.isPrinterConnected() != true ) {

//           await Utils.sleep(1000)
//       }
//       this.setState({ status: await PrinterManager.getInfoConnectedPrinters().info });
//     } else {
//       this.setState({ status: "Searching printers isn't availabe on your device "});
//     }
//   }
