import React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';

import i18n from './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import packageJson from '../package.json';

import { defineCustomElements } from '@ionic/pwa-elements/loader';

console.log('%c App version: %s', 'background: #222; color: #bada55; font-size: 1.3rem;', packageJson.version);

// const root = ReactDOM.createRoot(document.getElementById('root'));

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
);

defineCustomElements(window);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
