import { IonImg } from '@ionic/react';
import React from 'react';
import '../theme/components/banner_full.css';
import AppStore from '../mobx/AppStore';

interface Props {
  appStore: AppStore;
  src?: string | null;
}

class Banner extends React.Component<Props> {
  public static defaultProps = {
    src: null,
  };

  render() {
    const { locationStore } = this.props.appStore;

    return (
      <div className="banner-img-cont-full">
        <IonImg className="banner-img-full" src={this.props.src || locationStore.location!.theme.bannerImage || ''} />
        {/* <div className="gradient-cont" />
				<div className="gradient-img" /> */}
      </div>
    );
  }
}

export default Banner;
