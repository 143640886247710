import { IonContent, IonPage, IonText, isPlatform } from '@ionic/react';
import React from 'react';
import { Browser } from '@capacitor/browser';

import Step, { StepProps } from './Step';
import { VisitorInfoFieldType } from '../../commons/Constants';
import Banner from '../../components/Banner';
import Inputs from '../../components/Inputs';
import '../../theme/pages/visitor.css';
import { observer } from 'mobx-react';
import Checkbox from '../../components/Checkbox';
import MultiSelect from '../../components/MultiSelect';
import Toggle from '../../components/Toggle';
import RadioSelect from '../../components/RadioSelect';
import VisitorInfoFieldModel from '../../models/VisitorInfoFieldModel';
import Select from '../../components/Select';
import Utils from '../../utils/Utils';
import DriverLicenseDataModel from '../../models/DriverLicenseDataModel';
import SignatureEmbedded from '../../components/SignatureEmbedded';
import StarRating from '../../components/StarRating';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import parsePhoneNumber, { getCountryCallingCode, isPossiblePhoneNumber } from 'libphonenumber-js';
import { CountryCode } from 'libphonenumber-js';
import DesignHelper from './DesignHelper';

const FIRST_NAME = 'first_name';
const LAST_NAME = 'last_name';
const PHONE_NUMBER: string = 'phone_number';
const EMAIL = 'email';
const COMPANY = 'company';
const EMPTY_PHONE_NUMBER = '0000000';
const DL_DOB = 'dl_dob';

class Visitor extends Step<StepProps> {
  fieldRefs = new Array<any>();
  private countryCode: CountryCode = Utils.searchCountryCodeByCallingCode(
    this.props.appStore.locationStore.location?.settings?.countryCode,
  );

  constructor(props: StepProps) {
    super(props);

    const { workflowStore } = this.props.appStore;
    if (workflowStore.checkInRecord!.visitorInfoFieldValues === undefined) {
      workflowStore.checkInRecord!.visitorInfoFieldValues = {};
    }
  }

  _fillPhoneNumberIfNeed = () => {
    const { workflowStore } = this.props.appStore;
    workflowStore.workflow!.visitorInfoFields.forEach((visitorInfoFields) => {
      if (visitorInfoFields.label === PHONE_NUMBER) {
        const phoneNumber = workflowStore.checkInRecord!.visitorInfoFieldValues[PHONE_NUMBER];
        if (phoneNumber === undefined) {
          workflowStore.checkInRecord!.visitorInfoFieldValues[PHONE_NUMBER] = EMPTY_PHONE_NUMBER;
        }
      }
    });
  };

  _visitorInfoFieldValuesJSON(visitorInfoFieldValues: any): { [index: string]: any } {
    const visitorFieldJSON: { [index: string]: any } = {};
    Object.entries(visitorInfoFieldValues).forEach(([key, value]) => (visitorFieldJSON[key] = value));
    return visitorFieldJSON;
  }

  goToNextStep = () => {
    const { analyticsStore, workflowStore } = this.props.appStore;
    analyticsStore.setVisitorInfoFieldValues(workflowStore.checkInRecord!.visitorInfoFieldValues!);
    workflowStore.workflow?.useAlternativeWorkflow == true
      ? workflowStore.goToNextNode(
          this._visitorInfoFieldValuesJSON(workflowStore.checkInRecord!.visitorInfoFieldValues!),
        )
      : workflowStore.goToNextStep();
  };

  _getLabelName = (visitorInfoField: VisitorInfoFieldModel, defaultValue: string) => {
    const { locationStore } = this.props.appStore;
    let visitorInfolabelPolyglotLength = Object.keys(visitorInfoField.labelPolyglot).length;
    const displayLabelOrFieldLabel =
      (visitorInfoField.displayLabel && visitorInfoField.displayLabel.length > 0
        ? visitorInfoField.displayLabel
        : visitorInfoField.label) || defaultValue;
    let label: string =
      visitorInfolabelPolyglotLength === 0
        ? displayLabelOrFieldLabel
        : visitorInfoField.labelPolyglot[`${locationStore.language}`] || defaultValue;
    if (label.replace(' ', '').toLocaleLowerCase() === visitorInfoField.label.replace('_', '').toLocaleLowerCase()) {
      label = displayLabelOrFieldLabel;
    }
    return label;
  };

  createVisitorInfoFieldComponent = (visitorInfoField: VisitorInfoFieldModel, index: number) => {
    const { locationStore, workflowStore } = this.props.appStore;

    // variables for conditions
    const visitorDataType = visitorInfoField.dataType;
    const startDl = visitorDataType?.match(/^dl_/i);

    const readonlyOffForDL = [
      'dl_street_address1',
      'dl_street_address2',
      'dl_city',
      'dl_jurisdiction_code',
      'dl_city_state',
      'dl_address',
    ].some((type) => type === visitorDataType);

    switch (visitorInfoField.label) {
      case FIRST_NAME:
        visitorInfoField = {
          ...visitorInfoField,
          displayLabel: this._getLabelName(visitorInfoField, 'First Name'),
          dataType: VisitorInfoFieldType.TEXT,
        };
        break;
      case LAST_NAME:
        visitorInfoField = {
          ...visitorInfoField,
          displayLabel: this._getLabelName(visitorInfoField, 'Last Name'),
          dataType: VisitorInfoFieldType.TEXT,
        };
        break;
      case EMAIL:
        visitorInfoField = {
          ...visitorInfoField,
          displayLabel: this._getLabelName(visitorInfoField, 'Email'),
          dataType: VisitorInfoFieldType.EMAIL,
        };
        break;
      case PHONE_NUMBER:
        visitorInfoField = {
          ...visitorInfoField,
          displayLabel: this._getLabelName(visitorInfoField, 'Phone Number'),
          dataType: VisitorInfoFieldType.PHONE,
        };
        break;
      case COMPANY:
        visitorInfoField = {
          ...visitorInfoField,
          displayLabel: this._getLabelName(visitorInfoField, 'Company'),
          dataType: VisitorInfoFieldType.TEXT,
        };
        break;
      default: {
        visitorInfoField = {
          ...visitorInfoField,
          // condition
          displayLabel: this._getLabelName(visitorInfoField, ''),
        };
      }
    }

    let label = visitorInfoField.label.replace(' ', '_').toLowerCase();
    let value = workflowStore.checkInRecord!.visitorInfoFieldValues[label];
    let isRequired: boolean;
    let readOnly: boolean;
    let disable: boolean = false;
    const passwordProtected = visitorInfoField.passwordProtected;

    if (visitorDataType && (visitorDataType.match(/\bbarcode\b|\bdl_license_no\b/i) || startDl)) {
      // starts with dl_
      isRequired = true && visitorDataType !== 'dl_street_address2';
      readOnly =
        !workflowStore.isDLScanSkipped &&
        DriverLicenseDataModel.visitorInfoFieldDLDataKeyMap[visitorDataType] !== undefined;
    } else {
      isRequired = true;
      readOnly = false;
    }

    const displayLabel = Utils.humanizeField(visitorInfoField.displayLabel);
    if (
      Utils.isNative() &&
      visitorInfoField.label.toLocaleLowerCase() === 'temperature' &&
      locationStore.location!.isThermalCheckInEnabled
    ) {
      readOnly = true;
      disable = true;
    }

    const autofocusEnable = index === 0 && value == null;

    switch (visitorInfoField.dataType) {
      case VisitorInfoFieldType.TEXT:
        return (
          <Inputs
            key={visitorInfoField.label + index}
            type={passwordProtected ? 'password' : 'text'}
            inputMode={'text'}
            minlength={0}
            maxlength={50}
            forPlaceholder={displayLabel}
            forIcon="id-card-o"
            isRequired={isRequired}
            readonly={readOnly}
            disabled={disable}
            value={value}
            autofocus={autofocusEnable}
            onChange={(e) =>
              (workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label] = (
                e.target as HTMLInputElement
              ).value)
            }
          />
        );

      case VisitorInfoFieldType.EMAIL:
        return (
          <Inputs
            key={visitorInfoField.label + index}
            type={passwordProtected ? 'password' : 'email'}
            inputMode={'email'}
            minlength={0}
            maxlength={50}
            forPlaceholder={displayLabel}
            forIcon="envelope-o"
            isRequired
            readonly={readOnly}
            disabled={disable}
            value={value}
            autofocus={autofocusEnable}
            onChange={(e) =>
              (workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label] = (
                e.target as HTMLInputElement
              ).value)
            }
          />
        );

      case VisitorInfoFieldType.PHONE:
        return (
          <div className="center-box">
            <PhoneInput
              international
              className="country-code"
              defaultCountry={this.countryCode}
              onChange={(value) => value}
              onCountryChange={(countryCode) => {
                this.countryCode = countryCode!;
                workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label] = `+${getCountryCallingCode(
                  this.countryCode,
                )} `;
              }}
            />
            <Inputs
              key={visitorInfoField.label + index}
              ref={(el) => this.fieldRefs.push(el!)}
              defaultValue={
                !value && !workflowStore.isDLScanSkipped ? `+${getCountryCallingCode(this.countryCode)} ` : undefined
              } // set default phone_number if Drivers License scaned
              type={passwordProtected ? 'password' : 'tel'}
              inputMode={'tel'}
              minlength={7}
              maxlength={20}
              forPlaceholder={displayLabel}
              forIcon="phone"
              isRequired={workflowStore?.workflow?.useAlternativeWorkflow == true ? isRequired : false}
              readonly={readOnly}
              disabled={disable}
              value={value}
              autofocus={autofocusEnable}
              onChange={(e) => {
                workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label] = parsePhoneNumber(
                  (e.target as HTMLInputElement).value,
                  this.countryCode,
                )?.format('INTERNATIONAL');
              }}
            />
          </div>
        );

      case VisitorInfoFieldType.NUMBER:
        return (
          <Inputs
            key={visitorInfoField.label + index}
            type={passwordProtected ? 'password' : 'number'}
            inputMode={'decimal'}
            minlength={0}
            maxlength={50}
            forPlaceholder={displayLabel}
            forIcon="hashtag"
            isRequired
            value={value}
            autofocus={autofocusEnable}
            onKeyPress={(e) => {
              const pattern = /[0-9.,]/;
              let inputChar = String.fromCharCode(e.charCode);

              if (!pattern.test(inputChar)) {
                // invalid character, prevent input
                e.preventDefault();
              }
            }}
            onChange={(e) =>
              (workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label] = (
                e.target as HTMLInputElement
              ).value)
            }
          />
        );

      case DL_DOB:
      case VisitorInfoFieldType.DATE:
        return (
          <Inputs
            key={visitorInfoField.label + index}
            type={passwordProtected ? 'password' : 'date'}
            forPlaceholder={displayLabel}
            forIcon="calendar"
            isRequired
            disabled={readOnly}
            value={value}
            autofocus={index === 0}
            onChange={(e) =>
              (workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label] = (
                e.target as HTMLInputElement
              ).value)
            }
          />
        );

      case VisitorInfoFieldType.CHECKBOX:
        return (
          <Checkbox
            key={visitorInfoField.label + index}
            ref={(el) => this.fieldRefs.push(el!)}
            checkboxLabel={displayLabel}
            isRequired
            isChecked={value === 'yes'}
            onIonChange={(e) =>
              (workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label] = e.detail.checked
                ? 'yes'
                : 'no')
            }
          />
        );

      case VisitorInfoFieldType.MULTISELECT:
        return (
          <MultiSelect
            key={visitorInfoField.label + index}
            ref={(el) => this.fieldRefs.push(el!)}
            multiTitle={displayLabel}
            multiOptions={visitorInfoField.options}
            values={value}
            isRequired
            onChange={(values) =>
              (workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label] = values)
            }
          />
        );

      case VisitorInfoFieldType.TOGGLE:
        return (
          <Toggle
            key={visitorInfoField.label + index}
            ref={(el) => this.fieldRefs.push(el!)}
            toggleLabel={displayLabel}
            checked={value === 'yes'}
            onChange={(checked) =>
              (workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label] = checked ? 'yes' : 'no')
            }
          />
        );

      case VisitorInfoFieldType.RADIO_SELECT:
        return (
          <RadioSelect
            key={visitorInfoField.label + index}
            ref={(el) => this.fieldRefs.push(el!)}
            radioTitle={displayLabel}
            radioOptions={visitorInfoField.options}
            value={value}
            isRequired
            onChange={(value) => (workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label] = value)}
          />
        );

      case VisitorInfoFieldType.SELECT:
        return (
          <Select
            key={visitorInfoField.label + index}
            ref={(el) => this.fieldRefs.push(el!)}
            selectTitle={displayLabel}
            selectOptions={visitorInfoField.options}
            value={value}
            isRequired
            onChange={(value) => (workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label] = value)}
          />
        );

      case VisitorInfoFieldType.SIGNATURE:
        return <SignatureEmbedded signatureText={'Signature'} clearText={'Clear'} />;

      case VisitorInfoFieldType.RATING:
        return (
          <StarRating
            key={visitorInfoField.label + index}
            ref={(el) => this.fieldRefs.push(el!)}
            ratingLabel={displayLabel}
            isRequired={isRequired}
            onChange={(value) => (workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label] = value)}
          />
        );

      // case VisitorInfoFieldType.SLIDER: return <SliderGreetly key={visitorInfoField.label + index}
      // ref={(el) => this.fieldRefs.push(el!)}
      // sliderLabel={displayLabel}
      // onChange={(value) => workflowStore.checkInRecord!
      // 	.visitorInfoFieldValues[visitorInfoField.label] = value} />;

      default:
        return (
          <Inputs
            key={visitorInfoField.label + index}
            type={passwordProtected ? 'password' : 'text'}
            inputMode={'text'}
            forPlaceholder={displayLabel}
            forIcon=""
            value={value}
            isRequired={isRequired}
            readonly={readonlyOffForDL ? false : readOnly}
            disabled={disable} // for situations if the address is different from what is on Drivers License
            autofocus={autofocusEnable}
            onChange={(e) =>
              (workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label] = (
                e.target as HTMLInputElement
              ).value)
            }
          />
        );
    }
  };

  onSubmit = (e: any) => {
    e.preventDefault();

    let isValid = true;
    for (let i = 0; i < this.fieldRefs.length; ++i) {
      const fieldRef = this.fieldRefs[i];
      if (fieldRef !== null && fieldRef.validate) {
        if (fieldRef.props.type != 'tel' && !fieldRef.validate()) {
          isValid = false;
        } else if (
          fieldRef.props.type == 'tel' &&
          !fieldRef.validate(() => isPossiblePhoneNumber(fieldRef.props.value ?? '', this.countryCode))
        ) {
          isValid = false;
        }
      }
    }

    if (isValid) {
      this._fillPhoneNumberIfNeed();
      this.goToNextStep();
    }
  };

  goLinkInBrowser = async () => {
    let inAppBrowserRef: any;
    function loadStopCallBack() {
      if (inAppBrowserRef !== undefined) {
        inAppBrowserRef.insertCSS({
          code: 'button.navbar-toggle.collapsed {display: none;}.main-content {padding-top: 8rem!important;}',
        });
        inAppBrowserRef.show();
      }
    }
    const url = 'https://www.greetly.com/privacy-policy';
    const target = '_blank';
    let options = 'hideurlbar=yes,hidenavigationbuttons=yes,hidden=yes,beforeload=yes';
    if (isPlatform('ios') || isPlatform('ipad')) {
      options = 'location=no,hidenavigationbuttons=yes';
    }

    //@ts-ignore
    inAppBrowserRef = await Browser.open(url, target, options);
    inAppBrowserRef.addEventListener('loadstop', loadStopCallBack);
  };

  getPreRegMessage = () => {
    const { appStore } = this.props;
    const { workflowStore, locationStore } = appStore;
    const { preRegCheckInRecord } = workflowStore;

    return `Please register to visit ${preRegCheckInRecord!.host_name} on ${preRegCheckInRecord!.event_start_time} at ${
      locationStore.location!.name
    }`;
  };

  render() {
    const { appStore } = this.props;
    const { locationStore, workflowStore } = appStore;
    const { location } = locationStore;

    const themeButton = locationStore.themeButton;
    const themeMain = locationStore.themeMain;

    this.fieldRefs = [];
    return (
      <IonPage className="visitor">
        <IonContent>
          <Banner {...this.props} />
          <div className="img-container">
            <img
              className={locationStore.location?.logo ? 'brand-logo' : 'brand-logo opacity-zero'}
              alt=""
              src={locationStore.location?.logo || ''}
            ></img>
          </div>
          {Utils.isNative() && DesignHelper.renderHomeButton(themeButton, () => workflowStore.reset(true))}
          {DesignHelper.renderThemeLabelTitle(themeMain, locationStore.tabletTerminologies.whoIsVisiting)}
          <form onSubmit={(e) => this.onSubmit(e)}>
            {workflowStore.workflow!.visitorInfoFields.map((visitorInfoFields, i) =>
              this.createVisitorInfoFieldComponent(visitorInfoFields, i),
            )}
            {DesignHelper.renderNextScreenButton(appStore.screenType)}
          </form>
          {location!.gdpr === '1' && (
            <div className="gdpr-cont">
              <IonText className="gdpr-area">{locationStore.tabletTerminologies.gdpr}</IonText>
              {/* <PrivacyPolicy {...this.props}/> */}
              {!isPlatform('desktop') && !isPlatform('mobileweb') && (isPlatform('android') || isPlatform('ios')) ? (
                <IonText
                  className="provacy-poly-visit"
                  onClick={() => {
                    this.goLinkInBrowser();
                  }}
                >
                  Privacy Policy
                </IonText>
              ) : (
                <a href="https://www.greetly.com/privacy-policy">Privacy Policy</a>
              )}
            </div>
          )}
          <div className="end-block hide" id="endBlock"></div>
        </IonContent>
      </IonPage>
    );
  }
}

export default observer(Visitor);
