import { IonButton, IonContent, IonText } from '@ionic/react';
import React, { useEffect, useRef } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import Rollbar from 'rollbar';
import WorkflowStore from '../mobx/WorkflowStore';
import Utils from '../utils/Utils';
import '../theme/pages/ErrorFallback.css';

const RollbarConfig: Rollbar.Configuration = {
  enabled: !!process.env.REACT_APP_ROLLBAR_TOKEN,
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  payload: {
    environment: process.env.NODE_ENV,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: process.env.CODE_VERSION,
      },
    },
  },
};

export const rollbarHandler = (config: Omit<Rollbar.Configuration, 'enabled' | 'accessToken'> = {}) =>
  new Rollbar({ ...RollbarConfig, ...config });

export const useRollbar = (deps?: React.DependencyList) => {
  const rollbar = useRef<Rollbar>(rollbarHandler());

  useEffect(() => {
    rollbar.current = rollbarHandler();
    // eslint-disable-next-line
  }, deps);

  return rollbar.current;
};

const DefaultErrorFallback = (props: FallbackProps): JSX.Element => (
  <IonContent className="error-fallback">
    <div className="error-fallback-header">
      <IonText color="danger">There was an error loading this screen.</IonText>
      <div className="error-fallback-button">
        <IonButton onClick={props.resetErrorBoundary} color="danger">
          Start Over
        </IonButton>
      </div>
    </div>
  </IonContent>
);

interface Props {
  workflowStore: WorkflowStore;
  resetErrorBoundary?: () => void;
}

export const RollbarErrorHandler = ({
  children,
  workflowStore,
  resetErrorBoundary,
}: React.PropsWithChildren<Props>): JSX.Element => {
  const rollbar = useRollbar([children]);

  const onError = (error: Error, info: { componentStack: string }) => {
    const location = workflowStore.locationInfo;

    rollbar
      .configure({
        payload: {
          context: {
            location_id: location.id,
            location_name: location.name,
            screen: workflowStore.workflow?.useAlternativeWorkflow ? workflowStore.node?.id : workflowStore.step?.id,
            componentErrorStack: info.componentStack,
          },
        },
      })
      .error(error, () => {
        Utils.showErrorToast('There was an error loading this screen.');
      });
  };

  const onReset = () => {
    resetErrorBoundary ? resetErrorBoundary() : workflowStore.reset(true);
  };

  return (
    <ErrorBoundary FallbackComponent={DefaultErrorFallback} onError={onError} onReset={onReset}>
      {children}
    </ErrorBoundary>
  );
};
