import VisitorInfoFieldAPIModel from './api/VisitorInfoFieldAPIModel';

export default class VisitorInfoFieldModel {
  order: number;
  label: string;
  labelPolyglot: { [index: string]: any } = {};
  displayLabel: string;
  dataType: string | null;
  options: Array<string> = [];
  data?: any;
  required: boolean;
  passwordProtected: boolean;
  id?: number;
  formId?: number;

  constructor(visitorInfoFieldAPI: VisitorInfoFieldAPIModel) {
    this.order = visitorInfoFieldAPI.order;
    this.label = visitorInfoFieldAPI.label ?? '';
    this.labelPolyglot = visitorInfoFieldAPI.label_polyglot;
    this.displayLabel = visitorInfoFieldAPI.display_label;
    this.dataType = visitorInfoFieldAPI.data_type;
    this.options = visitorInfoFieldAPI.options;
    this.data = visitorInfoFieldAPI.data;
    this.required = visitorInfoFieldAPI.required ?? true;
    this.passwordProtected = visitorInfoFieldAPI.passwordProtected ?? false;
    this.id = visitorInfoFieldAPI?.id;
    this.formId = visitorInfoFieldAPI?.formId;
  }
}
