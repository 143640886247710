import React, { useEffect, useRef, useState } from 'react';

import GreetlyIcon from '../../../GreetlyIcon';
import { resizeText } from '../WorkflowsBtnHelper';
import '../style.scss';
import './style.scss';

function WorkflowOldBtn(props: any) {
  const { setWorkflow, label, iconName, workflowId } = props;
  const ref: any = useRef(null);

  const labelRef: any = useRef(null);
  const textRef = useRef(null);

  const [parentSize, setParentSize] = useState({ width: 0, height: 0 });

  const countSpace = () => {
    resizeText({
      element: labelRef.current,
      parent: textRef.current,
      step: 0.25,
    });
  };

  useEffect(() => {
    window?.screen?.orientation?.addEventListener('change', countSpace);
    window.addEventListener('resize', countSpace);

    return () => {
      window?.screen?.orientation?.removeEventListener('change', countSpace);
      window.removeEventListener('resize', countSpace);
    };
  }, []);

  // Update font size based on parentSize
  useEffect(() => {
    setTimeout(() => countSpace(), 100);
  }, [parentSize, ref?.current?.clientHeight]);

  return (
    <div className="workflow-item old-theme bottom" onClick={setWorkflow} ref={ref}>
      <div className="workflow-button">
        <div className="text-container workflow-label" id={workflowId} ref={textRef}>
          <span className="text label-text" ref={labelRef}>
            {label}
          </span>
        </div>
        <GreetlyIcon iconName={iconName} />
      </div>
      <div className="icon-shadow">
        <GreetlyIcon iconName={iconName} />
      </div>
    </div>
  );
}

export default WorkflowOldBtn;
