import React, { useCallback, useEffect, useState } from 'react';
import { IonPage, IonImg, IonItem, IonLabel, IonText, IonToggle } from '@ionic/react';
import { Preferences as Storage } from '@capacitor/preferences';

import NativeStore from '../mobx/NativeStore';
import Loading from '../components/Loading';
import { Paths, Etc } from '../commons/Constants';
import '../theme/pages/login.css';

export const KEY_IS_EU = 'is_eu';

interface Props {
  nativeStore: NativeStore;
}

type State = {
  isLoading: boolean;
  appVersion: string;
};

function Login(props: Props) {
  const [appVersion, setAppVersion] = useState('0');
  const [isLoading, setIsLoading] = useState(false);
  const [euInstance, setEuInstance] = useState(false);
  const { nativeStore } = props;
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    nativeStore.tryToLogin().catch(() => {
      setIsLoading(false);
    });
    setAppVersion(nativeStore.appVersion);
  }, [nativeStore]);

  const submitLogin = useCallback((e: any) => {
    e.preventDefault();
    login(e.currentTarget[0].value);
  }, []);

  const onCodeChange = useCallback((e: any) => {
    const code = e.target.value;
    if (code.length === 5) {
      login(code);
    }
  }, []);

  const handleToggleChange = async () => {
    const isEu = !euInstance;
    setEuInstance(isEu);
    await Storage.set({
      key: KEY_IS_EU,
      value: isEu ? 'true' : '',
    });
  };

  const login = useCallback((code: string) => {
    setIsLoading(true);
    nativeStore.login(code.toLowerCase()).catch((err) => {
      console.error(Etc.LOG_PREFIX + JSON.stringify(err));
      setIsLoading(false);

      document.getElementById('wrongCode')?.classList.remove('hide');

      const codeInput: any = document.getElementById('codeInput');
      codeInput.reset();
    });
  }, []);

  useEffect(() => {
    const getIsEU = async () => {
      const isEU = await Storage.get({ key: KEY_IS_EU });
      setEuInstance(isEU.value === 'true');
    };
    getIsEU();
  })

  return (
    <IonPage className="login">
      <div className="container">
        <IonImg className="img" src={Paths.IMAGE + '/greetly_logo.png'} />
        <IonText className="title">Enter pin code </IonText>
        <div className="login-pin-cont">
          <form id="codeInput" className="form" onSubmit={submitLogin}>
            <div className="pin-container">
              <input
                spellCheck={false}
                name="code"
                placeholder=""
                required={true}
                maxLength={5}
                onChange={(e) => onCodeChange(e)}
              />
            </div>
            <div className="wrongCode hide" id="wrongCode">
              Wrong Code. Please Try Again
            </div>
          </form>
        </div>


          <IonItem>
            <IonToggle checked={euInstance} onIonChange={handleToggleChange}></IonToggle>
            <IonLabel> This is an EU instance </IonLabel>
          </IonItem>


        <IonItem lines="none" className="copyright">
          <IonLabel>Copyright © {currentYear} Greetly v.{appVersion}</IonLabel>
        </IonItem>
      </div>
      {isLoading && <Loading />}
    </IonPage>
  );
}

export default Login;
