import InCheckInRecordAPIModel from './api/InCheckInRecordAPIModel';

export default class InCheckInRecordModel {
  id?: number;
  checkInOptionId?: number;
  accountId?: number;
  hostId?: number;
  claimed?: boolean;
  checkedOut?: boolean;
  checkInTime?: string;
  checkOutTime?: string | null;
  visitorInfoFieldValues?: any;
  firstName?: string;
  lastName?: string;
  email?: string;
  pin?: string;
  visitorPhoto?: string;
  hostName?: string;
  // company?: string;
  firstTime?: boolean;
  visitorName?: string;
  formatCheckInTime?: string;
  formatCheckOutTime?: string | null;
  checkInOption?: any;

  constructor(InCheckInRecordAPI: InCheckInRecordAPIModel) {
    this.id = InCheckInRecordAPI.id;
    this.checkInOptionId = InCheckInRecordAPI.check_in_option_id;
    this.accountId = InCheckInRecordAPI.account_id;
    this.hostId = InCheckInRecordAPI.host_id;
    this.claimed = InCheckInRecordAPI.claimed;
    this.checkedOut = InCheckInRecordAPI.checked_out;
    this.checkInTime = InCheckInRecordAPI.check_in_time;
    this.checkOutTime = InCheckInRecordAPI.check_out_time;
    this.visitorInfoFieldValues = InCheckInRecordAPI.visitor_info_fields;
    this.firstName = InCheckInRecordAPI.first_name;
    this.lastName = InCheckInRecordAPI.last_name;
    this.email = InCheckInRecordAPI.email;
    this.pin = InCheckInRecordAPI.pin;
    this.visitorPhoto = InCheckInRecordAPI.visitor_photo;
    this.hostName = InCheckInRecordAPI.host_name;
    // this.company = InCheckInRecordAPI.company;
    this.firstTime = InCheckInRecordAPI.first_time;
    this.visitorName = InCheckInRecordAPI.visitor_name;
    this.formatCheckInTime = InCheckInRecordAPI.format_check_in_time;
    this.formatCheckOutTime = InCheckInRecordAPI.format_check_out_time;
    this.checkInOption = InCheckInRecordAPI.check_in_option;
  }
}
