import { IonContent, IonPage, IonText, isPlatform } from '@ionic/react';
import React from 'react';
import { Browser } from '@capacitor/browser';
import { toJS } from 'mobx';
import PhoneInput from 'react-phone-number-input';
import parsePhoneNumber, { getCountryCallingCode, isPossiblePhoneNumber, CountryCode } from 'libphonenumber-js';
import i18next, { t } from 'i18next';

import Step, { WorkflowProps } from './Step';
import { VisitorInfoFieldType_v2 } from '../../commons/Constants';
import Banner from '../../components/Banner';
import Inputs from '../../components/Inputs';
import '../../theme/pages/visitor.css';
import { observer } from 'mobx-react';
import Checkbox from '../../components/Checkbox';
import MultiSelect from '../../components/MultiSelect';
import Toggle from '../../components/Toggle';
import RadioSelect from '../../components/RadioSelect';
import VisitorInfoFieldModel from '../../models/VisitorInfoFieldModel';
import Select from '../../components/Select';
import Utils from '../../utils/Utils';
import DriverLicenseDataModel from '../../models/DriverLicenseDataModel';
import SignatureEmbedded from '../../components/SignatureEmbedded';
import StarRating from '../../components/StarRating';
import SliderGreetly from '../../components/Slider';
import WorkflowNode from '../../models/v2/WorkflowNode';
import URL_Field from '../../components/URL_Field';
import DesignHelper from './DesignHelper';
import Header from '../../components/Header';
import TimePicker from '../../components/TimePicker/TimePicker';
import 'react-phone-number-input/style.css';

const PHONE_NUMBER: string = 'phone_number';
const EMAIL = 'email';
const EMPTY_PHONE_NUMBER = '0000000';
const DL_DOB = 'dl_dob';

class Visitor_v2 extends Step<WorkflowProps> {
  fieldRefs = new Array<any>();
  scanned_data: any = {};
  private countryCode: CountryCode = Utils.searchCountryCodeByCallingCode(
    this.props.appStore.locationStore.location?.settings?.countryCode,
  );
  // private headers: Array<string> | null = null;

  constructor(props: WorkflowProps) {
    super(props);

    const { workflowStore } = this.props.appStore;
    if (workflowStore.checkInRecord!.visitorInfoFieldValues === undefined) {
      workflowStore.checkInRecord!.visitorInfoFieldValues = {};
    }
    if (workflowStore.workflow?.useAlternativeWorkflow === true) {
      this._createVisitorInfoFieldValuesFromNode(this.props.node);
    }
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.node != prevProps.node) {
      const { workflowStore } = this.props.appStore;
      if (workflowStore.workflow?.useAlternativeWorkflow === true) {
        this._createVisitorInfoFieldValuesFromNode(this.props.node);
      }
      this.render();
    }
  }

  _createVisitorInfoFieldValuesFromNode(node: WorkflowNode) {
    const { workflowStore } = this.props.appStore;
    workflowStore.workflow!.visitorInfoFields = [];

    let fetchVisitorField = (field: any, index: number) => {
      let visitorInfoModel = new VisitorInfoFieldModel(field);
      visitorInfoModel.order = index;
      visitorInfoModel.labelPolyglot = [];
      workflowStore.workflow!.visitorInfoFields.push(visitorInfoModel);
      let defaultValue;
      switch (visitorInfoModel.dataType) {
        case 'checkbox_field':
          defaultValue = 'no';
          break;
        default:
          defaultValue = null;
      }
      if (defaultValue != null) {
        workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoModel.label] = defaultValue;
      }
    };

    node.data.selected.body.forEach((field: any, index: number) => {
      field.formId = node.data?.selected?.id;
      fetchVisitorField(field, index);
    });
    this.scanned_data = Object.assign({}, workflowStore.setupDriverLicenseData());
  }

  _fillPhoneNumberIfNeed = () => {
    const { workflowStore } = this.props.appStore;
    workflowStore.workflow!.visitorInfoFields.forEach((visitorInfoFields) => {
      if (visitorInfoFields.label === PHONE_NUMBER) {
        const phoneNumber = workflowStore.checkInRecord!.visitorInfoFieldValues[PHONE_NUMBER];
        if (phoneNumber === undefined) {
          workflowStore.checkInRecord!.visitorInfoFieldValues[PHONE_NUMBER] = EMPTY_PHONE_NUMBER;
        }
      }
    });
  };

  goToNextStep = () => {
    const { analyticsStore, workflowStore } = this.props.appStore;
    analyticsStore.setVisitorInfoFieldValues(workflowStore.checkInRecord!.visitorInfoFieldValues!);
    workflowStore.workflow?.useAlternativeWorkflow == true
      ? workflowStore.goToNextNode(toJS(workflowStore.checkInRecord!.visitorInfoFieldValues!))
      : workflowStore.goToNextStep();
  };

  _getLabelName = (visitorInfoField: VisitorInfoFieldModel, defaultValue: string) => {
    const { locationStore } = this.props.appStore;
    let visitorInfolabelPolyglotLength = Object.keys(visitorInfoField.labelPolyglot).length;
    const displayLabelOrFieldLabel =
      (visitorInfoField.displayLabel && visitorInfoField.displayLabel.length > 0
        ? visitorInfoField.displayLabel
        : visitorInfoField.label) || defaultValue;
    let label: string =
      visitorInfolabelPolyglotLength === 0
        ? displayLabelOrFieldLabel
        : visitorInfoField.labelPolyglot[`${locationStore.language}`] || defaultValue;
    if (label.replace(' ', '').toLocaleLowerCase() === visitorInfoField.label.replace('_', '').toLocaleLowerCase()) {
      label = displayLabelOrFieldLabel;
    }
    return label;
  };

  private _createVisitorInfoFieldComponent = (visitorInfoField: VisitorInfoFieldModel, index: number) => {
    const { locationStore, workflowStore } = this.props.appStore;

    // variables for conditions
    const visitorDataType = visitorInfoField.dataType;
    const startDl = visitorDataType?.match(/^dl_/i);

    const readonlyOffForDL = [
      'dl_street_address1',
      'dl_street_address2',
      'dl_city',
      'dl_jurisdiction_code',
      'dl_city_state',
      'dl_address',
    ].some((type) => type === visitorDataType);

    switch (visitorInfoField.label) {
      // case FIRST_NAME:
      // 	visitorInfoField = {
      // 		...visitorInfoField,
      // 		displayLabel: this._getLabelName(visitorInfoField, "First Name"),
      // 		dataType: VisitorInfoFieldType_v2.TEXT
      // 	};
      // 	break;
      // case LAST_NAME:
      // 	visitorInfoField = {
      // 		...visitorInfoField,
      // 		displayLabel: this._getLabelName(visitorInfoField, "Last Name"),
      // 		dataType: VisitorInfoFieldType_v2.TEXT
      // 	};
      // 	break;
      case EMAIL:
        visitorInfoField = {
          ...visitorInfoField,
          displayLabel: this._getLabelName(visitorInfoField, 'Email'),
          dataType: VisitorInfoFieldType_v2.EMAIL,
        };
        break;
      case PHONE_NUMBER:
        visitorInfoField = {
          ...visitorInfoField,
          displayLabel: this._getLabelName(visitorInfoField, 'Phone Number'),
          dataType: VisitorInfoFieldType_v2.PHONE,
        };
        break;
      // case COMPANY:
      // 	visitorInfoField = {
      // 		...visitorInfoField,
      // 		displayLabel: this._getLabelName(visitorInfoField, "Company"),
      // 		dataType: VisitorInfoFieldType_v2.TEXT
      // 	};
      // 	break;
      default: {
        visitorInfoField = {
          ...visitorInfoField,
          // condition
          displayLabel: this._getLabelName(visitorInfoField, ''),
        };
      }
    }

    let value = workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label];
    let isRequired: boolean;
    let readOnly: boolean;
    let disable: boolean = false;
    const passwordProtected = visitorInfoField.passwordProtected;

    if (visitorDataType && (visitorDataType.match(/\bbarcode\b|\bdl_license_no\b/i) || startDl)) {
      // starts with dl_
      isRequired = true && visitorDataType !== 'dl_street_address2';
      readOnly =
        !workflowStore.isDLScanSkipped &&
        DriverLicenseDataModel.visitorInfoFieldDLDataKeyMap[visitorDataType] !== undefined;
    } else {
      isRequired = true;
      readOnly = false;
    }

    const alternativeLabel = Utils.humanizeField(visitorInfoField.displayLabel);
    const i18nKey = `workflow_items.${visitorInfoField.label}`;
    const displayLabel = i18next.exists(i18nKey) ? t(i18nKey) : alternativeLabel;
    if (
      Utils.isNative() &&
      visitorInfoField.label.toLocaleLowerCase() === 'temperature' &&
      locationStore.location!.isThermalCheckInEnabled
    ) {
      readOnly = true;
      disable = true;
    }

    if (workflowStore?.workflow?.useAlternativeWorkflow == true) {
      readOnly =
        visitorInfoField.dataType == 'drivers_license_field' &&
        this.scanned_data[visitorInfoField.label] != null &&
        this.scanned_data[visitorInfoField.label].length > 0;
      isRequired = visitorInfoField.required;
    }

    const autofocusEnable = index === 0 && value == null;

    switch (visitorInfoField.dataType) {
      case VisitorInfoFieldType_v2.TEXT:
        return (
          <Inputs
            key={visitorInfoField.label + index}
            keyValue={visitorInfoField.label}
            type={passwordProtected ? 'password' : 'text'}
            inputMode={'text'}
            minlength={0}
            maxlength={50}
            forPlaceholder={displayLabel}
            forIcon="id-card-o"
            isRequired={isRequired}
            readonly={readOnly}
            disabled={disable}
            value={value}
            autofocus={autofocusEnable}
            onChange={(e) =>
              (workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label] = (
                e.target as HTMLInputElement
              ).value)
            }
          />
        );

      case VisitorInfoFieldType_v2.EMAIL:
        return (
          <Inputs
            key={visitorInfoField.label + index}
            type={passwordProtected ? 'password' : 'email'}
            inputMode={'email'}
            minlength={0}
            maxlength={50}
            forPlaceholder={displayLabel}
            forIcon="envelope-o"
            isRequired={isRequired}
            readonly={readOnly}
            disabled={disable}
            value={value}
            autofocus={autofocusEnable}
            onChange={(e) =>
              (workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label] = (
                e.target as HTMLInputElement
              ).value)
            }
          />
        );

      case VisitorInfoFieldType_v2.PHONE:
        return (
          <div className="center-box">
            <PhoneInput
              international
              className="country-code"
              defaultCountry={this.countryCode}
              onChange={(value) => value}
              onCountryChange={(countryCode) => {
                this.countryCode = countryCode!;
                workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label] = `+${getCountryCallingCode(
                  this.countryCode,
                )} `;
              }}
            />
            <Inputs
              key={visitorInfoField.label + index}
              ref={(el) => this.fieldRefs.push(el!)}
              defaultValue={!value ? `+${getCountryCallingCode(this.countryCode)} ` : undefined}
              type={passwordProtected ? 'password' : 'tel'}
              inputMode={'tel'}
              minlength={7}
              maxlength={20}
              forPlaceholder={displayLabel}
              forIcon="phone"
              isRequired={workflowStore?.workflow?.useAlternativeWorkflow == true ? isRequired : false}
              readonly={readOnly}
              disabled={disable}
              value={value}
              autofocus={autofocusEnable}
              onChange={(e) => {
                workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label] = parsePhoneNumber(
                  (e.target as HTMLInputElement).value,
                  this.countryCode,
                )?.format('INTERNATIONAL');
              }}
            />
          </div>
        );

      case VisitorInfoFieldType_v2.NUMBER:
        return (
          <Inputs
            key={visitorInfoField.label + index}
            type={passwordProtected ? 'password' : 'number'}
            inputMode={'decimal'}
            minlength={0}
            maxlength={50}
            forPlaceholder={displayLabel}
            forIcon="hashtag"
            isRequired={isRequired}
            value={value}
            autofocus={autofocusEnable}
            onKeyPress={(e) => {
              const pattern = /[0-9.,]/;
              let inputChar = String.fromCharCode(e.charCode);

              if (!pattern.test(inputChar)) {
                // invalid character, prevent input
                e.preventDefault();
              }
            }}
            onChange={(e) =>
              (workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label] = (
                e.target as HTMLInputElement
              ).value)
            }
          />
        );

      case DL_DOB:
      case VisitorInfoFieldType_v2.DATE:
        return (
          <Inputs
            key={visitorInfoField.label + index}
            type={passwordProtected ? 'password' : 'date'}
            forPlaceholder={displayLabel}
            forIcon="calendar"
            isRequired={isRequired}
            disabled={readOnly}
            value={value}
            autofocus={index === 0}
            onChange={(e) =>
              (workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label] = (
                e.target as HTMLInputElement
              ).value)
            }
          />
        );

      case VisitorInfoFieldType_v2.CHECKBOX:
        return (
          <Checkbox
            key={visitorInfoField.label + index}
            ref={(el) => this.fieldRefs.push(el!)}
            i18nBase={`workflow_items.forms.${visitorInfoField.formId}.${visitorInfoField.id}`}
            checkboxLabel={visitorInfoField.data.description}
            isRequired={isRequired}
            isChecked={value === 'yes'}
            onIonChange={(e) =>
              (workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label] = e.detail.checked
                ? 'yes'
                : 'no')
            }
          />
        );

      case VisitorInfoFieldType_v2.MULTISELECT:
        return (
          <MultiSelect
            key={visitorInfoField.label + index}
            ref={(el) => this.fieldRefs.push(el!)}
            multiTitle={displayLabel}
            multiOptions={visitorInfoField.options}
            values={value}
            isRequired={isRequired}
            onChange={(values) =>
              (workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label] = values)
            }
          />
        );

      case VisitorInfoFieldType_v2.TOGGLE:
        return (
          <Toggle
            key={visitorInfoField.label + index}
            ref={(el) => this.fieldRefs.push(el!)}
            toggleLabel={displayLabel}
            checked={value === 'yes'}
            onChange={(checked) =>
              (workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label] = checked ? 'yes' : 'no')
            }
          />
        );

      case VisitorInfoFieldType_v2.RADIO_SELECT:
        return (
          <RadioSelect
            key={visitorInfoField.label + index}
            ref={(el) => this.fieldRefs.push(el!)}
            radioTitle={visitorInfoField.data.question ?? displayLabel}
            radioOptions={visitorInfoField.data.options}
            value={value}
            i18nBase={`workflow_items.forms.${visitorInfoField.formId}.${visitorInfoField.id}`}
            isRequired={isRequired}
            onChange={(value) => (workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label] = value)}
          />
        );

      case VisitorInfoFieldType_v2.SELECT:
        return (
          <Select
            key={visitorInfoField.label + index}
            ref={(el) => this.fieldRefs.push(el!)}
            selectTitle={visitorInfoField.data.question}
            selectOptions={visitorInfoField.data.options}
            value={value}
            i18nBase={`workflow_items.forms.${visitorInfoField.formId}.${visitorInfoField.id}`}
            isRequired={isRequired}
            onChange={(value) => (workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label] = value)}
          />
        );

      case VisitorInfoFieldType_v2.SIGNATURE:
        return <SignatureEmbedded signatureText={'Signature'} clearText={'Clear'} />;

      case VisitorInfoFieldType_v2.RATING:
        return (
          <StarRating
            key={visitorInfoField.label + index}
            ref={(el) => this.fieldRefs.push(el!)}
            ratingLabel={displayLabel}
            isRequired={isRequired}
            onChange={(value) => (workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label] = value)}
          />
        );

      case VisitorInfoFieldType_v2.SLIDER:
        return (
          <SliderGreetly
            key={visitorInfoField.label + index}
            ref={(el) => this.fieldRefs.push(el!)}
            sliderLabel={displayLabel}
            min={parseInt(visitorInfoField.data.min)}
            max={parseInt(visitorInfoField.data.max)}
            step={parseInt(visitorInfoField.data.step)}
            onChange={(value) => (workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label] = value)}
          />
        );

      case VisitorInfoFieldType_v2.URL:
        return (
          <URL_Field
            key={visitorInfoField.label + index}
            ref={(el) => this.fieldRefs.push(el!)}
            i18nBase={`workflow_items.forms.${visitorInfoField.formId}.${visitorInfoField.id}`}
            urlLabel={visitorInfoField.data?.description}
            url={visitorInfoField.data?.url}
          />
        );

      case VisitorInfoFieldType_v2.EXTENDED_HEADER:
      case VisitorInfoFieldType_v2.HEADER:
        const i18nKey = `workflow_items.${visitorInfoField.data.header}`;

        return (
          <Header
            key={visitorInfoField.label + index}
            header={visitorInfoField.data.header}
            i18nBase={`workflow_items.forms.${visitorInfoField.formId}.${visitorInfoField.id}.header`}
            theme={locationStore.themeMain}
          />
        );

      case VisitorInfoFieldType_v2.DRIVER_LICENSE:
        if (visitorInfoField.label === 'gender') {
          const options = ['Male', 'Female'];
          const index = value - 1;
          const selectValue = Number.isInteger(Number.parseInt(value)) ? options[index] : value;
          workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label] = selectValue;

          return (
            <Select
              key={visitorInfoField.label + index}
              ref={(el) => this.fieldRefs.push(el!)}
              selectTitle={displayLabel}
              selectOptions={options}
              disabled={!!selectValue || false}
              value={selectValue}
              isRequired={isRequired}
              readOnly={readOnly}
              onChange={(value) => {
                workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label] = value;
              }}
            />
          );
        } else {
          return (
            <Inputs
              key={visitorInfoField.label + index}
              type={passwordProtected ? 'password' : 'text'}
              inputMode={'text'}
              forPlaceholder={displayLabel}
              forIcon=""
              value={value}
              isRequired={isRequired}
              readonly={readonlyOffForDL ? false : readOnly}
              disabled={disable}
              autofocus={autofocusEnable}
              onChange={(e) =>
                (workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label] = (
                  e.target as HTMLInputElement
                ).value)
              }
            />
          );
        }
      case VisitorInfoFieldType_v2.TIME_PICKER:
        return (
          <TimePicker
            key={visitorInfoField.label + index}
            ref={(el) => this.fieldRefs.push(el!)} // TODO write ref
            timeLabel={displayLabel}
            isRequired={isRequired}
            onChange={(value) => (workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label] = value)}
          />
        );

      default:
        return (
          <Inputs
            key={visitorInfoField.label + index}
            type={passwordProtected ? 'password' : 'text'}
            inputMode={'text'}
            forPlaceholder={displayLabel}
            forIcon=""
            value={value}
            isRequired={isRequired}
            readonly={readonlyOffForDL ? false : readOnly}
            disabled={disable} // for situations if the address is different from what is on Drivers License
            autofocus={autofocusEnable}
            onChange={(e) =>
              (workflowStore.checkInRecord!.visitorInfoFieldValues[visitorInfoField.label] = (
                e.target as HTMLInputElement
              ).value)
            }
          />
        );
    }
  };

  onSubmit = (e: any) => {
    e.preventDefault();

    let isValid = true;
    for (let i = 0; i < this.fieldRefs.length; ++i) {
      const fieldRef = this.fieldRefs[i];
      if (fieldRef !== null && fieldRef.props.isRequired && fieldRef.validate) {
        if (fieldRef.props.type != 'tel' && !fieldRef.validate()) {
          isValid = false;
        } else if (
          fieldRef.props.type == 'tel' &&
          !fieldRef.validate(() => isPossiblePhoneNumber(fieldRef.props.value ?? '', this.countryCode))
        ) {
          isValid = false;
        }
      }
    }

    if (isValid) {
      this._fillPhoneNumberIfNeed();
      this.goToNextStep();
    }
  };

  goLinkInBrowser = async () => {
    let inAppBrowserRef: any;
    function loadStopCallBack() {
      if (inAppBrowserRef !== undefined) {
        inAppBrowserRef.insertCSS({
          code: 'button.navbar-toggle.collapsed {display: none;}.main-content {padding-top: 8rem!important;}',
        });
        inAppBrowserRef.show();
      }
    }
    const url = 'https://www.greetly.com/privacy-policy';
    const target = '_blank';
    let options = 'hideurlbar=yes,hidenavigationbuttons=yes,hidden=yes,beforeload=yes';
    if (isPlatform('ios') || isPlatform('ipad')) {
      options = 'location=no,hidenavigationbuttons=yes';
    }

    //@ts-ignore
    inAppBrowserRef = await Browser.open(url, target, options);
    inAppBrowserRef.addEventListener('loadstop', loadStopCallBack);
  };

  getPreRegMessage = () => {
    const { appStore } = this.props;
    const { workflowStore, locationStore } = appStore;
    const { preRegCheckInRecord } = workflowStore;

    return `Please register to visit ${preRegCheckInRecord!.host_name} on ${preRegCheckInRecord!.event_start_time} at ${
      locationStore.location!.name
    }`;
  };

  render() {
    const { appStore } = this.props;
    const { locationStore, workflowStore } = appStore;
    const { location } = locationStore;

    this.fieldRefs = [];

    const themeMain = locationStore.themeMain;
    const themeButton = locationStore.themeButton;

    return (
      <IonPage className="visitor">
        <IonContent>
          <Banner {...this.props} />
          {DesignHelper.renderThemeDesignTop(themeMain, locationStore.location?.logo || null)}
          {Utils.isNative() && DesignHelper.renderHomeButton(themeButton, () => workflowStore.reset(true))}
          {workflowStore.workflow!.visitorInfoFields.find((element) => element.dataType == 'header') == null &&
            DesignHelper.renderThemeLabelTitle(themeMain, locationStore.tabletTerminologies.whoIsVisiting)}

          <form onSubmit={(e) => this.onSubmit(e)}>
            {workflowStore.workflow!.visitorInfoFields.map((visitorInfoFields, i) =>
              this._createVisitorInfoFieldComponent(visitorInfoFields, i),
            )}
            {DesignHelper.renderNextScreenButton(appStore.screenType)}
          </form>

          {location!.gdpr === '1' && (
            <div className="gdpr-cont">
              <IonText className="gdpr-area">{locationStore.tabletTerminologies.gdpr}</IonText>
              {/* <PrivacyPolicy {...this.props}/> */}
              {!isPlatform('desktop') && !isPlatform('mobileweb') && (isPlatform('android') || isPlatform('ios')) ? (
                <IonText
                  className="provacy-poly-visit"
                  onClick={() => {
                    this.goLinkInBrowser();
                  }}
                >
                  Privacy Policy
                </IonText>
              ) : (
                <a href="https://www.greetly.com/privacy-policy">Privacy Policy</a>
              )}
            </div>
          )}
          <div className="end-block hide" id="endBlock"></div>
        </IonContent>
      </IonPage>
    );
  }
}

export default observer(Visitor_v2);
