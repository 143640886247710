export default class DriverLicenseDataModel_v2 {
  drivers_license_number?: string;
  last_name?: string;
  first_name?: string;
  date_of_birth?: string;
  street_address_1?: string;
  street_address_2?: string;
  city?: string;
  state?: string;
  postal_code?: string;
  gender?: string;

  static parseText(text: string) {
    const codeFieldMap = [
      ['DAQ', 'drivers_license_number'],
      ['DCS', 'last_name'],
      ['DAB', 'last_name'],
      ['DBO', 'last_name'],
      ['DCT', 'first_name'],
      ['DBP', 'first_name'],
      ['DAC', 'first_name'],
      ['DBC', 'gender'],
      ['DBB', 'date_of_birth'],
      ['DBL', 'date_of_birth'],
      ['DAG', 'street_address_1'],
      ['DAM', 'street_address_2'],
      ['DAI', 'city'],
      ['DAJ', 'state'],
      ['DAK', 'postal_code'],
      ['DAA', 'last_first_names'],
      ['DBA', 'drivers_license_expiration'],
    ];

    const data: any = {};
    codeFieldMap.forEach((codeFieldPair) => {
      const code = codeFieldPair[0];
      if (!data[codeFieldPair[1].trim()]) {
        const regExpStr = code + '.*?\\n';
        const matches = text.match(new RegExp(regExpStr));
        if (matches) {
          const match = matches[0];
          let val = match.substring(code.length, match.length - 1);
          val = ['DBB', 'DBL', 'DBA'].includes(code) ? (val = val.replace(/^(\d{2})(\d{2})(\d{4})$/, '$3-$1-$2')) : val; // we got Drivres License date format '25021988' instead '1988-02-25'
          if (code === 'DAA') {
            const array_names = val.split(',');
            data['last_name'] = array_names.length > 1 ? array_names[0] : '';
            data['first_name'] = array_names.length > 2 ? array_names[1] : '';
          }
          data[codeFieldPair[1]] = val.trim();
        }
      }
    });

    // if postal code longer than 5 digit, slice last 0 numbers
    // @TODO need to test/check if slice correct place and number
    const POSTAL_CODE_LENGTH = 5;
    if (data.postal_code && data.postal_code.length >= POSTAL_CODE_LENGTH) {
      data.postal_code = data.postal_code.substring(0, POSTAL_CODE_LENGTH);
    }

    return data as DriverLicenseDataModel_v2;
  }
}
