import WorkflowEdgeAPI from '../api/v2/WorkflowEdgeAPI';

export default class WorkflowEdge {
  source: string;
  target: string;
  type: string | null = null;
  value: any;

  constructor(workflowEdgeAPI: WorkflowEdgeAPI) {
    this.source = workflowEdgeAPI.source;
    this.target = workflowEdgeAPI.target;
    this.type = workflowEdgeAPI.data.type;
    this.value = workflowEdgeAPI.data.value === 'true';
  }
}
