import { computed, action, observable, autorun, makeObservable } from 'mobx';
import WorkflowModel from '../models/WorkflowModel';
import HostModel from '../models/HostModel';
import Axios, { AxiosResponse } from 'axios';
import PostSignatureBodyAPIModel from '../models/api/PostSignatureBodyAPIModel';
import PostSignatureResponseAPIModel from '../models/api/PostSignatureResponseAPIModel';
import InCheckInRecordModel from '../models/InCheckInRecordModel';
import PostCheckInRecordBodyAPIModel from '../models/api/PostCheckInRecordBodyAPIModel';
import { StepId, WorkflowType, Etc, NodeId } from '../commons/Constants';
import InCheckInRecordAPIModel from '../models/api/InCheckInRecordAPIModel';
import AppStore from './AppStore';
import Utils from '../utils/Utils';
import OutCheckInRecordModel from '../models/OutCheckInRecordModel';
import OutCheckInRecordAPIModel from '../models/api/OutCheckInRecordAPIModel';
import DriverLicenseDataModel from '../models/DriverLicenseDataModel';
import NativeStore from './NativeStore';
import StepModel from '../models/StepModel';
import PreRegCheckInRecordAPIModel from '../models/api/PreRegCheckInRecordAPIModel';
import FetchPreRegCheckInRecordResultAPIModel from '../models/api/FetchPreRegCheckInRecordResultAPIModel';
import WorkflowAPIModel from '../models/api/WorkflowAPIModel';
import PostCheckInRecordBodyModel from '../models/PostCheckInRecordBody';
import { Plugins } from '@capacitor/core';
import { rollbarHandler } from '../components/RollbarErrorHandler';
import WorkflowLogic from '../logic/WorkflowLogic';
import WorkflowStep from '../models/v2/WorkflowStep';
import BadgeUtils, { BadgePrintInterface } from '../utils/BadgeUtils';
import { FailedCheckInCounter } from '../utils/FailedCheckInCounter';
import VisitorInfoFieldModel from '../models/VisitorInfoFieldModel';
import { getBaseUrl, getRubyServerUrl } from '../utils/BaseUrls';
import { deviceHashModel } from './deviceHash';

const { Device } = Plugins;

export class WorkflowStorePostCheckInRecordError {
  static get UNAUTHORIZED() {
    return 'workflow_store_post_check_in_record_unauthorized';
  }
  static get UNKNOWN() {
    return 'workflow_store_post_check_in_record_unknown';
  }
}

export class WorkflowStorePostSignatureError {
  static get UNAUTHORIZED() {
    return 'workflow_store_post_signature_unauthorized';
  }
  static get UNKNOWN() {
    return 'workflow_store_post_signature_unknown';
  }
}

export class FetchPreRegCheckInRecordError {
  static get UNAUTHORIZED() {
    return 'fetch_pre_reg_check_in_record_unauthorized';
  }
  static get UNKNOWN() {
    return 'fetch_pre_reg_check_in_record_unknown';
  }
}

export default class WorkflowStore {
  private _appStore!: AppStore;

  private _workflow: WorkflowModel | null = null;
  private _stepIndex = 0;
  private _nodeIndex = 'node_0';

  // prevents success page from finishing workflow
  private _isWorkflowFinished = false;

  private _postCheckInRecordBody: PostCheckInRecordBodyModel | null = null;
  private _checkInResponse: any = null;

  private _visitorImageBase64: string | null = null;
  private _frontDriverLicenseImage: string | null = null;
  private _backDriverLicenseImage: string | null = null;
  private _packageImageBase64: string | null = null;

  private _checkOutCheckInRecord: InCheckInRecordAPIModel | null = null;

  private _driverLicenseData: DriverLicenseDataModel | null = null;

  private _barcodeData: string | null = null;

  private _preRegCheckInRecord: PreRegCheckInRecordAPIModel | null = null;
  private _preRegCheckInRecordToComplete: PreRegCheckInRecordAPIModel | null = null;
  private _preRegBarcodeUrl: string | null = null;
  private _errorHandler = rollbarHandler();

  private _thermalCheckInRecord: any = null;

  private _surrogateId: string | null = null;

  isDLScanSkipped = false;

  private _workflowLogic: WorkflowLogic = new WorkflowLogic();
  private _workflowSteps = Array<WorkflowStep>();

  private _isUploadingSignature = false;

  constructor(appStore: AppStore) {
    makeObservable<
      WorkflowStore,
      | '_workflow'
      | '_stepIndex'
      | '_nodeIndex'
      | '_thermalCheckInRecord'
      | '_tryCompleteStep'
      | '_putPreRegCheckInRecord'
      | '_putThermalCheckInRecord'
    >(this, {
      _workflow: observable,
      _stepIndex: observable,
      _nodeIndex: observable,
      _thermalCheckInRecord: observable,
      workflow: computed,
      node: computed,
      step: computed,
      thermalCheckInRecord: computed,
      setWorkflow: action,
      goToNextStep: action,
      goToNextNode: action,
      _tryCompleteStep: action,
      finishWorkflow: action,
      reset: action,
      postSignature: action,
      fetchPreRegCheckInRecord: action,
      fetchServerData: action,
      _putPreRegCheckInRecord: action,
      _putThermalCheckInRecord: action,
      finishPrereg: action,
      checkInEmployee: action,
    });

    this._appStore = appStore;
    autorun(() => {
      const location = this.locationInfo;
      this._errorHandler.configure({
        payload: {
          context: {
            location_id: location.id,
            location_name: location.name,
            screen: this.step?.id,
          },
        },
      });
    });
  }

  get workflow() {
    return this._workflow;
  }

  get node() {
    return this._workflow?.alternativeWorkflow?.nodes[this._nodeIndex];
  }

  get step() {
    return this._workflow?.steps[this._stepIndex];
  }

  get hostId() {
    if (this._preRegCheckInRecord !== null) {
      return this._preRegCheckInRecord.host_id;
    } else if (this._thermalCheckInRecord !== null) {
      const hostId: number | null = this._thermalCheckInRecord.host_id;
      return hostId;
    } else {
      return this._postCheckInRecordBody!.checkInRecord.hostId;
    }
  }

  get checkInRecord() {
    return this._postCheckInRecordBody!.checkInRecord;
  }

  get checkInResponse() {
    return this._checkInResponse;
  }

  get visitorInfoFields(): Record<string, any> {
    return (
      this._postCheckInRecordBody!.checkInRecord.visitorInfoFieldValues ??
      this._checkInResponse.visitor_info_fields ??
      {}
    );
  }

  get checkOutCheckInRecord() {
    return this._checkOutCheckInRecord;
  }

  get driverLicenseData() {
    return this._driverLicenseData;
  }

  get barcodeData() {
    return this._barcodeData;
  }

  get preRegBarcodeUrl() {
    return this._preRegBarcodeUrl;
  }

  get preRegCheckInRecord() {
    return this._preRegCheckInRecord;
  }

  get thermalCheckInRecord() {
    return this._thermalCheckInRecord;
  }

  get locationInfo() {
    return {
      id: this._appStore.locationStore.location?.id,
      name: this._appStore.locationStore.location?.name,
    };
  }

  get surrogateId() {
    return this._postCheckInRecordBody!.surrogateId;
  }

  get isPreReg() {
    return this._preRegCheckInRecord !== null;
  }

  get workflowSteps(): Array<WorkflowStep> {
    return this._workflowSteps;
  }

  get autenticationHeader() {
    return this._appStore.authenticationHeader;
  }

  get isUserRegistered(): boolean {
    return this._preRegCheckInRecordToComplete != null;
  }

  get isNeedAddPhoto(): boolean {
    return (
      this._visitorImageBase64 !== null ||
      this._frontDriverLicenseImage !== null ||
      this._backDriverLicenseImage !== null ||
      this._packageImageBase64 !== null
    );
  }

  get hasVisitorPhoto(): boolean {
    return this._visitorImageBase64 !== null;
  }

  setSelectedHost(host: HostModel) {
    this._postCheckInRecordBody!.checkInRecord.hostId = host.id;
  }

  get selectedHostId(): number | undefined {
    return this._postCheckInRecordBody?.checkInRecord.hostId;
  }

  setIsDeliverySignatureRequired(isDeliverySignatureRequired: boolean) {
    this._postCheckInRecordBody!.checkInRecord.deliverySignatureRequired = isDeliverySignatureRequired;
  }

  setVisitorImageBase64(visitorImageBase64: string) {
    this._visitorImageBase64 = visitorImageBase64;
  }

  setPackageImageBase64(packageImageBase64: string) {
    this._packageImageBase64 = packageImageBase64;
  }

  setFrontDriverLicenseImage(dlimage: string) {
    this._frontDriverLicenseImage = dlimage;
  }

  setBackDriverLicenseImage(dlimage: string) {
    this._backDriverLicenseImage = dlimage;
  }

  setCheckOutCheckInRecord(checkInRecord: InCheckInRecordModel) {
    this._checkOutCheckInRecord = new InCheckInRecordAPIModel(checkInRecord);
  }

  setDriverLicenseData(dlData: DriverLicenseDataModel) {
    this._driverLicenseData = dlData;

    if (this._postCheckInRecordBody!.checkInRecord.visitorInfoFieldValues === undefined) {
      this._postCheckInRecordBody!.checkInRecord.visitorInfoFieldValues = {};
    }

    const visitorInfoFieldValues = this._postCheckInRecordBody!.checkInRecord.visitorInfoFieldValues;

    const dlDataAny: any = dlData;
    for (let i = 0; i < this._workflow!.visitorInfoFields.length; ++i) {
      const visitorInfoField = this._workflow!.visitorInfoFields[i];
      if (visitorInfoField.dataType !== null) {
        const dlDataKey = DriverLicenseDataModel.visitorInfoFieldDLDataKeyMap[visitorInfoField.dataType];
        if (dlDataKey !== undefined) {
          visitorInfoFieldValues[visitorInfoField.label] = dlDataAny[dlDataKey];
        }
      }
    }
  }

  setupDriverLicenseData(): {} {
    if (this._driverLicenseData != null) {
      if (this._postCheckInRecordBody!.checkInRecord.visitorInfoFieldValues === undefined) {
        this._postCheckInRecordBody!.checkInRecord.visitorInfoFieldValues = {};
      }

      const visitorInfoFieldValues = this._postCheckInRecordBody!.checkInRecord.visitorInfoFieldValues;

      const dlDataAny: any = this._driverLicenseData;
      for (let i = 0; i < this._workflow!.visitorInfoFields.length; ++i) {
        const visitorInfoField = this._workflow!.visitorInfoFields[i];
        const dlDataKey = visitorInfoField.label;
        if (dlDataKey !== undefined && dlDataAny[dlDataKey] != null) {
          visitorInfoFieldValues[visitorInfoField.label] = dlDataAny[dlDataKey];
        }
      }
      return visitorInfoFieldValues;
    }
    return {};
  }

  setupPreDefinedData() {
    let visitorInfoFieldValues: { [index: string]: any };
    if (this._preRegCheckInRecord || this._preRegCheckInRecordToComplete) {
      visitorInfoFieldValues =
        this._preRegCheckInRecord?.visitor_info_fields ?? this._preRegCheckInRecordToComplete?.visitor_info_fields;
    } else if (this._thermalCheckInRecord) {
      visitorInfoFieldValues = this._thermalCheckInRecord!.visitor_info_fields;
    } else {
      return;
    }

    if (visitorInfoFieldValues === undefined) return;

    this._postCheckInRecordBody!.checkInRecord.visitorInfoFieldValues = {};
    Object.entries(visitorInfoFieldValues).forEach(
      ([key, value]) => (this._postCheckInRecordBody!.checkInRecord.visitorInfoFieldValues[key] = value),
    );
  }

  setBarcodeData(barcodeData: string) {
    this._barcodeData = barcodeData;

    let visitorInfoFieldValues = this._postCheckInRecordBody!.checkInRecord.visitorInfoFieldValues;

    if (visitorInfoFieldValues === undefined) {
      visitorInfoFieldValues = {};
    }

    for (let i = 0; i < this._workflow!.visitorInfoFields.length; ++i) {
      const visitorInfoField = this._workflow!.visitorInfoFields[i];
      if (visitorInfoField.label === 'barcode') {
        visitorInfoFieldValues[visitorInfoField.label] = barcodeData;
        return;
      } else if (visitorInfoField.label === 'barcode_editable') {
        visitorInfoFieldValues[visitorInfoField.label] = barcodeData;
        return;
      }
    }
  }

  setThermalCheckInRecord(thermalCheckInRecord: any) {
    this._thermalCheckInRecord = thermalCheckInRecord;
  }

  setSurrogateId(surrogateId: string) {
    this._postCheckInRecordBody!.surrogateId = surrogateId;
  }

  setWorkflow(workflow: WorkflowModel) {
    const currentWorkflow: WorkflowModel = {
      ...workflow,
      visitorInfoFields: workflow.visitorInfoFields.map((item: VisitorInfoFieldModel) => {
        item.label = item.label.replace(' ', '_').toLowerCase();
        return item;
      }),
    };
    this._postCheckInRecordBody = new PostCheckInRecordBodyModel(new OutCheckInRecordModel(currentWorkflow.id));
    try {
      this._workflow = JSON.parse(JSON.stringify(currentWorkflow)) as WorkflowModel;
    } catch (err) {
      this._workflow = JSON.parse(Utils.safeStringify(currentWorkflow)) as WorkflowModel;
    }

    this.setupPreDefinedData();

    if (this._workflow.useAlternativeWorkflow == true) {
      this._workflowLogic = new WorkflowLogic(this);
      this._nodeIndex = 'node_0';
      if (this._preRegCheckInRecordToComplete != null) {
        this._postCheckInRecordBody.checkInRecord.old_check_in_record_id = this._preRegCheckInRecordToComplete?.id;
      }

      this._tryCompleteNode();
    } else {
      this._stepIndex = this._tryCompleteSteps(0);
    }
  }

  goToNextStep() {
    if (this._workflow?.useAlternativeWorkflow == true) {
      this.goToNextNode();
    } else {
      this._stepIndex = this._tryCompleteSteps(this._stepIndex + 1);

      if (this._stepIndex >= this._workflow!.steps.length) {
        this.reset(true);
      }
    }
  }

  private _tryCompleteSteps(fromStepIndex: number) {
    let currentStepIndex = fromStepIndex;
    while (currentStepIndex < this._workflow!.steps.length) {
      const currentStep = this._workflow!.steps[currentStepIndex];
      if (this._tryCompleteStep(currentStep)) {
        ++currentStepIndex;
      } else {
        break;
      }
    }
    return currentStepIndex;
  }

  goToNextNode(value?: any | null) {
    this._saveCompletedNode(value);
    if (this._workflowLogic.hasNextNode(this._nodeIndex!, value)) {
      this._nodeIndex = this._workflowLogic.nextNodeIndex(this._nodeIndex!, value);
      this._tryCompleteNode();
    } else {
      this.reset(true);
    }
  }

  private _tryCompleteNode() {
    this._workflowLogic.runWorkflowLogic(this.node);
  }

  private _saveCompletedNode(value?: any | null) {
    this._workflowSteps.push(new WorkflowStep(this.node?.id, this.node?.type, value));
    this._postCheckInRecordBody!.checkInRecord.steps = this._workflowSteps;
  }

  private _tryCompleteStep(step: StepModel) {
    switch (step.id) {
      case StepId.SEARCH_HOST:
        if (this._preRegCheckInRecord && this._preRegCheckInRecord.host_id) {
          return true;
        } else if (this._thermalCheckInRecord && this._thermalCheckInRecord.host_id) {
          return true;
        } else {
          return false;
        }
      case StepId.PHOTO_CAPTURE:
        if (this._preRegCheckInRecord && this._preRegCheckInRecord.visitor_photo) {
          return true;
        } else if (this._thermalCheckInRecord && this._thermalCheckInRecord.visitor_photo) {
          return true;
        } else {
          return false;
        }
      case StepId.VISITOR:
        {
          let visitorInfoFieldValues: any;
          if (this._preRegCheckInRecord) {
            visitorInfoFieldValues = this._preRegCheckInRecord!.visitor_info_fields;
          } else if (this._thermalCheckInRecord) {
            visitorInfoFieldValues = this._thermalCheckInRecord!.visitor_info_fields;
          } else {
            return false;
          }

          if (visitorInfoFieldValues === undefined) return false;

          const labels = Object.keys(visitorInfoFieldValues);
          this._postCheckInRecordBody!.checkInRecord.visitorInfoFieldValues = {};
          for (let i = 0; i < this._workflow!.visitorInfoFields.length; ++i) {
            const visitorInfoField = this._workflow!.visitorInfoFields[i];
            if (labels.includes(visitorInfoField.label)) {
              this._postCheckInRecordBody!.checkInRecord.visitorInfoFieldValues[visitorInfoField.label] =
                visitorInfoFieldValues[visitorInfoField.label];
            }
          }

          // stay on this step even if fully filled
        }
        break;
      case StepId.SIGNATURE:
        if (this._preRegCheckInRecord && this._preRegCheckInRecord.signature_record_id) {
          return true;
        } else if (this._thermalCheckInRecord && this._thermalCheckInRecord.signature_record_id) {
          return true;
        } else {
          return false;
        }
      case StepId.EMPLOYEE_CHECK_IN: {
        if (this._surrogateId) {
          this._postCheckInRecordBody!.surrogateId = this._surrogateId;
          this._postCheckInRecordBody!.checkInRecord = {
            locationId: this._appStore.locationId,
          };
          return true;
        }
      }
    }

    return false;
  }

  async finishWorkflow() {
    if (this._isWorkflowFinished) {
      // this._printBadge(this._checkInResponse.id);
      return;
    }

    if (this.node != null) {
      this._saveCompletedNode();
      this.checkInRecord.success_check_in = this._workflowLogic.isSuccessCheckIn(this.node!);
    }

    this._isWorkflowFinished = true;

    if (this._workflow!.type === WorkflowType.CHECK_OUT) {
      await this._putCheckOutCheckinRecords();
    } else {
      await this.waitUntilSignatureIsUploading();
      if (this._preRegCheckInRecord !== null) {
        await this._putPreRegCheckInRecord();
      } else if (this._thermalCheckInRecord !== null) {
        console.info('finish thermal');
        await this._putThermalCheckInRecord();
      } else {
        console.info('finish normal');
        await this._postCheckInRecord();
      }
    }
  }

  reset(shouldShowWelcomePage = false) {
    const nativeStore = this._appStore as NativeStore;
    if (Utils.isNative() || nativeStore.previewMode) {
      if (shouldShowWelcomePage) {
        nativeStore.shouldShowWelcomePage = true;
        const locationStore = nativeStore.locationStore;
        locationStore.resetLanguage();
      }

      if (this._thermalCheckInRecord !== null) {
        nativeStore.isFromSuccess = true;
        setTimeout(() => {
          nativeStore.openThermal();
        }, 200);
      }
    }

    this._workflow = null;
    this._isWorkflowFinished = false;
    this._postCheckInRecordBody = null;
    this._checkInResponse = null;
    this._visitorImageBase64 = null;
    this._checkOutCheckInRecord = null;
    this._driverLicenseData = null;
    this._barcodeData = null;
    this._preRegCheckInRecordToComplete = null;
    this._preRegCheckInRecord = null;
    this._preRegBarcodeUrl = null;
    this._thermalCheckInRecord = null;
    this.isDLScanSkipped = false;
    this._workflowSteps = [];
    this._frontDriverLicenseImage = null;
    this._backDriverLicenseImage = null;
    this._packageImageBase64 = null;
  }

  private _appendObject(formData: FormData, parentKey: string, object: any) {
    Object.keys(object).forEach((key) => {
      const value = object[key];
      if (Array.isArray(value)) {
        value.forEach((val) => {
          if (typeof val === 'object' && val != null) {
            this._appendObject(formData, parentKey + `[${key}[]]`, val);
          }
        });
      } else if (typeof value === 'object' && value != null) {
        this._appendObject(formData, parentKey + `[${key}]`, value);
      } else {
        if (value != null) {
          formData.append(parentKey + `[${key}]`, value);
        }
      }
    });
  }

  private async _printBadge(visitorId: any) {
    let shouldPrintBadge = this.workflow?.useAlternativeWorkflow == false && this._workflow!.shouldPrintBadge;
    const customBadgeHTML = this._workflowSteps.find((step) => step.type == NodeId.PRINT_BADGE)?.value;
    shouldPrintBadge = shouldPrintBadge || customBadgeHTML != null;
    if (shouldPrintBadge) {
      if (Utils.isNative()) {
        const { hostsStore, locationStore } = this._appStore;
        const hostId = this._postCheckInRecordBody!.checkInRecord.hostId;
        let hostFullName = hostId != null ? hostsStore.getHostById(hostId)!.fullName : null;

        const badgePrint: BadgePrintInterface = {
          visitorInfoFieldValues: this._postCheckInRecordBody!.checkInRecord.visitorInfoFieldValues,
          hostName: hostFullName,
          visitorImgUrl: this._visitorImageBase64 ? 'data:image/jpeg;base64,' + this._visitorImageBase64 : null,
          // eslint-disable-next-line max-len
          companyImgUrl: locationStore.location?.logo ? await BadgeUtils.addProxyToURL(locationStore.location.logo) : null,
          badgeText: Utils.parseMessage(locationStore.location!.badgeSetting.badgeText, [
            {
              tag: 'check_in_option_name',
              source: () => {
                return this._workflow!.name;
              },
            },
            {
              tag: 'visitor_id',
              source: () => {
                return visitorId;
              },
            },
            {
              tag: 'space',
              source: () => {
                return '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;';
              },
            },
          ]),
          customBadgeHTML: customBadgeHTML,
          workflowName: this._workflow?.name,
          visitorId: visitorId,
        };

        // don't wait, let the user finish without finishing print
        Utils.printBadge(badgePrint, locationStore.location!.badgeSetting).catch((err) => {
          console.error(Etc.LOG_PREFIX + JSON.stringify(err));
          this._errorHandler.log(err);
        });
      } else {
        this._postCheckInRecordBody!.checkInRecord.toPrint = true;
      }
    }
  }

  private async _postCheckInRecord() {
    const baseUrl = await getBaseUrl();
    let url = baseUrl + '/api/check_in_records';

    const checkInRecord = this._postCheckInRecordBody!.checkInRecord;
    checkInRecord.device_id = this._appStore?.deviceId || null;

    if (Utils.isNative()) {
      const info = await Device.getInfo();
      const unitInfo =  deviceHashModel.find((item) => item.model === info.model);

      const nativeStore = this._appStore as NativeStore;
      await Axios.post(baseUrl + '/api/device/record_device', {
        location_id: nativeStore.account?.locationId,
        api_key: nativeStore.account?.apiKey,
        model: unitInfo?.name || info.model,
        platform: info.platform,
        manufacturer: info.manufacturer,
        version: info.osVersion,
        uuid: info.uuid,
      });
    } else {
      //print badge if check-in allows printing and not native app for old badge system
      checkInRecord.toPrint = this._workflow!.shouldPrintBadge;
    }

    if (this.isNeedAddPhoto && !Utils.isNative()) {
      const record = new OutCheckInRecordAPIModel(this._postCheckInRecordBody!.checkInRecord);
      const formData = this._createFormDataForImagesRequest(record, true);

      return Axios.post(url, formData, {
        headers: {
          ...this._appStore.authenticationHeader,
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((res: AxiosResponse<any>) => {
          this._appStore.analyticsStore.logEvent();
          this._checkInResponse = res.data;
        })
        .catch((err) => {
          console.error(Etc.LOG_PREFIX + JSON.stringify(err));
          this._errorHandler.error(err);
          if (err.response !== undefined) {
            if (err.response.status === 401) {
              throw WorkflowStorePostCheckInRecordError.UNAUTHORIZED;
            } else {
              throw WorkflowStorePostCheckInRecordError.UNKNOWN;
            }
          } else {
            throw WorkflowStorePostCheckInRecordError.UNKNOWN;
          }
        });
    } else {
      this._postCheckInRecordBody!.checkInRecord.visitor_photo_attached = this.hasVisitorPhoto;
      this._postCheckInRecordBody!.checkInRecord.attachments_update = this.isNeedAddPhoto;
      const body = new PostCheckInRecordBodyAPIModel(this._postCheckInRecordBody!);

      if (!body.check_in_record.device_id || body.check_in_record.device_id === 'undefined') {
        body.check_in_record.device_id = null;
      }
      return Axios.post(url, body, {
        headers: {
          ...this._appStore.authenticationHeader,
        },
      })
        .then((res: AxiosResponse<any>) => {
          this._appStore.analyticsStore.logEvent();
          this._checkInResponse = res.data;
          if (this.isNeedAddPhoto) {
            this._uploadImagesInBackground();
          }
        })
        .catch((err) => {
          console.error(err.request.response);
          console.error(Etc.LOG_PREFIX + JSON.stringify(err));
          this._errorHandler.error(err);
          if (err.response !== undefined) {
            if (err.response.status === 401) {
              throw WorkflowStorePostCheckInRecordError.UNAUTHORIZED;
            } else {
              throw WorkflowStorePostCheckInRecordError.UNKNOWN;
            }
          } else {
            throw WorkflowStorePostCheckInRecordError.UNKNOWN;
          }
        });
    }
  }

  private _createFormDataForImagesRequest = (record: any, blob: boolean) => {
    if (!Utils.isNative() && record.steps) {
      record.steps = record.steps?.reduce((array: any, step: WorkflowStep, currentIndex: number) => {
        array[currentIndex] = step;
        return array;
      }, {});
    }
    const formData = new FormData();
    this._appendObject(formData, 'check_in_record', record);
    if (this._postCheckInRecordBody!.surrogateId !== undefined) {
      this._appendObject(formData, 'surrogate_id', this._postCheckInRecordBody!.surrogateId);
    }

    this.addCheckInImageToFormdata(formData, blob);

    return formData;
  };

  private async _uploadImagesInBackground() {
    const baseUrl = await getBaseUrl();
    let url = `${baseUrl}/api/check_in_records/${this._checkInResponse.id}`;
    const formData = this._createFormDataForImagesRequest({ attachments_update: true }, false);

    return Axios.put(url, formData, {
      headers: {
        ...this._appStore.authenticationHeader,
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((res: AxiosResponse<any>) => {
        this.reset();
      })
      .catch((err) => {
        this.reset();
        console.error(Etc.LOG_PREFIX + JSON.stringify(err));
        this._errorHandler.error(err);
        FailedCheckInCounter.shared.increaseFailedCheckInNumber();
      });
  }

  private async _putCheckOutCheckinRecords() {
    const baseUrl = await getBaseUrl();
    const url = baseUrl + '/api/check_in_records/' + this._checkOutCheckInRecord!.id;

    this._checkOutCheckInRecord!.checked_out = true;
    this._checkOutCheckInRecord!.steps = this._workflowSteps;
    Object.assign(
      this._checkOutCheckInRecord!.visitor_info_fields,
      this._postCheckInRecordBody!.checkInRecord.visitorInfoFieldValues,
    );

    return Axios.put(
      url,
      { check_in_record: this._checkOutCheckInRecord },
      {
        headers: {
          ...this._appStore.authenticationHeader,
        },
      },
    ).catch((err) => {
      console.error(Etc.LOG_PREFIX + JSON.stringify(err));
      this._errorHandler.critical(err);
      throw err;
    });
  }

  async postSignature(signatureBase64: string) {
    this._isUploadingSignature = true;
    const baseUrl = await getBaseUrl();
    let url = baseUrl + '/api/signature_records';
    const body = new PostSignatureBodyAPIModel(
      signatureBase64,
      this._postCheckInRecordBody!.checkInRecord.visitorInfoFieldValues ?? {
        firstname: 'firstname',
        lastname: 'lastname',
      },
    );
    return Axios.post(url, body, {
      headers: {
        ...this._appStore.authenticationHeader,
      },
    })
      .then((res: AxiosResponse<PostSignatureResponseAPIModel>) => {
        this._postCheckInRecordBody!.checkInRecord.signatureRecordId = res.data.signature_record.id!;
      })
      .catch((err) => {
        console.error(Etc.LOG_PREFIX + JSON.stringify(err));
        this._errorHandler.error(err);
        if (err.response !== undefined) {
          if (err.response.status === 401) {
            throw WorkflowStorePostSignatureError.UNAUTHORIZED;
          } else {
            throw WorkflowStorePostSignatureError.UNKNOWN;
          }
        } else {
          throw WorkflowStorePostSignatureError.UNKNOWN;
        }
      })
      .finally(() => {
        this._isUploadingSignature = false;
      });
  }

  async fetchPreRegCheckInRecord() {
    const baseUrl = await getBaseUrl();

    let url = baseUrl + '/api/pre-reg-check-in-record';
    return Axios.get(url, {
      headers: {
        ...this._appStore.authenticationHeader,
      },
    })
      .then((res: AxiosResponse<FetchPreRegCheckInRecordResultAPIModel>) => {
        if (res.data.check_in_record !== null) {
          this._preRegCheckInRecord = res.data.check_in_record;
          const workflowId = this._preRegCheckInRecord.check_in_option_id!;
          const workflow = this._appStore.locationStore.location!.getWorkflowById(workflowId);
          if (workflow !== null) {
            this.setWorkflow(workflow);
          } else {
            throw new Error(`PreReg workflow id ${workflowId} cannot be found`);
          }
        }
      })
      .catch((err) => {
        console.error(Etc.LOG_PREFIX + JSON.stringify(err));
        this._errorHandler.error(err);
        if (err.response !== undefined) {
          if (err.response.status === 401) {
            throw FetchPreRegCheckInRecordError.UNAUTHORIZED;
          } else {
            throw FetchPreRegCheckInRecordError.UNKNOWN;
          }
        } else {
          throw FetchPreRegCheckInRecordError.UNKNOWN;
        }
      });
  }

  async fetchServerData() {
    const baseUrl = await getBaseUrl();
    let url = baseUrl + '/api/check-in-record';
    return Axios.get(url, {
      headers: {
        ...this._appStore.authenticationHeader,
      },
    })
      .then((res: AxiosResponse<any>) => {
        const checkInRecord = res.data.check_in_record;
        if (checkInRecord !== null) {
          if (checkInRecord.visitor_info_fields.temperature !== undefined) {
            this._thermalCheckInRecord = checkInRecord;
          } else {
            this._preRegCheckInRecord = checkInRecord;
            const workflowId = this._preRegCheckInRecord!.check_in_option_id!;
            const workflow = this._appStore.locationStore.location!.getWorkflowById(workflowId);
            if (workflow !== null) {
              this.setWorkflow(workflow);
            } else {
              throw new Error(`PreReg workflow id ${workflowId} cannot be found`);
            }
          }
        }
      })
      .catch((err) => {
        console.error(Etc.LOG_PREFIX + JSON.stringify(err));
        this._errorHandler.error(err);
        if (err.response !== undefined) {
          if (err.response.status === 401) {
            throw FetchPreRegCheckInRecordError.UNAUTHORIZED;
          } else {
            throw FetchPreRegCheckInRecordError.UNKNOWN;
          }
        } else {
          throw FetchPreRegCheckInRecordError.UNKNOWN;
        }
      });
  }

  private addCheckInImages(outCheckInRecordAPI: any) {
    const addImageBase64 = (imageBase64: string | null, photo_name: string) => {
      if (imageBase64 != null) {
        outCheckInRecordAPI[photo_name] = imageBase64;
        outCheckInRecordAPI[photo_name + '_file_name'] = `${Utils.randomUUID}_${photo_name}`;
        outCheckInRecordAPI[photo_name + '_content_type'] = 'image/jpeg';
      }
    };
    addImageBase64(this._visitorImageBase64, 'visitor_photo');
    addImageBase64(this._frontDriverLicenseImage, 'driver_license_front_photo');
    addImageBase64(this._backDriverLicenseImage, 'driver_license_back_photo');
    addImageBase64(this._packageImageBase64, 'package_photo');
  }

  private addCheckInImageToFormdata(formData: FormData, blob: boolean) {
    const addImageBase64ToFormData = (imageBase64: string | null, checkInRecordName: string, fileName: string) => {
      if (imageBase64 != null) {
        if (blob) {
          const byteCharacters = atob(imageBase64);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteNumbers.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const blob = new Blob([new Uint8Array(byteNumbers)], { type: 'image/jpeg' });
          formData.append(checkInRecordName, blob, fileName);
        } else {
          formData.append(checkInRecordName, imageBase64);
        }
      }
    };

    addImageBase64ToFormData(
      this._visitorImageBase64,
      'check_in_record[visitor_photo]',
      `${Utils.randomUUID}_visitor_photo.jpeg`,
    );
    addImageBase64ToFormData(
      this._frontDriverLicenseImage,
      'check_in_record[driver_license_front_photo]',
      `${Utils.randomUUID}_driver_license_front_photo.jpeg`,
    );
    addImageBase64ToFormData(
      this._backDriverLicenseImage,
      'check_in_record[driver_license_back_photo]',
      `${Utils.randomUUID}_driver_license_back_photo.jpeg`,
    );
    addImageBase64ToFormData(
      this._packageImageBase64,
      'check_in_record[package_photo]',
      `${Utils.randomUUID}_package_photo.jpeg`,
    );
  }

  private async waitUntilSignatureIsUploading() {
    while (this._isUploadingSignature) {
      await Utils.sleep(50);
    }
  }

  private async _putPreRegCheckInRecord() {
    const outCheckInRecordAPI = new OutCheckInRecordAPIModel(this._postCheckInRecordBody!.checkInRecord);

    this.addCheckInImages(outCheckInRecordAPI);

    outCheckInRecordAPI.checked_in = 'false';
    const baseUrl = await getBaseUrl();
    const rubyServerURL = await getRubyServerUrl();
    const url = baseUrl + '/api/check_in_records/' + this._preRegCheckInRecord!.id;
    return Axios.put(
      url,
      { check_in_record: outCheckInRecordAPI },
      {
        headers: {
          ...this._appStore.authenticationHeader,
        },
      },
    )
      .then(async (res) => {
        this._preRegBarcodeUrl = rubyServerURL + res.data.success;
      })
      .catch((err) => {
        console.error(Etc.LOG_PREFIX + JSON.stringify(err));
        this._errorHandler.critical(err);
        throw err;
      });
  }

  private async _putThermalCheckInRecord() {
    const outCheckInRecordAPI = new OutCheckInRecordAPIModel(this._postCheckInRecordBody!.checkInRecord);

    this.addCheckInImages(outCheckInRecordAPI);

    outCheckInRecordAPI.checked_in = 'true';
    if (outCheckInRecordAPI.visitor_info_fields && outCheckInRecordAPI.visitor_info_fields.temperature) {
      outCheckInRecordAPI.visitor_info_fields.temperature = this._thermalCheckInRecord.visitor_info_fields.temperature;
    }
    const baseUrl = await getBaseUrl();
    const url = baseUrl + '/api/check_in_records/' + this._thermalCheckInRecord!.id;
    return Axios.put(
      url,
      { check_in_record: outCheckInRecordAPI },
      {
        headers: {
          ...this._appStore.authenticationHeader,
        },
      },
    )
      .then((res) => {
        this._checkInResponse = res.data;
        // this._printBadge(this._checkInResponse.id);
      })
      .catch((err) => {
        console.error(Etc.LOG_PREFIX + JSON.stringify(err));
        this._errorHandler.critical(err);
        throw err;
      });
  }
  // look at this for testing rails api
  async finishPrereg(data: any) {
    const baseUrl = await getBaseUrl();
    const url = baseUrl + '/api/qr_checkin';

    let temperature: string | null = null;
    if (this._thermalCheckInRecord !== null) {
      temperature = this._thermalCheckInRecord.visitor_info_fields.temperature;
    }
    return Axios.post(
      url,
      {
        payload: data,
        temperature: temperature,
        thermal_check_in_record_id: this._thermalCheckInRecord?.id,
      },
      {
        headers: {
          ...this._appStore.authenticationHeader,
        },
      },
    )
      .then((res) => {
        this._checkInResponse = res.data;
        this._preRegCheckInRecordToComplete = this._checkInResponse.check_in_record;
        const workflowId = this._checkInResponse.check_in_record.check_in_option_id!;
        const workflow = this._appStore.locationStore.location!.getWorkflowById(workflowId);
        if (workflow != null && workflow!.useAlternativeWorkflow == true) {
          this.setWorkflow(workflow);
        } else {
          const tempWorkflowAPI = new WorkflowAPIModel();
          tempWorkflowAPI.steps.push({ id: StepId.SUCCESS });
          const tempWorkflow = new WorkflowModel(tempWorkflowAPI);
          const realWorkflow = this._appStore.locationStore.location!.getWorkflowById(workflowId);
          tempWorkflow.shouldPrintBadge = realWorkflow?.shouldPrintBadge ?? false;
          this._isWorkflowFinished = true;
          this.setWorkflow(tempWorkflow);
        }
      })
      .catch((err) => {
        console.error(Etc.LOG_PREFIX + JSON.stringify(err));
        this._errorHandler.error(err);
        throw err;
      });
  }

  async checkInEmployee(data: any) {
    const employeeCheckInWorkflow = this._appStore.locationStore.location!.workflows.find(
      (workflow) => workflow.type === WorkflowType.EMPLOYEE_CHECK_IN,
    );

    if (employeeCheckInWorkflow !== undefined) {
      this._surrogateId = data;
      this.setWorkflow(employeeCheckInWorkflow);
      await this.finishWorkflow().catch((err) => {
        const location = this.locationInfo;
        this._errorHandler
          .configure({
            payload: {
              context: {
                location_id: location.id,
                location_name: location.name,
                screen: this.step?.id,
                surrogateId: this._surrogateId,
              },
            },
          })
          .error(err);
        this.reset();
        throw err;
      });
    } else {
      throw new Error('Cannot find employee check in workflow');
    }
  }
}
