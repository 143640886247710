import { ThemeType } from '../commons/Constants';
import Utils from '../utils/Utils';
import ThemeAPIModel from './api/ThemeAPIModel';

export default class ThemeModel {
  id: number;
  name: string | null;
  textColor: string | null;
  bannerImage: string | null;
  headerTextColor: string | null;
  welcomeAnimationOption: string | null;
  base64: string | null;
  canShowWelcomePage: boolean;
  welcomeSplash: string | null;
  isNoTouchEnabled: boolean;
  welcomeBackgroudImagePortrait: string | null;
  welcomeBackgroudImageLandscape: string | null;
  workflowsBackgroudImagePortrait: string | null;
  workflowsBackgroudImageLandscape: string | null;
  welcomeBackgroundVideo: string | null;
  mainBackgroundColor: string | null;
  workflowsBackgroundColor: string | null;
  workflowsButtonColor: string | null;
  themeMain: string | null;
  themeButton: string | null;
  themeHeader: string | null;

  welcomeLogo: string | null;
  welcomeBackground: string | null;
  buttonLogo: string | null;
  buttonBackground: string | null;

  welcomeButtonColor: string | null;
  welcomeButtonTextColor: string | null;
  welcomeAccentColor: string | null;

  buttonHeaderTextColor: string | null;
  buttonBackgroundColor: string | null;
  buttonButtonColor: string | null;
  buttonTextColor: string | null;
  buttonIconColor: string | null;

  constructor(themeAPI: ThemeAPIModel) {
    this.id = themeAPI.id;
    this.name = themeAPI.name;
    this.textColor = themeAPI.text_color;
    this.bannerImage = themeAPI.banner_image;
    this.headerTextColor = themeAPI.header_text_color;
    this.welcomeAnimationOption = themeAPI.welcome_animation_option;
    this.base64 = themeAPI.base64;
    this.canShowWelcomePage = themeAPI.welcome_splash_mode === 'on';
    this.welcomeSplash = themeAPI.welcome_splash;
    this.isNoTouchEnabled = themeAPI.no_touch_enabled !== null ? themeAPI.no_touch_enabled === 'enabled' : false;
    this.welcomeBackgroudImagePortrait = themeAPI.welcome_background_image_portrait;
    this.welcomeBackgroudImageLandscape = themeAPI.welcome_background_image_landscape;
    this.workflowsBackgroudImagePortrait = themeAPI.workflows_background_image_portrait;
    this.workflowsBackgroudImageLandscape = themeAPI.workflows_background_image_landscape;
    this.welcomeBackgroundVideo = themeAPI.welcome_background_video;
    this.mainBackgroundColor = themeAPI.main_background_color ?? 'white';
    this.workflowsBackgroundColor = themeAPI.workflows_background_color ?? null;
    this.workflowsButtonColor = themeAPI.workflows_button_color ?? null;

    this.welcomeLogo = themeAPI.welcome_logo ?? themeAPI.quickstart_logo;
    this.welcomeBackground = themeAPI.welcome_bg ?? themeAPI.quickstart_bg ?? themeAPI.banner_image;
    this.buttonLogo = themeAPI.button_logo ?? themeAPI.quickstart_logo;
    this.buttonBackground = themeAPI.button_bg ?? themeAPI.quickstart_bg ?? themeAPI.banner_image;

    this.themeMain = this._setTheme(themeAPI.header_transition_type);
    this.themeHeader = this.themeMain;
    this.themeButton = this._setTheme(themeAPI.btn_theme);

    this.welcomeButtonColor = themeAPI.welcome_btn_color;
    this.welcomeButtonTextColor = themeAPI.welcome_btn_text_color;
    this.welcomeAccentColor = themeAPI.welcome_accent_color;

    this.buttonHeaderTextColor = themeAPI.btn_header_text_color;
    this.buttonBackgroundColor = themeAPI.btn_bg_color;
    this.buttonButtonColor = themeAPI.btn_color;
    this.buttonTextColor = themeAPI.btn_text_color;
    this.buttonIconColor = themeAPI.btn_icon_color;
  }

  private _setTheme(theme: string | null | undefined): string | null {
    let internalTheme = theme?.toLowerCase() ?? 'old';
    return internalTheme !== 'old' ? internalTheme : '';
  }

  static setTheme = (theme: ThemeModel) => {
    const root = document.documentElement;
    // theme.textColor = '#ff0000'
    // theme.headerTextColor = '#00ff00'
    root.style.setProperty('--ion-color-primary', theme.textColor);
    root.style.setProperty('--color-icon', theme.textColor);
    root.style.setProperty('--color-icon-transparent', theme.textColor + '33');
    root.style.setProperty('--color-header-text', theme.headerTextColor);
    root.style.setProperty('--workflows-page-background', theme.workflowsBackgroundColor);
    if (theme.workflowsBackgroundColor) {
      root.style.setProperty('--banner-color-background', 'white');
    }
    if (theme.welcomeBackgroudImagePortrait) {
      root.style.setProperty('--welcome-background-image-portrait', 'url(' + theme.welcomeBackgroudImagePortrait + ')');
      root.style.setProperty(
        '--welcome-background-image-landscape',
        'url(' + theme.welcomeBackgroudImagePortrait + ')',
      );
    }
    if (theme.welcomeBackgroudImageLandscape) {
      root.style.setProperty(
        '--welcome-background-image-landscape',
        'url(' + theme.welcomeBackgroudImageLandscape + ')',
      );
    }
    if (theme.workflowsBackgroudImagePortrait) {
      root.style.setProperty(
        '--workflows-background-image-portrait',
        'url(' + theme.workflowsBackgroudImagePortrait + ')',
      );
      root.style.setProperty(
        '--workflows-background-image-landscape',
        'url(' + theme.workflowsBackgroudImagePortrait + ')',
      );
    }
    if (theme.workflowsBackgroudImageLandscape) {
      root.style.setProperty(
        '--workflows-background-image-landscape',
        'url(' + theme.workflowsBackgroudImageLandscape + ')',
      );
    }
    if (theme.mainBackgroundColor) {
      root.style.setProperty('--greetly-background-color', theme.mainBackgroundColor);
    }

    if (theme.themeButton === ThemeType.Whimsical) {
      root.style.setProperty('--color-icon-shade', Utils.newShadeHSLColor(theme.textColor ?? '#ff0000', 400, 40));
    }

    if (theme.themeMain === ThemeType.Dark) {
      const blackColor = '#2C2E3A';
      const whiteColor = 'white';
      const emptyURL = 'url()';
      root.style.setProperty('--input-active-color', whiteColor);
      root.style.setProperty('--input-element-filled-color', whiteColor);
      root.style.setProperty('--input-focus-color', whiteColor);
      root.style.setProperty('--greetly-background-color', blackColor);
      root.style.setProperty('--welcome-background-image-portrait', emptyURL);
      root.style.setProperty('--welcome-background-image-landscape', emptyURL);
      root.style.setProperty('--workflows-page-background', blackColor);
      root.style.setProperty('--input-element-filled-color', whiteColor);
      root.style.setProperty('--text-color', whiteColor);
      root.style.setProperty('--workflow-button-text-color', whiteColor);
      root.style.setProperty('--welcome-button-color', 'var(--greetly-background-color)');
    } else {
      root.style.setProperty('--welcome-button-color', 'var(--basic-btn-color)');
      root.style.setProperty('--input-active-color', 'black');
    }

    if (theme.themeButton === ThemeType.Dark && theme.buttonButtonColor === null) {
      const blackColor = '#343746';
      const blackColor2nd = '#2C2E3A';
      root.style.setProperty('--button-button-color', blackColor);
      root.style.setProperty('--button-button-color-2nd', blackColor2nd);
      root.style.setProperty('--workflow-button-text-color', 'white');
    }

    if (theme.welcomeButtonColor) {
      root.style.setProperty('--welcome-button-color', theme.welcomeButtonColor);
    }

    if (theme.welcomeButtonTextColor) {
      root.style.setProperty('--welcome-button-text-color', theme.welcomeButtonTextColor);
    }

    if (theme.welcomeAccentColor) {
      root.style.setProperty('--welcome-accent-color', theme.welcomeAccentColor);
    }

    if (theme.buttonHeaderTextColor) {
      root.style.setProperty('--button-header-text-color', theme.buttonHeaderTextColor);
    }

    if (theme.buttonBackgroundColor) {
      root.style.setProperty('--button-background-color', theme.buttonBackgroundColor);
    }

    if (theme.buttonButtonColor) {
      root.style.setProperty('--button-button-color', theme.buttonButtonColor);
      if (theme.themeButton === ThemeType.Dark) {
        root.style.setProperty('--button-button-color-2nd', Utils.adjust(theme.buttonButtonColor, -30));
        root.style.setProperty('--button-button-color', theme.buttonButtonColor);
      }
    }

    if (theme.buttonTextColor) {
      root.style.setProperty('--button-text-color', theme.buttonTextColor);
    }

    if (theme.buttonTextColor) {
      root.style.setProperty('--button-icon-color', theme.buttonIconColor);
      root.style.setProperty('--button-icon-color-transparent', theme.buttonIconColor + '33');
      if (theme.themeButton == ThemeType.Whimsical) {
        root.style.setProperty(
          '--color-icon-shade',
          Utils.newShadeHSLColor(theme.buttonIconColor ?? '#ff0000', 400, 40),
        );
      }
    }
  };
}
