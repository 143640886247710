import React from 'react';
import BarcodeScannerOptions from '../models/BarcodeScannerOptions';
import BarcodeScannerResults from '../models/BarcodeScannerResults';

export interface BarcodeScannerProps {
  className?: string;
  shouldAutoStart?: boolean;
  options?: BarcodeScannerOptions;
  onStart?: () => void;
  onStop?: () => void;
  onSuccess?: (res: BarcodeScannerResults) => void;
  onError?: (err: any) => void;
}

abstract class BarcodeScanner<P extends BarcodeScannerProps, S = {}> extends React.Component<P, S> {}

export default BarcodeScanner;
