import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import NativeStore from '../mobx/NativeStore';
import Welcome from './Welcome';
import Workflow from './Workflow';
import Workflows from './Workflows/Workflows';
import NativeRetry from './NativeRetry';
import '../theme/pages/loggedIn.css';
import ThermalWaiting from './ThermalWaiting';
import Loading from '../components/Loading';

interface Props {
  nativeStore: NativeStore;
}

const LoggedIn = observer((props: Props) => {
  const { nativeStore } = props;
  const locationStore = nativeStore.locationStore;
  const isLoading = locationStore.isLoading;

  useEffect(() => {
    nativeStore.fetchLocation(false);
  }, []);

  useEffect(() => {
    return () => {
      nativeStore.clearPrintBadgeTimeout();
      nativeStore.clearReloadLocationTimeout();
      nativeStore.clearWelcomePageTimeout();
    };
  }, []);

  const resetTimeouts = useCallback(() => {
    nativeStore.resetWelcomePageTimeout();
    nativeStore.resetReloadLocationTimeout();
  }, []);

  const getPage = useCallback(() => {
    const workflowStore = nativeStore.workflowStore;
    const locationStore = nativeStore.locationStore;

    if (locationStore.error) {
      return <NativeRetry {...props} />;
    }

    if (locationStore.location?.isThermalCheckInEnabled) {
      if (workflowStore.thermalCheckInRecord === null) {
        return <ThermalWaiting nativeStore={nativeStore} />;
      } else if (nativeStore.shouldShowWelcomePage) {
        return <Welcome appStore={nativeStore} />;
      } else if (workflowStore.workflow !== null) {
        return <Workflow {...props} appStore={nativeStore} />;
      } else {
        return <Workflows {...props} appStore={nativeStore} />;
      }
    } else {
      if (nativeStore.shouldShowWelcomePage) {
        return <Welcome appStore={nativeStore} />;
      } else if (workflowStore.workflow !== null) {
        return <Workflow {...props} appStore={nativeStore} />;
      } else {
        return <Workflows {...props} appStore={nativeStore} />;
      }
    }
  }, []);

  return (
    <div className="logged-in" onTouchStart={() => resetTimeouts()}>
      {getPage()}
      {isLoading && <Loading />}
    </div>
  );
});

export default LoggedIn;
