import { isPlatform } from '@ionic/react';
import { Toast } from '@ionic-native/toast';
import { AsYouType, CountryCode } from 'libphonenumber-js';
import { v4 as uuid } from 'uuid';

import ENV from '../env';
import BadgeUtils, { BadgePrintInterface } from './BadgeUtils';
import BadgeSettingModel from '../models/BadgeSettingModel';
import ColorConvertor from './ColorConvertor';
import PrinterManager from '../plugins/PrinterManager';

// const rollbar = rollbarHandler();

declare global {
	interface String {
		capitalize(): string;
		toBoolean(): boolean;
	}
}

// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function () {
	let str = String(this);
	return str.charAt(0).toUpperCase() + str.slice(1);
};

// eslint-disable-next-line no-extend-native
String.prototype.toBoolean = function (): boolean {
	switch (this) {
		case 'true':
		case '1':
		case 'on':
		case 'yes':
			return true;
		default:
			return false;
	}
};

export default class Utils {
	static isNative() {
		return ENV.isNative;
	}

	static isMobileApp() {
		return isPlatform('ipad') || isPlatform('android');
	}

	static isTabletApp() {
		return isPlatform('ipad') || (isPlatform('android') && isPlatform('tablet'));
	}

	static isAndroid() {
		return isPlatform('android');
	}

	static async sleep(ms: number) {
		await new Promise((res) => {
			setTimeout(res, ms);
		});
	}

	static showErrorToast(message: string) {
		return Toast.showWithOptions({
			message: message,
			duration: 5_000,
			position: 'top',
			styling: {
				backgroundColor: '#dc143c',
				cornerRadius: 15,
				opacity: 1,
				horizontalPadding: 15,
				verticalPadding: 15,
			},
		}).subscribe();
	}

	static async printBadge(badgePrint: BadgePrintInterface, badgeSetting: BadgeSettingModel) {
		return BadgeUtils.createBadgeImage(badgePrint)
			.then(async (imageBase64Url) => {
				const imageBase64 = imageBase64Url
					.substring(imageBase64Url.lastIndexOf(",") + 1, imageBase64Url.length);

				return this.printImg(imageBase64, badgeSetting.ipAddress);
			});
	}

	static async printImg(imageBase64: string, ipAddress: string) {
		return PrinterManager.printImage({
			imageBase64,
			paperType: await BadgeUtils.getPaperType(),
			address: ipAddress,
		});
	}

	static safeStringify(obj: any, indent = 2) {
		let cache: any = [];
		const retVal = JSON.stringify(
			obj,
			(key, value) =>
				typeof value === 'object' && value !== null
					? cache.includes(value)
						? undefined // Duplicate reference found, discard key
						: cache.push(value) && value // Store value in our collection
					: value,
			indent,
		);
		return retVal;
	}

	static getDefaultLanguage(tabletTerms: any) {
		const i = tabletTerms.findIndex((terms: any) => terms.default === true);
		return tabletTerms[i].languageName;
	}

	static checkValueOfTabletTerminology = (term: any) => {
		if (term === null || term === '') {
			return true;
		} else {
			return false;
		}
	};

	static parseMessage = (
		message: string,
		tagSources: Array<{ tag: string; source: () => string | undefined }>,
		defaultSource?: (tag: string) => string | undefined,
	) => {
		const tagSourceMap: Map<string, () => string | undefined> = new Map<string, () => string | undefined>();
		for (let i = 0; i < tagSources.length; ++i) {
			const tagSource = tagSources[i];
			tagSourceMap.set(tagSource.tag, tagSource.source);
		}

		let outMessage = message;
		let isParsingField = false;
		let tag = '';
		for (let i = 0; i < message.length; ++i) {
			const c = message[i];
			if (isParsingField) {
				if (c === ']') {
					const source = tagSourceMap.get(tag);

					let value;
					if (source !== undefined) {
						value = source();
					}

					if (value === undefined && defaultSource !== undefined) {
						value = defaultSource(tag);
					}

					if (value !== undefined) {
						outMessage = outMessage.replace(`[${tag}]`, value);
					}

					tag = '';
					isParsingField = false;
				} else {
					tag += c;
				}
			} else {
				if (c === '[') {
					isParsingField = true;
				}
			}
		}

		return outMessage;
	};

	static _capitalizeFirstLetter(text: string) {
		return text.length > 0 ? text.charAt(0).toUpperCase() + text.slice(1) : text;
	}

	static humanizeField = (fieldName: string) => {
		if (fieldName != null) {
			let spacedString: string;
			if (fieldName.includes('_') && !fieldName.includes(' ')) {
				spacedString = fieldName
					.split('_')
					.map((text) => text.capitalize())
					.join(' ');
			} else {
				spacedString = fieldName;
			}
			return spacedString.capitalize();
		} else {
			return '<undefined display_label>';
		}
	};

	static adjust(hexInput: string, percent: number) {
		let hex = hexInput;

		// strip the leading # if it's there
		hex = hex.replace(/^\s*#|\s*$/g, '');

		// convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
		if (hex.length === 3) {
			hex = hex.replace(/(.)/g, '$1$1');
		}
		let r = parseInt(hex.substring(0, 2), 16);
		let g = parseInt(hex.substring(2, 4), 16);
		let b = parseInt(hex.substring(4, 6), 16);

		const calculatedPercent = (100 + percent) / 100;

		r = Math.round(Math.min(255, Math.max(0, r * calculatedPercent)));
		g = Math.round(Math.min(255, Math.max(0, g * calculatedPercent)));
		b = Math.round(Math.min(255, Math.max(0, b * calculatedPercent)));

		let fix2symbols = (color: string) => (color.length > 1 ? color : `0${color}`);

		return `#${fix2symbols(r.toString(16).toUpperCase())}${fix2symbols(g.toString(16).toUpperCase())}${fix2symbols(
			b.toString(16).toUpperCase(),
		)}`;
	}

	static newShadeHSLColor(color: string, hValue: number, lValue: number) {
		let [h, s, l] = ColorConvertor.hexToHsl(color);
		h = (h + hValue / 360) % 1;
		l = Math.min(l + lValue / 100, 1);
		return ColorConvertor.hslToHex(h, s, l);
	}

	static searchCountryCodeByCallingCode(callingCode: string | null | undefined): CountryCode {
		const asYouType = new AsYouType();
		asYouType.input(`${callingCode}`);
		let currentCountyCode = asYouType.getCountry();

		if (!currentCountyCode) {
			const matchingCountriesArray = (asYouType as any).metadata.metadata.country_calling_codes[
				callingCode?.replace('+', '') ?? '1'
			];

			if (matchingCountriesArray) {
				currentCountyCode = matchingCountriesArray[0];
			}
		}

		return currentCountyCode ?? 'US';
	}

	static get randomUUID(): string {
		return uuid();
	}

	static b64DecodeUnicode = (str: string) => {
		return decodeURIComponent(
			Array.prototype.map
				.call(atob(str), function (c) {
					return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join(''),
		);
	};
}
