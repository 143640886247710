import { IonContent, IonPage, IonItem, IonImg, IonLabel, IonButton } from '@ionic/react';
import React from 'react';
import Step, { WorkflowProps } from './Step';
import '../../theme/pages/finishworkflow.css';
import Utils from '../../utils/Utils';
import { WorkflowStorePostCheckInRecordError } from '../../mobx/WorkflowStore';
import { Redirect } from 'react-router-dom';
import { Etc, NodeId, PathName, Paths, WorkflowType } from '../../commons/Constants';
import Loading from '../../components/Loading';
import Banner from '../../components/Banner';
import DesignHelper from './DesignHelper';
import { rollbarHandler } from '../../components/RollbarErrorHandler';

type State = {
  isLoading: boolean;
  customMessage?: string;
  isSuccessCheckIn: boolean;
  shouldRedirectToSessionExpired: boolean;
};

class FinishWorkflow extends Step<WorkflowProps, State> {
  private rollbar = rollbarHandler();

  state = {
    isLoading: this.props.node.type !== NodeId.CHECK_IN_FAILED,
    customMessage: this.props.node.data.message,
    isSuccessCheckIn: this.props.node.type !== NodeId.CHECK_IN_FAILED,
    shouldRedirectToSessionExpired: false,
  };

  closeTimeout: NodeJS.Timeout | null = null;

  constructor(props: WorkflowProps) {
    super(props);

    const { workflowStore, locationStore } = props.appStore;

    const catchError = (err: any) => {
      if (Utils.isNative()) {
        this.setState({ isLoading: false, customMessage: this.getFailedMessage(), isSuccessCheckIn: false });
      } else {
        switch (err) {
          // eslint-disable-next-line
          case WorkflowStorePostCheckInRecordError.UNAUTHORIZED:
            {
              this.setState({ shouldRedirectToSessionExpired: true });
            }
            break;
          case WorkflowStorePostCheckInRecordError.UNKNOWN:
          default: {
            this.setState({ isLoading: false, customMessage: this.getFailedMessage() });
          }
        }
      }
      const errorDescription = Etc.LOG_PREFIX + JSON.stringify(err);
      this.rollbar
        .configure({
          payload: {
            context: { location_id: locationStore.location?.id, error: errorDescription },
          },
        })
        .error(err, 'Print Failure');
    };

    const setCloseTimeout = () => {
      this.closeTimeout = setTimeout(() => {
        this.closeTimeout = null;
        workflowStore.goToNextStep();
      }, 10000);
    };

    if (this.state.isSuccessCheckIn) {
      workflowStore
        .finishWorkflow()
        .then(() => {
          this.setState({ isLoading: false }, () => {
            if (Utils.isNative()) {
              setCloseTimeout();
            }
          });
        })
        .catch(catchError);
    }
  }

  componentWillUnmount() {
    if (Utils.isNative()) {
      if (this.closeTimeout !== null) {
        clearTimeout(this.closeTimeout);
      }
    }
  }

  getMessage = () => {
    const { locationStore, workflowStore, hostsStore } = this.props.appStore;
    let successMsg;

    if (this.state.customMessage !== undefined) {
      successMsg = this.state.customMessage;
    } else {
      let successMessage = (message: string | null, polyglotMessage: string | null) => {
        if (message !== null && locationStore.language === 'English') {
          return message;
        } else {
          return polyglotMessage;
        }
      };

      if (workflowStore.workflow!.type === WorkflowType.EMPLOYEE_CHECK_IN) {
        if (workflowStore.checkInResponse.check_in_record.checked_out!) {
          return 'Thank you for checking out!';
        } else {
          return (
            successMessage(
              workflowStore.workflow!.customSuccessMsg,
              workflowStore.workflow!.customSuccessMsgPolyglot[`${locationStore.language}`],
            ) || 'Thank you for checking in!'
          );
        }
      }
      if (workflowStore.workflow!.type === WorkflowType.CHECK_OUT) {
        if (workflowStore.checkOutCheckInRecord?.checked_out) {
          return (
            successMessage(
              workflowStore.workflow!.customSuccessMsg,
              workflowStore.workflow!.customSuccessMsgPolyglot[`${locationStore.language}`],
            ) || 'Thank you for checking out!'
          );
        }
      }
      // @ATT could add here package-delivery workflow type
      if (workflowStore.workflow!.type === WorkflowType.PACKAGE_DELIVERY) {
        if (workflowStore.checkInRecord.deliverySignatureRequired) {
          return this.props.appStore.workflowStore.workflow?.customSignatureMsg;
        } else {
          return this.props.appStore.workflowStore.workflow?.customSuccessMsg;
        }
      }

      const locationSuccessMessage = locationStore.tabletTerminologies.successMessage;
      successMsg =
        successMessage(
          workflowStore.workflow!.customSuccessMsg,
          workflowStore.workflow!.customSuccessMsgPolyglot[`${locationStore.language}`],
        ) ||
        locationSuccessMessage ||
        'Success';
    }

    return Utils.parseMessage(
      successMsg,
      [
        {
          tag: 'host_first_name',
          source: () => {
            const host = hostsStore.getHostById(workflowStore.selectedHostId)!;
            return host?.firstName;
          },
        },
        {
          tag: 'host_last_name',
          source: () => {
            const host = hostsStore.getHostById(workflowStore.selectedHostId)!;
            return host?.lastName;
          },
        },
        {
          tag: 'host_company',
          source: () => {
            return locationStore.location!.name;
          },
        },
        {
          tag: 'visitor_id',
          source: () => {
            return workflowStore.checkInResponse?.visitor_info_fields?.visitor_id ?? '';
          },
        },
      ],
      (tag: string) => {
        return workflowStore.visitorInfoFields[tag] ?? `[${tag}]`;
      },
    );
  };

  getFailedMessage = () => {
    const node = this.props.node;
    return node.type === NodeId.CHECK_IN_FAILED ? node.data.message : 'Something unexpected happened';
  };

  exit = async () => {
    const { locationStore } = this.props.appStore;
    locationStore.resetLanguage(); //example of this working
    return this.props.appStore.workflowStore.goToNextStep();
  };

  render() {
    if (!Utils.isNative()) {
      if (this.state.shouldRedirectToSessionExpired) {
        return <Redirect to={PathName.SESSION_EXPIRED} />;
      }
    }

    const { locationStore, workflowStore } = this.props.appStore;

    if (workflowStore.preRegBarcodeUrl !== null) {
      window.location.replace(workflowStore.preRegBarcodeUrl);
      return null;
    }

    const themeMain = locationStore.themeMain;

    return (
      <IonPage className="success">
        <IonContent>
          {this.state.isLoading ? (
            <>
              <Banner {...this.props} />
              {DesignHelper.renderThemeDesignTop(themeMain, locationStore.location?.logo || null)}
              <Loading />
            </>
          ) : (
            <>
              {this.state.isSuccessCheckIn ? (
                <>
                  <IonImg className="image" src={workflowStore.workflow!.successImgSrc} />
                </>
              ) : (
                <>
                  <IonImg
                    className="image"
                    src={Paths.GIF + (themeMain !== 'dark' ? '/Failed.gif' : '/Failed_dark.gif')}
                  />
                </>
              )}
              <IonItem lines="none">
                <IonLabel className="welcome">
                  {this.state.isSuccessCheckIn ? locationStore.tabletTerminologies.success : 'Failed to check in!'}
                </IonLabel>
              </IonItem>
              <IonItem lines="none">
                <IonLabel className="notification">{this.getMessage()}</IonLabel>
              </IonItem>
              {Utils.isNative() && (
                <IonButton className="exit" onClick={() => this.exit()}>
                  {locationStore.tabletTerminologies.exit}
                </IonButton>
              )}
            </>
          )}
          {/* <div>Status: {workflowStore.pringtingStatus}</div>
					{Utils.isNative() && <IonButton className="exit" onClick={() => this.exit()}>
											{locationStore.location!.terminologySetting.english.exit}
										</IonButton>} */}
        </IonContent>
      </IonPage>
    );
  }
}

export default FinishWorkflow;
