import React, { useEffect, useState } from 'react';
import { IonContent, IonPage, IonImg } from '@ionic/react';
import { observer } from 'mobx-react';
import { isPlatform } from '@ionic/react';

import WorkflowsItem from '../../components/WorkflowsItem/WorkflowsItem';
import Banner from '../../components/Banner';
import WorkflowModel from '../../models/WorkflowModel';
import { ThemeType } from '../../commons/Constants';
import AppStore from '../../mobx/AppStore';
import Debug from '../../components/Debug';
import Utils from '../../utils/Utils';
import HiddenMenu from '../../components/HiddenMenu';
import LanguageMenu from '../../components/LanguageMenu';
import DesignHelper from '../steps/DesignHelper';
import HiddenMenuHelper from '../../utils/HiddenMenuHelper';
import './workflows.scss';
import './grid-template.scss';

interface Props {
  appStore: AppStore;
}

const Workflows = observer((props: Props) => {
  const [shouldShowDebug, setShouldShowDebug] = useState(false);
  const [shouldShowHiddenMenu, setShouldShowHiddenMenu] = useState(false);
  const [shouldShowLanguageMenu, setShouldShowLanguageMenu] = useState(false);

  const hiddenMenuHelper = new HiddenMenuHelper();
  const locationStore = props.appStore.locationStore;
  const isLoading = locationStore.isLoading;

  const workflowStore = props.appStore.workflowStore;
  const tabletTerms = locationStore.location?.terminologySetting.tabletTerminologies;
  const themeMain = locationStore.themeMain;
  const themeButton = locationStore.themeButton;

  const setWorkflow = async (workflow: WorkflowModel) => {
    const workflowStore = props.appStore.workflowStore;
    workflowStore.setWorkflow(workflow);
  };

  const onLanguageClick = () => {
    setShouldShowLanguageMenu(true);
  };

  useEffect(() => {
    if (locationStore.location?.workflows.length === 1) {
      setWorkflow(locationStore.location!.workflows[0]);
    }

    return () => {
      hiddenMenuHelper.clearTimeout();
    };
  }, [locationStore]);

  const isOldBackgroundImg = (): boolean => {
    return (
      themeButton == ThemeType.Old &&
      !!(
        locationStore.location?.theme.workflowsBackgroudImagePortrait ||
        locationStore.location?.theme.workflowsBackgroudImageLandscape
      )
    );
  };

  const isButtonBackground = (): boolean => {
    return (
      themeButton != ThemeType.Old &&
      !shouldShowHiddenMenu &&
      !shouldShowLanguageMenu &&
      !!locationStore.location?.theme.buttonBackground
    );
  };

  return (
    <IonPage className="workflows">
      <IonContent className="cont-back" scroll-y={'true'}>
        {isOldBackgroundImg() && <IonImg className="image-workflows" />}
        {isButtonBackground() && (
          <IonImg className="image-workflows" src={locationStore.location?.theme?.buttonBackground || ''} />
        )}
        <>
          {themeButton == ThemeType.Old && <Banner {...props} />}
          {DesignHelper.renderThemeDesignTop(
            themeMain,
            locationStore.location?.theme.buttonLogo ?? locationStore.location?.logo ?? null,
            locationStore.location?.theme.buttonBackgroundColor,
          )}

          {Utils.isNative() && (
            <div
              className="hidden-menu-button"
              onClick={() =>
                hiddenMenuHelper.onClick(() => {
                  setShouldShowHiddenMenu(true);
                })
              }
            />
          )}
          {showHomeBtn(Utils.isNative(), props.appStore.previewMode, isLoading) &&
            DesignHelper.renderHomeButton(themeButton, () => workflowStore.reset(true))}

          {tabletTerms &&
            tabletTerms.length > 1 &&
            DesignHelper.renderLanguageButton(themeButton, () => onLanguageClick(), locationStore.defaultLanguage)}

          {DesignHelper.renderThemeLabelTitle(
            themeMain,
            locationStore.tabletTerminologies !== undefined ? locationStore.tabletTerminologies.visitorCheckin : '',
            true,
          )}

          <div className="workflow-wrapper">
            <div
              className={getGridTemplate(themeMain, ThemeType, locationStore.visibleWorkflows.length)}
              id="workflowsContainer"
            >
              {locationStore.visibleWorkflows.map((workflow, index) => {
                return (
                  <WorkflowsItem
                    key={index}
                    {...props}
                    workflow={workflow}
                    setWorkflow={setWorkflow}
                    totalNumber={locationStore.visibleWorkflows.length}
                    appStore={props.appStore}
                  />
                );
              })}
            </div>
          </div>
        </>
      </IonContent>

      {shouldShowLanguageMenu && (
        <LanguageMenu
          {...props}
          close={() => {
            setShouldShowLanguageMenu(false);
          }}
        />
      )}

      {shouldShowHiddenMenu && (
        <HiddenMenu
          {...props}
          appVersion={props.appStore.appVersion}
          close={() => {
            setShouldShowHiddenMenu(false);
          }}
        />
      )}

      {shouldShowDebug && (
        <Debug
          {...props}
          close={() => {
            setShouldShowDebug(false);
          }}
        />
      )}
    </IonPage>
  );
});

export default Workflows;

function showHomeBtn(isNative: boolean, isPreviewMode: boolean, loading: boolean) {
  return (isNative || isPreviewMode) && !loading;
}

function getGridTemplate(themeMain: any, ThemeType: any, workflowNumber: number): string {
  let containerClass = themeMain !== ThemeType.Old ? 'container-new-design' : 'container';

  containerClass += ' grid-template grid-template-' + workflowNumber;

  return containerClass;
}
