import { observable, computed, action, makeObservable } from 'mobx';

import { ScreenType } from '../commons/Constants';
import AnalyticsStore from './AnalyticsStore';
import LocationStore from './LocationStore';
import HostsStore from './HostsStore';
import CheckInRecordsStore from './CheckInRecordsStore';
import WorkflowStore from './WorkflowStore';

export default abstract class AppStore {
  abstract get authenticationHeader(): any;
  abstract get locationId(): number;

  protected _analyticsStore = new AnalyticsStore(this);
  protected _locationStore = new LocationStore(this);
  protected _hostsStore = new HostsStore(this);
  protected _checkInRecordsStore = new CheckInRecordsStore(this);
  protected _workflowStore = new WorkflowStore(this);

  private _screenType: ScreenType = ScreenType.Phone;
  private _screenWidth = 0;
  private _screenHeight = 0;
  protected _shouldShowWelcomePage = false;

  constructor() {
    makeObservable<AppStore, '_screenType' | '_screenWidth' | '_screenHeight' | '_shouldShowWelcomePage'>(this, {
      _screenType: observable,
      _screenWidth: observable,
      _screenHeight: observable,
      _shouldShowWelcomePage: observable,
      screenType: computed,
      screenWidth: computed,
      screenHeight: computed,
      onWindowResize: action,
      setShowWelcomePageFunc: action,
    });
  }

  get analyticsStore() {
    return this._analyticsStore;
  }

  get locationStore() {
    return this._locationStore;
  }

  get hostsStore() {
    return this._hostsStore;
  }

  get checkInRecordsStore() {
    return this._checkInRecordsStore;
  }

  get workflowStore() {
    return this._workflowStore;
  }

  get appVersion(): string {
    return '';
  }

  get screenType() {
    return this._screenType;
  }

  get screenWidth() {
    return this._screenWidth;
  }

  get screenHeight() {
    return this._screenHeight;
  }

  get shouldShowWelcomePage() {
    return this._shouldShowWelcomePage;
  }

  get previewMode() {
    return false;
  }

  get deviceId() {
    return '';
  }

  get isBluetoothPrinter(): boolean | undefined {
    return undefined;
  }

  setShowWelcomePageFunc(value: boolean) {
    if (this._locationStore.location === null || !this._locationStore.location.theme.canShowWelcomePage) {
      if (value === false) {
        this._shouldShowWelcomePage = false;
      }

      return;
    }
    this._shouldShowWelcomePage = value;
  }

  set shouldShowWelcomePage(value: boolean) {
    this.setShowWelcomePageFunc(value);
  }

  onWindowResize() {
    this._screenWidth = window.innerWidth;
    this._screenHeight = window.innerHeight;

    if (window.innerWidth < 768) {
      this._screenType = ScreenType.Phone;
    } else {
      this._screenType = ScreenType.Tablet;
    }
  }

  async fetchDeviceId(): Promise<string> {
    return '';
  }
}
